import React, { useEffect } from "react";
import BannerSlider from "../layouts/slider/BannerSlider";
import BestSellingProducts from "../layouts/slider/BestSellingProducts";
import BestBrands from "../layouts/slider/BestBrands";
import ValuedCusmomer from "../layouts/slider/ValuedCustomers";
import HeardwareProducts from "../layouts/products/HeardwareProducts";
import CivilLab from "../layouts/civil_lab/CivilLab";
import MeasuringInstrument from "../layouts/slider/MeasuringInstruments";
import Newsletter from "../layouts/newsletter/Newsletter";
import BestDealOn from "../layouts/slider/BestDearlon";
import { connect } from "react-redux";
import { GET_HOME_DATA, globalAction } from "../../redux";
import SeoSetting from "./seoSetting/seoSetting";

function Home({ home, getHomeData }) {
  useEffect(() => {
    getHomeData();
  }, [getHomeData]);
  const {
    slider_images,
    best_selling_product,
    best_deal_product,
    new_product,
    most_popular_product,
    brands,
    home_category_1,
    home_category_2,
    home_category_3,
    our_valued_customers,
  } = home;
  console.log("home", home);
  return (
    <div className="home">
      {
        <>
          <SeoSetting title="Online Industrial Departmental Store - Bellstoneonline.com" />
          <BannerSlider slider_images={slider_images} />
          <BestSellingProducts best_selling_product={best_selling_product} />
          <HeardwareProducts hardware={home_category_1} />
          <CivilLab civilLabData={home_category_2} />
          <MeasuringInstrument measuringData={home_category_3} />
          <Newsletter />
          <BestDealOn
            bestDeal={best_deal_product}
            newProducts={new_product}
            popularProducts={most_popular_product}
          />
          <BestBrands brands={brands && brands} />
          <ValuedCusmomer
            our_valued_customers={our_valued_customers && our_valued_customers}
          />
        </>
      }
    </div>
  );
}
const mapStateFromProps = (state) => {
  const {
    AppReducer: { home },
  } = state;
  return { home };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHomeData: () => {
      dispatch(globalAction(GET_HOME_DATA));
    },
  };
};

export default connect(mapStateFromProps, mapDispatchToProps)(Home);
