import React, { Component } from 'react'
import "./notFound.scss"
import i18next from '../Translation'

export default class NotFound extends Component {
    render() {
        return (
            <div className="not-found">
                <h1>
                {`${i18next.t('404 Not Found Page')}`}
                </h1>
            </div>
        )
    }
}
