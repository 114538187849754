// Imports: Dependencies
import { combineReducers } from 'redux';
// Imports: Reducers
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import AppReducer from './AppReducer'
// Redux: Root Reducer
const rootReducer = combineReducers({
  authReducer,errorReducer,AppReducer
});
// Exports
export default rootReducer;