import React, { useEffect, useState } from "react";
import CivilLab from "../../../assets/product/civil_lab1.jpg";
import { Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./login.scss";
import { connect, useSelector } from "react-redux";
import { globalAction } from "../../../redux/actions";
import GoogleButton from "react-google-button";
import {
  HIDE_MESSAGE,
  SHOW_TOAST,
  SIGN_IN,
  SOCIAL_LOGIN,
} from "../../../redux/actionTypes";
import i18next from "../../Translation";
import {
  signInWithGooglePopup,
  signInWithFacebookPopup,
} from "../../../utils/firebase_config";
import { FacebookLoginButton } from "react-social-login-buttons";

const initialState = { email: "", password: "" };

function Login(props) {
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState(initialState);
  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user) {
      props.history.push("/dashboard");
    }
  }, [user, props.history]);

  const googlelogin = async (token) => {
    // Handle Google login here
  };

  const facebooklogin = async (token) => {
    // Handle Facebook login here
  };

  const changeText = (value, text) => {
    let credential = state;
    credential[text] = value.target.value;
    setState(credential);
  };

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    console.log(response?.user);
    googlelogin(response?.user?.accessToken);
  };

  const logFacebookUser = async () => {
    const response = await signInWithFacebookPopup();
    console.log(response?.user);
    facebooklogin(response?.user?.accessToken);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      const {
        history: { location },
        login,
      } = props;

      state.forword = props.history;

      if (location.state) {
        state.path = location.state.from;
      } else {
        let prevUrl = localStorage.getItem("url");
        state.path = prevUrl;
      }
      await login(state);
    }

    setValidated(true);
  };

  const onSocialLogin = (data) => {
    const { socialLogin, history, location } = props;
    console.log(history);
    if (data.provider === "google") {
      data.forword = history;
      if (location.state) {
        data.path = location.state.from;
      } else {
        data.path = "/dashboard";
      }
      console.log("data", data);
      socialLogin(data);
    }
  };

  return (
    <div>
      <div className="full-width-container login">
        <div className="container-md login-container">
          <div className="row login-row">
            <div className="col-md-6 login-img">
              <img src={CivilLab} className="img-fluid" alt="login-img" />
            </div>
            <div className="col-md-6 login-6">
              <div className="heading">
                <h2>
                  <span className="green-text">{`${i18next.t(
                    "Welcome"
                  )}`}</span>
                  {`${i18next.t("Back!")}`}
                </h2>
                <p>{`${i18next.t("Please Sign In to your account.")}`}</p>
              </div>

              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group as={Col} md="12" controlId="emailId">
                  <Form.Control
                    required
                    type="email"
                    placeholder={`${i18next.t("Email")}`}
                    onChange={(value) => changeText(value, "email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`${i18next.t("Please enter your valid email.")}`}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="Password">
                  <Form.Control
                    required
                    type="Password"
                    placeholder={`${i18next.t("Password")}`}
                    onChange={(value) => changeText(value, "password")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {`${i18next.t("Please enter your password.")}`}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3 forget_password"
                  id="formGridCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    label={`${i18next.t("Keep me Log In")}`}
                  />
                  <Link to="/forget-password">
                    {" "}
                    {`${i18next.t("Forget Password?")}`}{" "}
                  </Link>
                </Form.Group>
                <Button className="btn w-100" type="submit">
                  {`${i18next.t("Log In Now")}`}
                </Button>
                <div className="or">
                  <p>
                    <span>{`${i18next.t("OR Login with")}`}</span>
                  </p>
                  <hr className="hr-line" />
                </div>
                <div className="social-login">
                  {/* <FacebookLoginButton
                    type="light"
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                      boxShadow: "none",
                    }}
                    onClick={logFacebookUser}
                  /> */}

                  <GoogleButton
                    type="light"
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                      boxShadow: "none",
                    }}
                    onClick={logGoogleUser}
                  />
                </div>
                <div className="link">
                  <p>
                    {`${i18next.t("Don't Have account?")}`}{" "}
                    <Link to="register">{`${i18next.t("Signup!")}`}</Link>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateFromProps = (state) => {
  const {
    errorReducer: { message, visibleMessage, status },
  } = state;
  return { message, visibleMessage, status };
};
const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(globalAction(SIGN_IN, data)),
  hideMessage: () => dispatch(globalAction(HIDE_MESSAGE)),
  showToast: (data) => dispatch(globalAction(SHOW_TOAST, data)),
  socialLogin: (data) => dispatch(globalAction(SOCIAL_LOGIN, data)),
});
export default connect(mapStateFromProps, mapDispatchToProps)(Login);
