import { takeEvery, put, call, delay } from 'redux-saga/effects';
import { loginApi, signInApi, getSavedAddressApi, addAddressApi, deleteAddressApis, updateAddressApi, socialLoginApi, signUpVerifyApi, updatePasswordApi, } from '../../api'

import { SIGN_IN, SET_USER, SHOW_MESSAGE, SIGN_UP, HIDE_MESSAGE, REMOVE_MESSAGE, GET_USER_SAVED_ADDRESS } from '../actionTypes'
import { globalAction } from '../actions';
import { ADD_ADDRESS, ADD_TO_CART, CHANGE_PASSWORD, CLOSE_LOADING, DELETE_ADDRESS, GET_LIST_OF_ADD_TO_CART_INTO_DB, SET_USER_SAVED_ADDRESS, SIGNUP_VERIFY, SOCIAL_LOGIN, UPDATE_ADDRESS } from '..';
import { data } from 'jquery';


const callApi = (customFunction, data) => {
  return customFunction(data).then(result => result).catch(err => err.response)
}

function* signIn({ payload }) {
  console.log(payload)
  let history;
  if (payload.forword) {
    const { forword } = payload;
    history = forword;
    delete payload.forword;
  }
  let path = payload.path;
  delete payload.path;

  try {
    const result = yield call(callApi, loginApi, payload);
    const { status, data } = result
    if (status === 200) {
      if (data.status === 'error') {
        if (history) {
          window.scroll({ top: 0 })
          data.error === "Please activate your account by verifying on your email" ? yield history.push('/verify-account') : yield history.push('/login')
          yield localStorage.setItem('bellStone_verifyEmail', payload.email)
          yield localStorage.setItem('bellStone_pass', payload.password)
        }
      }
      else {
        yield put(globalAction(GET_LIST_OF_ADD_TO_CART_INTO_DB,data.data.id))
        yield localStorage.setItem('bellStone_idToken', data.access_token)
        yield localStorage.setItem("bellStone_user", JSON.stringify(data.data))
        yield localStorage.setItem('bellStone_verifyEmail', undefined)
        yield localStorage.setItem('bellStone_pass', undefined)
        let localCartItem = localStorage.getItem('cart')
        localCartItem = JSON.parse(localCartItem)
        if (localCartItem) {
          yield put(globalAction(ADD_TO_CART, localCartItem))
        }
        
        yield put(globalAction(SET_USER, data.data))
        // get item from database

        if (history) {
          yield history.push(path)
        }
        if (payload.cmhistory) {
          yield payload.cmhistory.push('/dashboard')
        }
      }

      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    } else {
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    }

  }
  catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error.response))
    yield delay(2000)
    yield put(globalAction(HIDE_MESSAGE))
    yield delay(1000)
    yield put(globalAction(REMOVE_MESSAGE))
  }
};
function* signup({ payload }) {
  const { forword } = payload;
  const history = forword;
  delete payload.forword;
  try {
    const value = yield call(callApi, signInApi, payload);
    const { status, data } = value
    if (status === 200) {
      // localStorage.setItem('bellStone_idToken', data.access_token)
      // sessionStorage.setItem("bellStone_user", JSON.stringify(data.data))
      if (data.status === 'success') {

        yield put(globalAction(SIGN_IN, { email: payload.email, password: payload.password, forword: forword }))
      }
      // yield put(globalAction(SET_USER, data.data))
      // yield history.push("/dashboard")
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    } else {
      yield put(globalAction(SHOW_MESSAGE, value))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    }

  }
  catch (error) {
    if (error.response !== undefined) {
      data = {
        msg: `Something went wrong ! Please try after sometime`,
        status: "waring",
      };
    }
    else {
      yield put(globalAction(SHOW_MESSAGE, error.response))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    }

  }
};


function* getSavedAddress({ payload }) {
  try {
    const result = yield call(callApi, getSavedAddressApi, payload)
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(SET_USER_SAVED_ADDRESS, data.addresses))
      yield put(globalAction(CLOSE_LOADING))
    }

  }
  catch (error) {
    let data = error
    if (error !== undefined) {
      data = {
        msg: `Error 400 (Network Error) ! Please try after sometime`,
        status: "waring",
      };
    }
    yield put(globalAction(SHOW_MESSAGE, data))
    yield delay(2000)
    yield put(globalAction(HIDE_MESSAGE))
    yield delay(1000)
    yield put(globalAction(REMOVE_MESSAGE))
  }
}

function* add_Address({ payload }) {
  console.log(payload)
  if (payload.isBillingAddress === 'no') {
    try {
      const result = yield call(callApi, addAddressApi, payload)
      const { status, data } = result;
      if (status === 200) {
        localStorage.setItem('shippingId', data.data.id)
        yield put(globalAction(GET_USER_SAVED_ADDRESS, payload.user_id))
        yield put(globalAction(SHOW_MESSAGE, data))
        yield delay(2000)
        yield put(globalAction(HIDE_MESSAGE))
        yield delay(1000)
        yield put(globalAction(REMOVE_MESSAGE))
      }
    }
    catch (error) {
      yield put(globalAction(SHOW_MESSAGE, error))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    }
  }

  else if (payload.isBillingAddress === 'yes') {
    try {
      const result = yield call(callApi, addAddressApi, payload)
      const { status, data } = result;
      if (status === 200) {
        localStorage.setItem('billingId', data.data.id)
      }
    }
    catch (error) {

    }
  }
  else{

  }

}


function* deleteAddress({ payload }) {
  try {
    const result = yield call(callApi, deleteAddressApis, payload)
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(GET_USER_SAVED_ADDRESS, payload.user_id))
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
    }
  }
  catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error))
    yield delay(2000)
    yield put(globalAction(HIDE_MESSAGE))
    yield delay(1000)
    yield put(globalAction(REMOVE_MESSAGE))
  }
}

function* updateAddress({ payload }) {
  try {
    const result = yield call(callApi, updateAddressApi, payload)
    const { status, data } = result;
    if (status === 200) {
      yield put(globalAction(GET_USER_SAVED_ADDRESS, payload.user_id))
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(REMOVE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(HIDE_MESSAGE))
    }
  }
  catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error))
    yield delay(2000)
    yield put(globalAction(HIDE_MESSAGE))
    yield delay(1000)
    yield put(globalAction(REMOVE_MESSAGE))
  }
}

function* signupVerify({ payload }) {
  try {
    const result = yield call(callApi, signUpVerifyApi, payload)
    const { status, data } = result
    let email = localStorage.getItem('bellStone_verifyEmail')
    let pass = localStorage.getItem('bellStone_pass')
    if (status === 200) {
      if (data.status === 'error') {
        let tempdata = {
          msg: `You entered wrong OTP.`,
          status: "waring",
        };
        yield put(globalAction(SHOW_MESSAGE, tempdata))
        yield delay(2000)
      }
      else {
        yield put(globalAction(SIGN_IN, { email: email, password: pass, cmhistory: payload.cmhistory }))
        // yield history.push('/dashboard')
        let tempdata = {
          msg: `Email verfied succesfully!.`,
          status: "success",
        };
        yield put(globalAction(SHOW_MESSAGE, tempdata))
        yield delay(2000)

      }
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
      yield put(globalAction(CLOSE_LOADING))
    }
  }
  catch (error) {
    // console.log('catch error in verify', error)
    yield put(globalAction(CLOSE_LOADING))
    yield put(globalAction(SHOW_MESSAGE, error))
    yield delay(2000)
    yield put(globalAction(HIDE_MESSAGE))
    yield delay(1000)
    yield put(globalAction(REMOVE_MESSAGE))
  }

}

function* changePassword({ payload }) {

  try {
    const value = yield call(callApi, updatePasswordApi, payload);
    const { status, data } = value
    if (status === 200) {
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    } else {
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    }

  }
  catch (error) {
    console.log(error);
  }
};



function* socialLogin({ payload }) {
  let history;
  if (payload.forword) {
    const { forword } = payload;
    history = forword;
    delete payload.forword;
  }
  let path = payload.path;
  delete payload.path;

  try {
    const result = yield call(callApi, socialLoginApi, payload);
    const { status, data } = result
    if (status === 200) {
      yield localStorage.setItem('bellStone_idToken', data.access_token)
      yield localStorage.setItem("bellStone_user", JSON.stringify(data.data))
      yield put(globalAction(SET_USER, data.data))
      if (history) {
        yield history.push("/dashboard")
      }

      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    } else {
      yield put(globalAction(SHOW_MESSAGE, data))
      yield delay(2000)
      yield put(globalAction(HIDE_MESSAGE))
      yield delay(1000)
      yield put(globalAction(REMOVE_MESSAGE))
    }

  }
  catch (error) {
    // console.log(error);
  }
};




export function* watchAuthentication() {
  yield takeEvery(SIGN_IN, signIn);
  yield takeEvery(SIGN_UP, signup);
  yield takeEvery(GET_USER_SAVED_ADDRESS, getSavedAddress);
  yield takeEvery(ADD_ADDRESS, add_Address);
  yield takeEvery(DELETE_ADDRESS, deleteAddress);
  yield takeEvery(UPDATE_ADDRESS, updateAddress)
  yield takeEvery(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(SIGNUP_VERIFY, signupVerify)
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  // yield takeEvery(SOCIAL_LOGIN, socialLogin);
};
