import React, { Component } from 'react'
import { Helmet } from 'react-helmet';

export default class seoSetting extends Component {
    render() {
        const { title, canonicalLink, metaDescription, metaKeywords, tags, metaCategory } = this.props;
        return (
            <Helmet>
                <meta charSet="utf-8" />
                {title && <title> {title} </title>}
                {canonicalLink && <link rel="canonical" href= {canonicalLink }/>}
                {metaDescription && <meta name="description" content= {metaDescription} />}
                {metaKeywords && <meta name="keywords" content= {metaKeywords} />}
                {tags && <meta name="tags" content={tags} />}
                {metaCategory && <meta name="category" content= {metaCategory} />}
            </Helmet>
        )
    }
}
