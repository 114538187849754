import React, { useState, useEffect } from 'react'
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { addAddressApi, getCountryApi, getStateApi } from '../../../api';
import { useSelector, useDispatch } from "react-redux";
import {
    ADD_ADDRESS,
    GET_USER_SAVED_ADDRESS,
    globalAction,
    SHOW_LOADING,
    SHOW_MESSAGE,
    REMOVE_MESSAGE,
    HIDE_MESSAGE
} from "../../../redux";
import { withRouter, useHistory } from 'react-router';
import { Link } from "react-router-dom";




function AddressForm(props) {
    let history = useHistory();
    const { message } = useSelector((state) => state.errorReducer);
    const [countryData, setcountryData] = useState([]);
    const [stateData, setstateData] = useState([])
    const [newAddress, setnewAddress] = useState({
        user_id: props.userId,
        full_name: '',
        address_line_1: '',
        address_line_2: '',
        country: '',
        state: '',
        zip: 0,
        mobile_no: '',
        default_address: 'no'
    })
    const [newBillAddress, setnewBillAddress] = useState({
        user_id: props.userId,
        full_name: '',
        address_line_1: '',
        address_line_2: '',
        country: '',
        state: '',
        zip: 0,
        mobile_no: ''
    })

    const [gstInfo, setGstInfo] = useState({
        gst_number: "",
        company_name: "",
        company_address: "",
    });
    const [isGSTBill, setIsGSTBill] = useState("no");
    const [getAddressInfo, setGetAddressInfo] = useState({});
    const [isBillingSame, setisBillingSame] = useState(false);
    const [makeDefault, setmakeDefault] = useState(false)

    const dispatch = useDispatch();
    const getStateData = async (id) => {
        await getStateApi(id)
            .then(async res => {
                const { data, status } = res;
                if (status === 200) {
                    await setstateData(data.state_list)
                }
            })
    }

    const onChangeText = (text, e) => {
        if (text === 'country') {
            getStateData(e.target.value);
        }

        let inputData = newAddress;
        inputData[text] = e.target.value;
        setnewAddress(inputData)
    }



    

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "gst_billing") {
            setIsGSTBill(value);
        }
        let temp = gstInfo;
        temp[name] = value;
        setGstInfo(temp);
    };

    const Validation = () => {
        let isValid = true;
        for (const property in newAddress) {
          if (String(newAddress[property]).trim() === "") {
            isValid = false;
          }
        }
        return isValid;
      };
    

    const finalShippingAddress = (e, item) => {
  
        e.preventDefault();
        if (Validation()) {
        let finalObject = {
            user_id: props.userId,
            full_name: newAddress.full_name,
            address_line_1: newAddress.address_line_1,
            address_line_2: newAddress.address_line_2,
            country: Number(newAddress.country),
            state: Number(newAddress.state),
            zip: Number(newAddress.zip),
            mobile_no: Number(newAddress.mobile_no),
            default_address: makeDefault?'yes':'no',
            isBillingAddress: "no"
        }
        if ( props.userId!=='' && props!==undefined){
            dispatch(globalAction(ADD_ADDRESS, finalObject));
            dispatch(globalAction(SHOW_LOADING));
        }
    }
    else {
        let data = {
          msg: `Please fill up the all fields.`,
          status: "waring",
        };
  
        dispatch(globalAction(SHOW_MESSAGE, data));
        setTimeout(() => {
          dispatch(globalAction(HIDE_MESSAGE));
          dispatch(globalAction(REMOVE_MESSAGE));
        }, 2000);
      }
       

        
    };

    
    useEffect(() => {   
        getCountryApi().then(res => {
            const { data, status } = res;
            if (status === 200) {
                setcountryData(data.country_list)
            }
        })
    }, [])

    useEffect(() => {
        if (message === "Address added successfully") {
            localStorage.setItem("finalShippingAddress", JSON.stringify(newAddress))
            setnewAddress({
                user_id: props.userId,
                full_name: '',
                address_line_1: '',
                address_line_2: '',
                country: '',
                state: '',
                zip: 0,
                mobile_no: ''
            })
            history.push('/dashboard/Address')
        }
    }, [message])


    return (
        <div className={`addressform`}>
            <Form >
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control type="text" placeholder="Full name" onChange={(e) => onChangeText('full_name', e)} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control type="number" placeholder="Mobile" onChange={(e) => onChangeText('mobile_no', e)} />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Address Line 1</Form.Label>
                    <Form.Control type="text" placeholder="Address Line1" onChange={(e) => onChangeText('address_line_1', e)} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridAddress2">
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control type="text" placeholder="Address Line1" onChange={(e) => onChangeText('address_line_2', e)} />
                </Form.Group>

                <Row className="mb-0">
                    <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>Country</Form.Label>
                        <Form.Control as="select" onChange={(e) => onChangeText('country', e)} >
                            <option >Select Country</option>
                            {countryData && countryData.map(item => {
                                const { id, name } = item;
                                return (
                                    <option key={id} selected={id === "101" ? true : false} value={id}>{name}</option>
                                )
                            })}

                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>State</Form.Label>
                        <Form.Control as="select" onChange={(e) => onChangeText('state', e)} >
                            <option value="">Select State</option>
                            {stateData && stateData.map((item, i) => {
                                const { id, name } = item;
                                return (
                                    <option key={id + i} value={id}>{name}</option>
                                )
                            })}

                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="number" placeholder="zip" onChange={(e) => onChangeText('zip', e)} />
                    </Form.Group>
                </Row>
            </Form>
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check type="checkbox" label="Yes, I would like to receive tracking information via SMS (SMS and data rates may apply)." />
            </Form.Group>

            <div>
                <input
                    type="checkbox"
                    id="make_default_address"
                    name="make_default_address"
                    Defaultvalue={makeDefault}
                    onChange={(e) => { 
                        setmakeDefault(!makeDefault)    
                    }}
                />
                <label for="make_default_address">
                     Would you like to make this address default for future orders.?
                </label>
            </div>


            <div className="delivery-btn wrap save">

                <Link
                    onClick={(e) => {
                        finalShippingAddress(e);
                    }}
                    className="button"
                    to="/dashboard/Address"
                >
                    Save 
                </Link>

            </div>
        </div>
    )
}

export default withRouter(AddressForm);
