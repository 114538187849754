import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDeliveryInfoApi } from "../../../api";
import parser from 'react-html-parser'
import i18next from "../../Translation";

function DeliveryInfo() {
  const [state, setState] = useState({
    title:"",
    description:""
  })
  useEffect(() => {
    document.title = "Delivery Information";
    getDeliveryInfoApi().then(res => {
      const { status, data } = res;
      if (status === 200) {
        setState(data.delivery_information)
      }
    })
  }, []);

  return (
    <section className="delivery_info">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                 {`${i18next.t('Home')}`}
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/delivery-info">{`${i18next.t('Delivery Information')}`}</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>{`${i18next.t('DELIVERY INFORMATION')}`}</h5>
              </div>
              <div className="common_pages_content">
                {/* <div className="date">
                  <span>Last updated on</span> <span>29-March-2017</span>
                </div> */}
                {parser(state.description)}
                {/* <div className="common_pages_inner">
                  <h6>DELIVERY INFORMATION:</h6>
                  <p>Bellstoneonline.com provides delivery services fastly through standard courier. The shipment goods are to be delivered within 2 to 7 working days from the date of order and 3 to 4 working days from the date of dispatch of goods. Bellstoneonline.com is neither responsible for nor liable to return/refund/replacement/exchange of goods for the delay caused by the transportation services.</p>

                  <h6>TRACKING OF SHIPMENT:</h6>
                  <p>Bellstoneonline.com provides details of shipment through email alters/sms to the customer while package is handed over to transportation. The email alerts shall contain the tracking number and expected date of delivery. Customers can track the shipment by using the tracking number. It is ensure that the tracking number may take up to 24 business hours to become active for tracking through website.</p>

                  <h6>INCASE OF DELAY:</h6>
                  <p>We will not be held responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause (namely earthquake, war, fire, communal riots, flood, man-power etc.) which is beyond our reasonable control. This condition does not affect your statutory rights.</p>

                  <h6>INCASE OF DELAY:</h6>
                  <ol>
                    <li>
                      Cancellation by us:
                      <ul>
                        <li>There may be certain orders that we are unable to accept the same. We reserve the right, at our sole discretion, to refuse or cancel any order.</li>
                      </ul>
                    </li>

                    <li>
                      Cancellation by users:
                      <ul>
                        <li>You can cancel your order for a product at any time except shipment. Incase of request of order for cancellations, we reserve the right to accept or reject the request of orders of cancellation. If we receive an order for cancellation when the order is not under processed, we shall cancel the order and refund the entire amount within 2 to 7 working days.</li>
                        <li>On the other-hand, a request for cancellation is accepted only if they are made within 24 hours from making of the order on the website. The user agrees not to dispute the decision made by us and accepts our decision regarding the cancellation. The amount will be refunded in the same account as you had made the payment within 2 to 7 working days to initiate the refund.</li>
                      </ul>
                    </li>
                  </ol>

                  <h6>CASH ON DELIVERY POLICY:</h6>
                  <p>Cash on Delivery is also available subject to particular scheme as applicable to us</p>

                  <h6>COLORS:</h6>
                  <p>While we have made every effort to display as accurate as possible, the colors of the products that appear on the website, we can not guarantee that your monitor or screen display of any color will be accurate.</p>
                </div> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DeliveryInfo;
