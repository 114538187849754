import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { forgetPasswordApi, HeaderApi, resetPasswordApi } from '../../../api'
import DemoLogo from '../../../assets/logo-black.png'
import { CLOSE_LOADING, globalAction, REMOVE_MESSAGE, SHOW_LOADING, SHOW_MESSAGE } from '../../../redux'
import './ForgetPassword.scss'
import i18next from '../../Translation'





function ForgetPassword() {
    const [logo, setlLogo] = useState(undefined);
    const [email, setEmail] = useState('')
    const [forgot_otp, setforgot_otp] = useState('')
    const [new_password, setNew_password] = useState('')
    const [confirm_password, setConfirm_password] = useState('')
    const [error, seterror] = useState(undefined);
    const [codeSendSuccess, setcodeSendSuccess] = useState(false);
    const dispatch = useDispatch()
    const formRef = useRef()
    let history = useHistory()


    useEffect(() => {
        window.scroll({ top: 0 })
        localStorage.setItem('url', '/')
        HeaderApi()
            .then((res) => {
                const { data, status } = res;
                if (status === 200) {
                    setlLogo(data.header.logo);
                }
            });
    }, [])


    const handleCodeSend = ((e, type) => {
        e.preventDefault()
        dispatch(globalAction(SHOW_LOADING))
        if (type === 'sendCode' && email.trim() !== '') {
            console.log(email)
            forgetPasswordApi({ email: email })
                .then((res) => {
                    const { data, status } = res;
                    if (status === 200) {
                        console.log(data)
                        if (data.status === 'success') {
                            setcodeSendSuccess(true)
                            formRef.current.reset()
                        }
                        dispatch(globalAction(CLOSE_LOADING))
                        dispatch(globalAction(SHOW_MESSAGE, data))
                        setTimeout(() => {
                            dispatch(globalAction(REMOVE_MESSAGE))
                        }, 1000);
                    }
                })
                .catch((error => {
                    console.log(error)
                    dispatch(globalAction(CLOSE_LOADING))
                    dispatch(globalAction(SHOW_MESSAGE, error))
                    setTimeout(() => {
                        dispatch(globalAction(REMOVE_MESSAGE))
                    }, 1000);
                }));
        }
        else if (type === 'verifyCode' && forgot_otp.trim() !== '' && new_password.trim() !== '' && confirm_password.trim() !== '') {
            let temp = {
                email: email,
                forgot_otp: forgot_otp,
                new_password: new_password,
                confirm_password: confirm_password
            }
            resetPasswordApi(temp)
                .then((res) => {
                    const { data, status } = res;
                    if (status === 200) {
                        console.log(data)
                        if (data.status === 'success') {
                            history.push('/login')
                        }
                        dispatch(globalAction(CLOSE_LOADING))
                        dispatch(globalAction(SHOW_MESSAGE, data))
                        setTimeout(() => {
                            dispatch(globalAction(REMOVE_MESSAGE))
                        }, 1000);
                    }
                })
                .catch((error => {
                    console.log(error)
                    dispatch(globalAction(CLOSE_LOADING))
                    dispatch(globalAction(SHOW_MESSAGE, error))
                    setTimeout(() => {
                        dispatch(globalAction(REMOVE_MESSAGE))
                    }, 1000);
                }));
        }
        else (
            seterror('Please fill all  field.')
        )
    })

    return (
        <div className="forget-pass-wrap">
            <div className="forget-inner">
                <div className="logo-wrap">
                    <img src={logo || DemoLogo} alt="StarApple.com" />
                </div>

                <div className="forget-pass-form">
                    <div className="fpf-inner">
                        <h1>
                            {codeSendSuccess ?
                                <><span className="green-text">{`${i18next.t('Verification')}`}</span> {`${i18next.t('required')}`} </>
                                :
                                <><span className="green-text">{`${i18next.t('Forget')}`}</span> {`${i18next.t('Password')}`} </>
                            }
                        </h1>
                        <p>
                            {codeSendSuccess ?
                                `${i18next.t("To continue, complete this verification step. We've sent an OTP to the email")} ${email}. ${i18next.t("Please enter it below to complete verification.")}`
                                :
                                `${i18next.t('Enter the email address  associated with your Bellstone account.')}`
                            }
                        </p>
                        {error &&
                            <div className="error-wrap">
                                <p>{error}</p>
                            </div>
                        }

                        {!codeSendSuccess ?
                            <form onSubmit={(e) => { handleCodeSend(e, 'sendCode') }} autocomplete="off">
                                <input
                                    id="email"
                                    required
                                    type="email"
                                    placeholder={`${i18next.t('Enter your registered email')}`}
                                    onChange={(e) => { setEmail(e.target.value) }}
                                />
                                <div className="btn-wrap">
                                    <Button type='submit'>{`${i18next.t('Send')}`}</Button>
                                </div>
                            </form>
                            :
                            <form
                                onSubmit={(e) => { handleCodeSend(e, 'verifyCode') }}
                                autocomplete="off"
                                ref={formRef} >
                                <input
                                    id="otp"
                                    required
                                    type="text"
                                    defaultValue={forgot_otp}
                                    placeholder={`${i18next.t('Enter one time password')}`}
                                    onChange={(e) => { setforgot_otp(e.target.value) }}
                                />
                                <input
                                    id="password"
                                    required
                                    type="text"
                                    placeholder={`${i18next.t('Enter new password')}`}
                                    onChange={(e) => { setNew_password(e.target.value) }}
                                />
                                <input
                                    id="c_password"
                                    required
                                    type="text"
                                    placeholder={`${i18next.t('Confirm new password')}`}
                                    onChange={(e) => { setConfirm_password(e.target.value) }}
                                />

                                <div className="btn-wrap">
                                    <Button type='submit'>{`${i18next.t('Reset Password')}`}</Button>
                                </div>
                            </form>

                        }

                    </div>
                </div>

            </div>
        </div>
    )
}

export default ForgetPassword
