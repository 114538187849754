export const french = {
    translation: {
        //home page menu
        "Welcome_to_React": "Welcome to English",
        "All Categories": "Toutes Catégories",
        "View All": "Voir Tout",
        "All": "Tout",
        "Best Seller": "Best Seller",
        "Brands": "Marques",
        "Brand": "Marque",
        "Electronics": "Électronique", 
        "Regular Price": "Prix Habituel", 
        "Special Price": "Prix Spécial", 
        "Price": "Prix", 
        "Latest Product": "Dernier Produitx", 
        "Shop order": "Commande en boutique", 
        "Sorting": "Tri", 
        "Show": "Montrer", 
        "CUSTOMER SERVICES": "SERVICE CLIENT", 
        "Customer Services": "Service client", 
        "Contact Us": "Contactez-nous", 
        "Low": "Faible", 
        "High": "Haut", 
        "Review": "Examen", 
        "OFF": "DÉSACTIVÉ", 
        "sale": "vente", 
        "Bulb Technology: Halogen": "Technologie d'ampoule : Halogène", 
        "Bulb Color: Clear": "Couleur de l'ampoule : transparente", 
        "Package Quantity": "Quantité de paquet", 
        "Power: 40 MW": "Puissance : 40 MW", 
        "Voltage: 12 VDC": "Tension : 12 Vcc", 
        "Add to cart": "Ajouter au panier", 
        "Add to wishlist": "Ajouter à la liste de souhaits", 
        "No Products Found...!": "Aucun produit trouvé... !", 
        "Dashboard": "Tableau de bord", 
        "Logout": "Se déconnecter", 
        "Logout": "S'identifier", 
        "Wishlist": "Liste de souhaits", 
        "Minimum": "Le minimum", 
        "Maximum": "Maximum", 
        "per QTY": "par QTÉ", 
        "Your is cart empty": "Votre panier est vide", 
        "View Cart": "Voir le panier", 
        "Main Menu": "Menu Principal", 
        "Home": "D'accueil", 
        "About Us": "À propos de nous", 
        "Blog": "Blog", 
        "Blogs": "Blogues", 
        "Contact us": "Contactez-nous", 
        "Category": "Catégorie", 
        "Account": "Compte", 
        "Cart": "Chariot", 
        "Search product by title,supplier": "Rechercher un produit par titre, fournisseur", 
        "Privacy Policy": "Politique De Confidentialité", 
        "Terms & Conditions": "Termes & Conditions", 
        "Delivery Information": "Informations de livraison", 
        "Returns & Refunds": "Retours & Remboursements", 
        "Payment Methods": "Méthodes de Payement", 
        "Sell On Bellstone": "Vendre sur Bellstone", 
        "Bulk Order": "Commande en vrac",
        "Offline Business": "Entreprise Hors Ligne", 
        "Important Links": "Liens importants", 
        "News & Events": "Nouvelles & Événements", 
        "Top Categories": "Principales catégories", 
        "Top Brands": "Meilleures Marques", 
        "Newsletter": "Bulletin", 
        "Subscribe Our newsletter to get our latest update & news": "Abonnez-vous à notre newsletter pour recevoir nos dernières mises à jour et nouvelles", 
        "Please login or Register to save this product.": "Veuillez vous connecter ou vous inscrire pour enregistrer ce produit.", 
        "are successfully added in your cart": "sont ajoutés avec succès dans votre panier", 
        "Share Your Thoughts With Other Customers": "Partagez vos pensées avec d'autres clients", 
        "Please login or register for add review!": "Veuillez vous connecter ou vous inscrire pour ajouter un avis !", 
        "CUSTOMER REVIEWS": "AVIS DES CLIENTS", 
        "Customer Reviews on": "Avis des clients sur", 
        "Average rating based on": "Note moyenne basée sur", 
        "ratings": "évaluations", 
        "Enter you comment": "Entrez votre commentaire", 
        "Rate the Product": "Évaluez le produit", 
        "Add Review": "Ajouter un commentaire", 
        "Top Customer Reviews": "Meilleurs avis clients", 
        "FILTER BY": "FILTRER PAR", 
        "Start Rating": "Commencer l'évaluation", 
        "Verified User": "Utilisateur vérifié", 
        "0 of 0 users found this review helpful. Was this review helpful?": "0 utilisateurs sur 0 ont trouvé cet avis utile. Cet avis vous a-t-il été utile ?", 
        "No reviews.": "Aucun avis.", 
        "Best Deals On": "Meilleures offres sur", 
        "New Products": "Nouveaux Produits", 
        "Most Popular Products": "Produits Les Plus Populaires", 
        "Best Selling Products": "Produits Les Plus Vendus", 
        "already added in your cart": "déjà ajouté dans votre panier", 
        "Items Available": "Articles Disponibles", 
        "Related Products": "Produits connexes", 
        "404 Not Found Page": "404 Page Introuvable", 
        "Already added in compare list": "Déjà ajouté dans la liste de comparaison", 
        "Compare Products": "Comparer Les Produits", 
        "by": "par", 
        "Reviews": "Commentaires", 
        "Weight": "Lester", 
        "Description": "Description", 
        "Additional Information": "Informations Complémentaires", 
        "Out of Stock": "Rupture de stock", 
        "Add product to compare": "Ajouter un produit à comparer", 
        "Search": "Recherche", 
        "No product found..!": "Aucun produit trouvé..!", 
        "Lets Compare": "Comparons", 
        "Load more posts": "Charger plus de messages",
        "No blog found in this category": "Aucun blog trouvé dans cette catégorie",
        "Categories": "Catégories",
        "Most Popular": "Le plus populaire",
        "Share": "Partager",
        "Blog Detail": "Détail du blog",
        "Author": "Auteur",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy": "Lorem Ipsum est simplement un faux texte de l'industrie de l'impression et de la composition. Lorem Ipsum a été le mannequin standard de l'industrie",
        "Comment": "Commentaire",
        "Reply Comments": "Répondre aux commentaires",
        "Please enter your comments.": "Veuillez entrer vos commentaires.",
        "Post": "Publier",
        "Recent Post": "Post récent",
        "Search Keywoards": "Rechercher des mots-clés",
        "Update Address": "Mettre à jour l'adresse",
        "Full Name": "Full Name",
        "Mobile": "Mobile",
        "Address Line": "Ligne d'adresse",
        "Country": "Pays",
        "Select Country": "Choisissez le pays",
        "State": "État",
        "Select State": "Sélectionnez l'état",
        "Zip": "Code PIN",
        "Would you like to make this address default for future orders.?": "Souhaitez-vous définir cette adresse par défaut pour les commandes futures ?",
        "Yes, I would like to receive tracking information via SMS (SMS and data rates may apply).": "Oui, je souhaite recevoir des informations de suivi par SMS (des frais de SMS et de données peuvent s'appliquer).",
        "Update and Save": "Mettre à jour et enregistrer",
        "Terms and Conditions": "Termes et conditions",
        "TERMS & CONDITIONS OF SALE:(BETWEEN SELLER AND THE CUSTOMER)": "CONDITIONS GÉNÉRALES DE VENTE :(ENTRE LE VENDEUR ET LE CLIENT)",
        "Last updated on": "Dernière mise à jour le",
        "Email": "E-mail",
        "Please fill up the all fields.": "Veuillez remplir tous les champs.",
        "Welcome": "Bienvenue",
        "Back!": "Dos!",
        "Sign In": "S'identifier",
        "Please Sign In to your account.": "Connectez-vous à votre compte s'il vous plaît.",
        "Please enter your valid email.": "Veuillez entrer votre email valide.",
        "Password": "Mot de passe",
        "Please enter your password.": "S'il vous plait entrez votre mot de passe.",
        "Forget Password?": "Mot de passe oublié?",
        "Log In Now": "Connecte-toi maintenant",
        "OR Login with": "OU Connectez-vous avec",
        "Don't Have account?": "Vous n'avez pas de compte ?",
        "Signup!": "S'inscrire!",
        "Verification": "S'inscrire!",
        "required": "requis",
        "Forget": "Oublier",
        "To continue, complete this verification step. We've sent an OTP to the email": "Pour continuer, effectuez cette étape de vérification. Nous avons envoyé un OTP à l'e-mail",
        "Please enter it below to complete verification.": "Veuillez le saisir ci-dessous pour terminer la vérification.",
        "Enter your registered email": "Entrez votre email enregistré",
        "Send": "Envoyer",
        "Enter one time password": "Entrez un mot de passe à usage unique",
        "Enter new password": "Entrez un nouveau mot de passe",
        "Confirm new password": "Confirmer le nouveau mot de passe",
        "Reset Password": "réinitialiser le mot de passe",
        "Already have an account?": "Vous avez déjà un compte?",
        "Login": "Connexion",
        "Register": "Enregistrer",
        "Please enter repeate password.": "Veuillez saisir le mot de passe répété.",
        "Please enter password.": "Veuillez entrer le mot de passe.",
        "Please enter contact number.": "Veuillez entrer le numéro de contact.",
        "Please enter valid email.": "Veuillez saisir une adresse e-mail valide.",
        "Please enter your name.": "S'il vous plaît entrez votre nom.",
        "Create your account here.": "Créez votre compte ici.",
        "Here": "Ici",
        "Full name": "Nom et prénom",
        "Repeat Password": "Répéter le mot de passe",
        "Your order is complete !": "Votre commande est terminée !",
        "Your will be receiving a conformation email with order detail": "Vous recevrez un e-mail de confirmation avec les détails de la commande",
        "we will redirect to homepage in": "nous allons rediriger vers la page d'accueil dans",
        "Back to home": "De retour à la maison",
        "View orders": "Afficher les commandes",
        "Add Shipping Address": "Ajouter une adresse de livraison",
        "Bellstone does not ship to PO Box, mailbox, and APO/FPO addresses.": "Bellstone n'expédie pas vers les boîtes postales, les boîtes aux lettres et les adresses APO/FPO.",
        "First Name": "Prénom",
        "Last Name": "Nom de famille",
        "Contact Number": "Numéro de contact",
        "Address": "Adresse",
        "City": "Ville",
        "Order Summary": "Récapitulatif de la commande",
        "Items": "Articles",
        "Subtotal:": "Total:",
        "Est. Shipping:": "Est. Expédition:",
        "Order Total:": "Total de la commande:",
        "Place Order": "Passer la commande",
        "RETURN POLICY": "POLITIQUE DE RETOUR",
        "Please fill up the all fields.": "Veuillez remplir tous les champs.",
        "Feedback Form": "Formulaire de commentaires",
        "Give your valuable feedback about our products which helps to our other customers.": "Donnez vos précieux commentaires sur nos produits, ce qui aide nos autres clients.",
        "Feedback Form": "Formulaire de commentaires",
        "Select Category": "Choisir une catégorie",
        "Select Sub Category": "Sélectionnez la sous-catégorie",
        "Select product & give your feedback.": "Sélectionnez le produit et donnez votre avis.",
        "Products Not Found": "Produits introuvables",
        "Add your valuable feedback.": "Ajoutez vos précieux commentaires.",
        "Next": "Suivant",
        "Feedback": "Retour",
        "Rating": "Notation",
        "Name": "Nom",
        "Prev": "Précédent",
        "Submitting..": "Soumission..",
        "Enter your fullname": "Entrez votre nom complet",
        "Enter your email": "Entrer votre Email",
        "Enter your mobile number": "Entrez votre numéro de mobile",
        "write your feedback": "écrivez vos commentaires",
        "GET BULK QUOTE NOW": "OBTENEZ UN DEVIS EN GROS MAINTENANT",
        "Want to buy even more quantity?": "Vous voulez acheter encore plus de quantité ?",
        "Shipping charge applicable as per serviceability": "Frais d'expédition applicables selon l'état de fonctionnement",
        "Check": "Vérifier",
        "Check delivery and payment options ?": "Vérifier les options de livraison et de paiement ?",
        "Area PIN Code": "Code PIN de zone",
        "Out Of Stock": "Rupture de stock",
        "Add to wishlist": "Ajouter à la liste de souhaits",
        "Buy Now": "Acheter maintenant",
        "Send Enquiry": "Envoyer la demande",
        "Add to Compare": "Ajouter Pour Comparer",
        "None": "Aucun",
        "Model": "Modèle",
        "Brand": "Marque",
        "Weight": "Lester",
        "Color": "Couleur",
        "Inclusive Price:": "Prix inclus:",
        "You can only compare similar product": "Vous ne pouvez comparer que des produits similaires",
        "Item successfully deleted in your cart": "Article supprimé avec succès dans votre panier",
        "Already added in your cart with same quantity": "Déjà ajouté dans votre panier avec la même quantité",
        "Please login or Register to save this product.": "Veuillez vous connecter ou vous inscrire pour enregistrer ce produit.",
        "Payment Methods": "Méthodes De Payement",
        "PAYMENT METHODS": "MÉTHODES DE PAYEMENT",
        "You need to verified your email account for first time to login your": "Vous devez vérifier votre compte de messagerie pour la première fois pour vous connecter à votre",
        "We already sent a verification code to your email": "Nous avons déjà envoyé un code de vérification à votre adresse e-mail",
        "Verify your": "Vérifier votre",
        "email": "e-mail",
        "Verify": "Vérifier",
        "Required": "Requis",
        "Not Deliverable": "Non livrable",
        "Pay & Placed Order": "Payer et passer commande",
        "Credit Card / Debit Card / Net Banking / UPI": "Carte de crédit / Carte de débit / Net Banking / UPI",
        "COD (Cash On Delivery)": "COD (paiement à la livraison)",
        "Order Total:": "Total de la commande:",
        "Est. shipping date:": "Est. date d'expédition:",
        "Shipping price:": "Prix de l'expédition:",
        "REMOVE": "RETIRER",
        "ITEM TOTAL": "OBJET TOTAL",
        "Unit Price": "Prix unitaire",
        "Product id": "Identifiant du produit",
        "Orders": "Ordres",
        'BEST SELLING': 'MEILLEURE VENTE',
        "Billing Details": "Détails de la facturation",
        "Phone": "Téléphone:",
        "Billing Address": "adresse de facturation",
        "Delivery Address": "Adresse de livraison",
        "Selected Payment Option": "Option de paiement sélectionnée",
        "Thankyou! For Order": "Merci! Pour la commande",
        "By placing your order, you agree to bellstone policies and agreements": "En passant votre commande, vous acceptez les politiques et accords de Bellstone",
        "Review Your Delivery, Biliing, GST, & Orders.": "Passez en revue votre livraison, votre facturation, la TPS et vos commandes.",
        "Edit": "Modifier",
        "Price": "Prix",
        "Fill Delivery Address": "Remplir l'adresse de livraison",
        "DELIVERY INFORMATION": "INFORMATIONS DE LIVRAISON",
        "Delivery Information": "Informations de livraison",
        "Message": "Message",
        "Email address": "Adresse e-mail",
        "Subject": "Sujet",
        "Get In Touch": "Entrer en contact",
        "You email address will not be published. Required fields are marked": "Votre adresse e-mail ne sera pas publiée. les champs requis sont indiqués",
        "Follow US:": "Suivez-nous:",
        "Store Location:": "Emplacement du magasin:",
        "Call US:": "Appelez-nous :",
        "Contact With US!": "En contact avec nous!",
        "Contact us": "Contactez-nous",
        "Enter Email address": "Entrer l'adresse e-mail",
        "Would you like to make this address default for future orders.?": "Souhaitez-vous définir cette adresse par défaut pour les commandes futures ?",
        "Company name": "Nom de l'entreprise",
        "Company Address": "Adresse de la société",
        "Save & Deliver to this address": "Enregistrer et livrer à cette adresse",
        "Add new Address": "Ajouter une nouvelle adresse",
        "Remove": "Retirer",
        "Selected for Billing": "Retirer",
        "Continue": "Continuer",
        "Enter valid Gst number": "Entrez un numéro de TPS valide",
        "Shopping Cart": "Panier",
        "Shipping Update": "Mise à jour de l'expédition",
        "As major carriers like FedEx and UPS have acknowledged, unprecedented demand may cause 1-2 day shipping delays from the ETA given at checkout. We apologize for any inconvenience.": "Comme l'ont reconnu les principaux transporteurs comme FedEx et UPS, une demande sans précédent peut entraîner des retards d'expédition de 1 à 2 jours par rapport à l'ETA indiqué au moment du paiement. Nous nous excusons pour tout inconvénient.",
        "QTY": "QTÉ",
        "Your Cart Is Empty": "Votre panier est vide",
        "Proceed to checkout": "Passer à la caisse",
        "Add Products to checkout": "Ajouter des produits à la caisse",
        "Key Features": "Principales caractéristiques",
        "Cancel Order": "Annuler La Commande",
        "Are you sure to Cancel this order..?": "Êtes-vous sûr d'annuler cette commande..?",
        "Cancellation Reason": "Motif d'annulation",
        "optional": "facultatif",
        "Cancel": "Annuler",
        "Cancel Order": "Annuler La Commande",
        "Order Detail": "Détails De La Commande",
        "View/Download Invoice": "Afficher/Télécharger la facture",
        "Invoice": "Facture",
        "Track Order": "Suivi De Commande",
        "Order Number": "Numéro de commande",
        "Date & Time": "Date Et Heure",
        "Status": "Statut",
        "StatusPayment Method": "Mode de paiement",
        "Pay with cash upon delivery.": "Payez en espèces à la livraison.",    
        "Order Details": "Détails De La Commande",
        "Item": "Article",
        "Price/Unit": "Prix ​​à l'unité",
        "Qty": "Qté",
        "Total": "Total",
        "HSN code": "Code HSN ",
        "Including GST": "TPS incluse",
        "Shipping Cost": "Frais de port",
        "Gst Charges": "Frais de TPS",
        "Grand Total": "Total",
        "Shipping Address": "Adresse De Livraison",
        "Company": "Entreprise",
        "PostCode": "Code Postal",
        "Phone Number": "Numéro De Téléphone",
        "Fax Number": "Numéro De Fax",
        "Email Address": "Adresse e-mail",
        "Company": "Entreprise",
        "GST Billing": "Facturation de la TPS",
        "GST Number": "Facturation de la TPS",
        "Compnay Name": "Nom de l'entreprise",
        "Company Addredd": "Adresse de la société",
        "Items Available": "Articles disponibles",
        "Keep me Log In": "Gardez-moi Connexion",
        "User Dashboard": "Tableau de bord utilisateur",
        "New Orders": "Nouvelles commandes",
        "Total Orders": "Commandes totales",
        "Saved Products": "Produits enregistrés",
        "Recent Orders": "Dernières commandes",
        "Order no": "N ° de commande",
        "Payment mode": "Mode de paiement",
        "Order At": "Commandez à",
        "Read more": "En savoir plus",
        "Dashboard": "Tableau de bord",
        "My Account": "Mon compte",
        "Saved Address": "Adresse enregistrée",
        "My Order": "Ma commande",
        "Bulk Enquiry": "Demande groupée",
        "Contact Information": "Demande groupée",
        "Please enter your email.": "Veuillez saisir votre e-mail.",
        "Please enter your contact number.": "Veuillez entrer votre numéro de téléphone.",  
        "Upload Profile": "Télécharger le profil",  
        "Change image": "Changer l'image",  
        "Save": "Sauvegarder",  
        "Current Password": "Mot de passe actuel",  
        "Please enter your current password.": "Veuillez saisir votre mot de passe actuel.",  
        "New Password": "Nouveau Mot De Passe",  
        "Please enter your new password.": "Veuillez entrer votre nouveau mot de passe.",  
        "Confirm new Password": "Confirmer le nouveau mot de passe",  
        "Please enter your confirm password.": "Veuillez entrer votre mot de passe de confirmation.",  
        "Selected for delivery": "Sélectionné pour la livraison",  
        "Select delivery address": "Sélectionnez l'adresse de livraison",  
        "All Enquires": "Toutes les demandes",  
        "Pending": "En attente",  
        "Enquiry Id": "Identifiant de la demande",  
        "Product quantity which you inquired ?": "Quantité de produit que vous avez demandée ?",  
        "Pieces": "Pièces",  
        "Your initial Message is": "Votre message initial est",  
        "View Conversation": "Afficher la conversation",  
        "Product Info": "Information sur le produit",  
        "Delete Request": "Supprimer la demande",  
        "No enquiries": "Aucune demande",  
        "Out of stock": "Rupture de stock",  
        "By creating an account you agree to our Terms & Privacy": "En créant un compte, vous acceptez nos Conditions d'utilisation et Confidentialité",  
        "previous": "précédent",  
        "next": "suivant",
        "Quantity": "Quantité",
        "Product Name": "Nom du produit",
        "Product ID": "Identifiant du produit",
        "Want to buy even more quantity ?": "Vous voulez acheter encore plus de quantité ?",
        "How many quanity you wanna buy, Enter here.": "Combien de quantité vous voulez acheter, entrez ici.",
        "Enter Mobile Number": "Entrez le numéro de portable",
        "Enter email": "Entrez l'e-mail",
        "Enter Full Name": "Entrez le nom complet",
    },
};
