// Imports: Dependencies
import { all, fork} from 'redux-saga/effects';
// Imports: Redux Sagas
import { watchAuthentication } from './authSaga';
import {watchAppSaga} from './appSaga'
// Redux Saga: Root Saga
export function* rootSaga () {
  yield all([
    fork(watchAppSaga),
    fork(watchAuthentication)
    //fork(watchDecreaseCounter),
  ]);
};