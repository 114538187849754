import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getPaymentMethodPolicyApi } from "../../../api";
import parser from 'react-html-parser'
import "./paymentMethods.scss";
import i18next from "../../Translation";

function PaymentMathods() {
  const [state, setState] = useState({
    title:"",
    description:"",
    description:""
  })

  useEffect(() => {
    document.title = "Payment Methods";
    getPaymentMethodPolicyApi().then(res => {
      const { status, data } = res;
      console.log(data)
      if (status === 200) {
        setState(data.payment_methods)
      }
    })
  }, []);

  return (
    <section className="payment_methods">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                {`${i18next.t('Home')}`}
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/payment-methods">{`${i18next.t('Payment Methods')}`}</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>{`${i18next.t('PAYMENT METHODS')}`}</h5>
              </div>
              <div className="common_pages_content">
                {/* <div className="date">
                  <span>Last updated on</span> <span>29-March-2017</span>
                </div> */}
                <div className="common_pages_inner">
                  {parser(state.description)}
                  {parser(state.description_1)}
                  {/* <h6>WE ACCEPT CREDIT CARDS OR DEBIT CARD</h6>

                  <h6>MODE OF PAYMENT</h6>
                  <p>The customer may pay either through credit / debit card or through Net banking.</p>

                  <div className="payment_mode">
                    <h6>PAYMENT MODE FOR INDIAN BANKS</h6>
                    <p>
                      <b>Account Name:</b>
                      BELLSTONE HITECH INTERNATIONAL LIMITED
                    </p>
                    <p>
                      <b>Account Number:</b>
                      113205501128
                    </p>
                    <p>
                      <b>Bank Name:</b>
                      ICICI Bank
                    </p>
                    <p>
                      <b>Branch Address:</b>
                      3761-65, Chawri Bazaar,Delhi-110006
                    </p>
                    <p>
                      <b>Bank IFSC Code:</b>
                      ICIC0001132
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PaymentMathods;
