import React, { useEffect, useState } from "react";
import "./SuccessMessage.scss";
import { Row, Container, Col } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../../Translation";
import { DELETE_ALL_ADD_TO_CART_FROM_DB, globalAction } from "../../../redux";



function SuccessMessage() {
  const [redirectTime, setredirectTime] = useState(10)
  const {user} = useSelector(state => state.authReducer)
  const dispatch = useDispatch  ()
  let history = useHistory()
 
  useEffect(() => {
    if (redirectTime > 0) {
      setTimeout(() => {
        setredirectTime(redirectTime - 1)
      }, 1000);
    }
    else {
      if(user){
        dispatch(globalAction(DELETE_ALL_ADD_TO_CART_FROM_DB,user?.id))
      }
      history.push('/')
     
    }
  })

  return (
    <section className="success_message">
      <Container>
        <div className="message">
          <div className="icon">
            <span>
              <FaCheckCircle />
            </span>
          </div>
          <div className="card_text">
            <h3>{`${i18next.t('Your order is complete !')}`}</h3>
            <p>{`${i18next.t('Your will be receiving a conformation email with order detail')}`}</p>
            <p><small>{`${i18next.t('we will redirect to homepage in')}`} {redirectTime}s</small></p>
          </div>
          <div className="button_wrap">
            <Link to="/" className="btn btn-primary">
            {`${i18next.t('Back to home')}`}
            </Link>
            <Link to="/dashboard/my-order" className="btn btn-primary">
            {`${i18next.t('View orders')}`}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default SuccessMessage;
