import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Pagination } from "react-bootstrap";
import { globalAction } from "../../../redux/actions";
import {
  ADD_COMPARE,
  ADD_TO_CART,
  GET_SAVED_PRODUCTS,
  REMOVE_SAVE_PROD,
  SHOW_LOADING,
  SHOW_TOAST,
} from "../../../redux/actionTypes";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";
import ReactStars from "react-rating-stars-component";
import "./savedProducts.scss";
import { BiRupee } from "react-icons/bi";
import i18next from "../../Translation";

class SavedProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
    };
  }

  componentDidMount() {
    const {
      user: { id },
      getSavedProducts,
      showLoading,
      add_to_cart,
    } = this.props;
    const { limit } = this.state;
    showLoading();
    getSavedProducts({ id, limit });
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      add_to_cart(preStoredItems);
    }
  }

  onSelectPage = (page) => {
    const {
      user: { id },
      getSavedProducts,
    } = this.props;
    const { limit } = this.state;
    getSavedProducts({ id, limit, page });
  };

  onPagination = () => {
    const {
      savedProducts: { last_page, current_page },
    } = this.props;
    let arr = [];
    if (last_page < 10) {
      for (let number = 1; number <= last_page; number++) {
        arr.push(
          <Pagination.Item
            active={current_page === number}
            onClick={() => this.onSelectPage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      for (let number = 1; number <= 10; number++) {
        arr.push(
          <Pagination.Item
            active={current_page === number}
            onClick={() => this.onSelectPage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    return arr;
  };

  onAddCompare = (id, cat) => {
    const { compareList, addToCompare, history, showToast } = this.props;
    let { data, category } = compareList;
    if (data.includes(id)) {
      showToast({
        data: {
          msg: `${i18next.t("Already added in compare list")}`,
          status: "warning",
        },
      });
    } else if (data.length === 0 || cat.id === category.id) {
      compareList["category"] = cat;
      data.push(id);
      addToCompare(compareList);
      history.replace("/compare-product");
    } else if (cat.id !== category.id) {
      showToast({
        data: {
          msg: `${i18next.t("You can only compare similar product")}`,
          status: "warning",
        },
      });
    }
  };

  onRemoveProduct = (id) => {
    const { user, removeSavedProduct, cart, add_to_cart } = this.props;
    const { limit } = this.state;

    removeSavedProduct({ id, user_id: user.id, limit });

    let preStoredWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    const updatedWishlist = preStoredWishlist.filter((item) => item.id !== id);

    localStorage.removeItem("wishlist", JSON.stringify(updatedWishlist));
  };

  addToCart = (item) => {
    const { showToast, add_to_cart, cart } = this.props;
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} ${i18next.t("already added in your cart")}`,
            status: "warning",
          };
          showToast({ data });
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} ${i18next.t(
            "are successfully added in your cart"
          )}`,
          status: "success",
        };
        showToast({ data });
        localStorage.setItem("cart", JSON.stringify(cart));
        add_to_cart(cart);
      }
    }
  };

  render() {
    const {
      savedProducts: { current_page, last_page, data },
      isLoading,
    } = this.props;
    return (
      <div className="row listing-row mt-4">
        {isLoading ? (
          <AnimatedLogo />
        ) : (
          <>
            {data &&
              data.map((item) => {
                const { id, product } = item;
                if (product !== null) {
                  return (
                    <div className="item" key={id}>
                      <div className="card item-card mb-3">
                        <div className="row item-row no-gutters">
                          <div className="col-md-3 left-3">
                            <Link
                              to={`/${product?.category?.slug}/${product?.subcategory?.slug}/${product?.slug}`}
                            >
                              <img
                                src={product && product.image_url}
                                className="card-img-top"
                                alt="Prod1"
                              />
                            </Link>
                            {product && product.stock_qty > 0 ? (
                              <span className="sale">{`${i18next.t(
                                "sale"
                              )}`}</span>
                            ) : (
                              <span className="out-of-stock">{`${i18next.t(
                                "Out of stock"
                              )}`}</span>
                            )}
                          </div>

                          <div className="col-md-6 item-6">
                            <div className="card-body">
                              <Link
                                to={`/${product?.category?.slug}/${product?.subcategory?.slug}/${product?.slug}`}
                              >
                                <h6 className="card-title">
                                  {product && product.title}
                                </h6>
                              </Link>
                              <div className="review">
                                <ReactStars
                                  count={5}
                                  onChange={this.ratingChanged}
                                  size={20}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                  value={product && product.avg_rating}
                                />
                                <span className="text">
                                  {product && product.product_review_count > 0
                                    ? `(${
                                        product.product_review_count
                                      })  ${i18next.t("Reviews")}`
                                    : `(${
                                        product.product_review_count
                                      }) ${i18next.t("Review")}`}
                                </span>
                              </div>
                              <div className="des">
                                <ul>
                                  <li>{`${i18next.t(
                                    "Bulb Technology: Halogen"
                                  )}`}</li>
                                  <li>{`${i18next.t("Bulb Color: Clear")}`}</li>
                                  <li>
                                    {`${i18next.t("Package Quantity:")}`}{" "}
                                    {product && product.stock_qty}
                                  </li>
                                  <li>{`${i18next.t("Power: 40 MW")}`}</li>
                                  <li>{`${i18next.t("Voltage: 12 VDC")}`}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 right-3">
                            <i
                              className="fa fa-close closeIcon"
                              onClick={() => this.onRemoveProduct(id)}
                            />
                            <h5>
                              <span>
                                {" "}
                                <BiRupee />
                                {Number(product && product.sale_price).toFixed(
                                  2
                                )}
                              </span>
                              <span>
                                <del>
                                  <BiRupee />
                                  {Number(
                                    product && product.regular_price
                                  ).toFixed(2)}
                                </del>
                              </span>
                            </h5>
                            <div className="add">
                              {product && product.stock_qty > 0 ? (
                                <Link
                                  onClick={() =>
                                    product &&
                                    product.stock_qty > 0 &&
                                    this.addToCart(product)
                                  }
                                >{`${i18next.t("Add to cart")}`}</Link>
                              ) : (
                                <Link className="Out-of-stock-btn">{`${i18next.t(
                                  "Out Of Stock"
                                )}`}</Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return true;
              })}
          </>
        )}
        <div className="card pagination">
          <Pagination>
            <Pagination.Prev
              disabled={current_page === 1}
              onClick={() => {
                this.onSelectPage(current_page - 1);
              }}
            />
            {this.onPagination()}
            <Pagination.Next
              disabled={current_page === last_page}
              onClick={() => {
                this.onSelectPage(current_page + 1);
              }}
            />
          </Pagination>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    AppReducer: { savedProducts, compareList, cart },
    authReducer: { user },
    errorReducer: { isLoading },
  } = state;
  return { savedProducts, user, isLoading, compareList, cart };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSavedProducts: (data) =>
      dispatch(globalAction(GET_SAVED_PRODUCTS, data)),
    showLoading: (data) => dispatch(globalAction(SHOW_LOADING)),
    addToCartList: (data) => dispatch(globalAction(ADD_TO_CART, data)),
    addToCompare: (data) => dispatch(globalAction(ADD_COMPARE, data)),
    showToast: (data) => dispatch(globalAction(SHOW_TOAST, data)),
    removeSavedProduct: (data) =>
      dispatch(globalAction(REMOVE_SAVE_PROD, data)),
    add_to_cart: (data) => dispatch(globalAction(ADD_TO_CART, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedProducts);
