import React, { useEffect } from "react";
import { store } from "./redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Router from "./routing/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import'./components/Translation/index';
import Whatappswidget from '../src/components/Whatapps-widget'


function App() {
 
  return (
    <>
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
    </>
  );
}

export default App;
