
import React, {useState } from 'react'

 import CivilLab from '../../../assets/product/civil_lab1.jpg'
import {  Form,Col,InputGroup,Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './register.scss'
import {connect} from 'react-redux'
import { globalAction } from '../../../redux/actions';
import i18next from '../../Translation';
import { HIDE_MESSAGE, SHOW_TOAST, SIGN_UP } from '../../../redux/actionTypes';

const initialState = {
    name: '',
    email: '',
    mobile: '',
    password: '',
    psw_repeat: '',
}
function Register(props) {
    
    const [validated, setValidated] = useState(false);
    const [state, setState] = useState(initialState)
    const changeText=(value,text)=>{
        let credential=state;
        credential[text]=value.target.value
        setState(credential)
    }
 
    const handleSubmit = (event ) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }else{
            event.preventDefault();
            const {name,mobile,password,psw_repeat,email}=state
            let finalObject={name,email,password,psw_repeat,mobile}
            finalObject.forword=props.history;
            props.signup(finalObject)
            // props.history.push("/")
        }
        
        setValidated(true);
    };
        return (
            <div>
                <div className="full-width-container register">
                    <div className="container-md register-container">
                        <div className="row register-row">
                            <div className="col-md-6 register-img">
                                <img src={CivilLab} className="img-fluid" alt="login-img" />
                            </div>
                            <div className="col-md-6 register-6">
                                <div className="heading">
                                    <h2><span className="green-text">{`${i18next.t('Register')}`}</span>{`${i18next.t('Here')}`}</h2>
                                    <p>{`${i18next.t('Create your account here.')}`}</p>
                                </div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={`${i18next.t('Full name')}`}
                                            onChange={(value)=>changeText(value,'name')}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {`${i18next.t('Please enter your name.')}`}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationEmail">
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder={`${i18next.t('Email')}`}
                                                onChange={(value)=>changeText(value,'email')}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {`${i18next.t('Please enter valid email.')}`}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="12" controlId="validationEmail">
                                            <Form.Control
                                                required
                                                type="email"
                                                placeholder="Email confirmation"
                                                onChange={(value)=>changeText(value,'email-confirmation')}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            Please email confirmation.
                                            </Form.Control.Feedback>
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="12" controlId="validationMobile">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                            type="digit"
                                            placeholder={`${i18next.t('Mobile')}`}
                                            onChange={(value)=>changeText(value,'mobile')}
                                            required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {`${i18next.t('Please enter contact number.')}`}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                            type="password"
                                            placeholder={`${i18next.t('Password')}`}
                                            onChange={(value)=>changeText(value,'password')}
                                            required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {`${i18next.t('Please enter password.')}`}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                        <InputGroup hasValidation>
                                            <Form.Control
                                            type="password"
                                            placeholder={`${i18next.t('Repeat Password')}`}
                                            onChange={(value)=>changeText(value,'psw_repeat')}
                                            required
                                            
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            {`${i18next.t('Please enter repeate password.')}`}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                        </Form.Group>
                                       
                                        
                                    </Form.Row>
                                    <Form.Group className="mb-3" id="formGridCheckbox">
                                        <Form.Check     required type="checkbox" label={`${i18next.t('By creating an account you agree to our Terms & Privacy')}`} />
                                    </Form.Group>
                                    <Button className="btn w-100" type="submit">{`${i18next.t('Register')}`}</Button>
                                    {/* <div className="link">
                                        <p>Already have an account? <Link to="/login">Login</Link></p>
                                    </div> */}
                                    {/* <div className="or">
                                        <p><span>OR Login with</span></p>
                                        <hr className="hr-line" />
                                    </div>
                                    <div className="social-login">
                                        <Link><i class="fa fa-facebook-f"></i></Link>
                                        <Link><i class="fa fa-google"></i></Link>
                                    </div> */}
                                    <div className="link">
                                        <p>{`${i18next.t('Already have an account?')}`}<Link to="/login">{`${i18next.t('Login')}`}</Link></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const mapStateToProps = (state) => {
        const {errorReducer:{message,visibleMessage,status} } = state;
        return { message,visibleMessage,status }
    }
    const mapDispatchToProps = dispatch => {
        return {
            signup: (data) => dispatch(globalAction(SIGN_UP, data)),
            hideMessage: ()=>dispatch(globalAction(HIDE_MESSAGE)),
            showToast: (data)=>dispatch(globalAction(SHOW_TOAST ,data))
        }
    }
    export default connect(mapStateToProps, mapDispatchToProps)(Register)