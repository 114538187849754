import React, { useState, useEffect } from 'react'
import './CustomModal.css'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_BULK_ENQUIRY, globalAction, SHOW_TOAST } from "../../redux";
import i18next from '../Translation';

function CustomModal(props) {

const dispatch = useDispatch();
const {  isLogin,user } = useSelector( (state) => state.authReducer);
const { currentLang } = useSelector((state) => state.AppReducer);
const { modalToggle, modalFlag, products_detail } = props;
const [state, setState] = useState({
    email: '',
    mobile: '',
    product_id: products_detail.id,
    qty: '',
    message: '',
    name: '',
    product:products_detail
});


const [validated, setValidated] = useState(false);
const changeText = (value, text) => {
    let inputData = state;
    inputData[text] = value.target.value;
    setState(inputData);
};


const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        event.preventDefault();
        console.log(state)
        dispatch(globalAction(SET_BULK_ENQUIRY, state));
        modalToggle()
        setState({
            
            email: '',
            mobile: '',
            product_id: products_detail.id,
            qty: '',
            message: '',
            name: '',
        })
    }
    setValidated(true);
};

useEffect(() => {
    setState({ ...state, product_id: products_detail.id })
    if (isLogin && user!==null) {
        setState({ ...state, user_id: user.id,product_id: products_detail.id,email:user.email, mobile:user.mobile  })
    }
}, [products_detail.id, isLogin,user])

console.log('state',state)
return (
    <div  >
        <Modal
            show={modalFlag}
            onHide={() => {modalToggle();setState({ ...state, user_id: '',product_id: '',email:'', mobile:''  })}}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="cm-modal-wrap"

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {`${i18next.t('Want to buy even more quantity ?')}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <Form.Group as={Row} controlId="productId">
                        <Form.Label column sm="2">
                        {`${i18next.t('Product ID')}`} :-
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control size="sm" plaintext readOnly defaultValue={products_detail.id} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="productName">
                        <Form.Label column sm="2">
                        {`${i18next.t('Product Name')}`} :-
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control size="sm" plaintext readOnly defaultValue={currentLang === 'en' ? products_detail.title : products_detail.title_french || products_detail.title} />
                        </Col>
                    </Form.Group>


                    <Form.Row>
                        <Form.Group as={Col} column="sm" controlId="formFullName">
                            <Form.Label  >{`${i18next.t('Full Name')}`}</Form.Label>
                            <Form.Control size="sm" type="text" placeholder={`${i18next.t('Enter Full Name')}`} required onChange={(value) => changeText(value, "name")} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label >{`${i18next.t('Email')}`}</Form.Label>
                            <Form.Control size="sm" type="email" defaultValue={state.email} placeholder={`${i18next.t('Enter email')}`} required onChange={(value) => changeText(value, "email")} />
                        </Form.Group>
                    </Form.Row>


                    <Form.Row>
                        <Form.Group as={Col} controlId="formMobile">
                            <Form.Label >{`${i18next.t('Mobile')}`}</Form.Label>
                            <Form.Control size="sm" type="number" defaultValue={state.mobile} placeholder={`${i18next.t('Enter Mobile Number')}`} required onChange={(value) => changeText(value, "mobile")} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridQuantity">
                            <Form.Label >{`${i18next.t('Quantity')}`}</Form.Label>
                            <Form.Control size="sm" type="text" placeholder={`${i18next.t('How many quanity you wanna buy, Enter here.')}`} required aria-describedby="quantityHelperText" onChange={(value) => changeText(value, "qty")} />

                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="message">
                        <Form.Label  >{`${i18next.t('Message')}`}</Form.Label>
                        <Form.Control size="sm" placeholder={`${i18next.t('Message')}`} as="textarea" aria-label="With textarea" required onChange={(value) => changeText(value, "message")} />
                    </Form.Group>



                    <Button variant="primary" type="submit" className="button" >
                    {`${i18next.t('Send')}`} 
                    </Button>
                </Form>
            </Modal.Body>

        </Modal>
    </div>
)
}

export default CustomModal
