import React, { useEffect, useState } from "react";
import "./contactUs.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FiPhone, FiMail } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { RiShareCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ImFacebook, ImTwitter } from "react-icons/im";
import { FaInstagram, FaPinterest } from "react-icons/fa";
import { contactApi } from "../../../api";
import { useDispatch, connect } from "react-redux";
import { globalAction, SHOW_TOAST, GET_HOME_DATA } from "../../../redux";
import BestBrands from "../../layouts/slider/BestBrands";
import ValuedCusmomer from "../../layouts/slider/ValuedCustomers";
import i18next from "../../Translation";
import Home from "../Home";

function ContactUs({ home, getHomeData }) {
  const dispatch = useDispatch();

  const initialState = {
    email: "",
    name: "",
    subject: "",
    message: "",
  };
  const [contactData, setContactData] = useState(initialState);

  const submitContact = (e) => {
    e.preventDefault();
    const updatedContactData = { ...contactData, user_id: 3 };
    console.log(updatedContactData);
    contactApi(updatedContactData).then((res) => {
      const { data, status } = res;
      if (status === 200) {
        dispatch(globalAction(SHOW_TOAST, { data }));
        setContactData(initialState);
      }
    });
  };

  const onChangeText = (field, value) => {
    setContactData({ ...contactData, [field]: value });
  };

  useEffect(() => {
    getHomeData();
  }, [getHomeData]);

  const {
    slider_images,
    best_selling_product,
    best_deal_product,
    new_product,
    most_popular_product,
    brands,
    home_category_1,
    home_category_2,
    home_category_3,
    our_valued_customers,
  } = home;

  return (
    <section className="contact">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  {`${i18next.t("Home")}`}
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/contact-us">{`${i18next.t("Contact us")}`}</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="contact_inner spacing">
        <Container>
          <Row>
            <Col xs={12} className="col">
              <Row>
                <Col md={12} className="slider_title contact_title">
                  <h5>{`${i18next.t("Contact With US!")}`}</h5>
                </Col>

                <Col lg={6} className="contact_content">
                  <ul>
                    <li>
                      <span>
                        <FiPhone />
                        {`${i18next.t("Call US:")}`}
                      </span>
                      <span>+91-11-45158809</span>
                    </li>
                    <li>
                      <span>
                        <FiMail />
                        {`${i18next.t("Email Address")}`}
                      </span>
                      <span>care@bellstoneonline.com</span>
                    </li>
                    <li>
                      <span>
                        <IoLocationOutline />
                        {`${i18next.t("Store Location:")}`}
                      </span>
                      <span>3755, Chawri Bazar, Delhi-110006(India)</span>
                    </li>
                    <li>
                      <span>
                        <RiShareCircleFill />
                        {`${i18next.t("Follow US:")}`}
                      </span>
                      <p>
                        <Link
                          to="/"
                          className="facebook"
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/BellstoneOnline"
                            )
                          }
                        >
                          <ImFacebook />
                        </Link>
                        <Link
                          to="/"
                          className="twitter"
                          onClick={() =>
                            window.open("https://twitter.com/Bellstoneonline")
                          }
                        >
                          <ImTwitter />
                        </Link>
                        <Link to="/" className="instagram">
                          <FaInstagram />
                        </Link>
                        <Link
                          to="/"
                          className="pint"
                          onClick={() =>
                            window.open(
                              "https://in.pinterest.com/bellstoneonline/"
                            )
                          }
                        >
                          <FaPinterest />
                        </Link>
                      </p>
                    </li>
                  </ul>
                </Col>
                <Col lg={6}>
                  <div className="contact_form">
                    <h4>{`${i18next.t("Get In Touch")}`}</h4>
                    <p>
                      {`${i18next.t(
                        "You email address will not be published. Required fields are marked"
                      )}`}
                      (*)
                    </p>
                    <Form onSubmit={submitContact}>
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>{`${i18next.t("Name")}`}</Form.Label>
                            <Form.Control
                              value={contactData.name}
                              type="text"
                              placeholder={`${i18next.t("Name")}`}
                              required
                              onChange={(e) =>
                                onChangeText("name", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>{`${i18next.t("Last Name")}`}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${i18next.t("Last Name")}`}
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>{`${i18next.t("Subject")}`}</Form.Label>
                            <Form.Control
                              type="text"
                              value={contactData.subject}
                              placeholder={`${i18next.t("Subject")}`}
                              required
                              onChange={(e) =>
                                onChangeText("subject", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>{`${i18next.t(
                              "Email address"
                            )}`}</Form.Label>
                            <Form.Control
                              type="email"
                              value={contactData.email}
                              placeholder={`${i18next.t(
                                "Enter Email address"
                              )}`}
                              required
                              onChange={(e) =>
                                onChangeText("email", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>{`${i18next.t("Message")}`}</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={contactData.message}
                              rows={3}
                              onChange={(e) =>
                                onChangeText("message", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="form_button">
                        <Button variant="primary" type="submit">
                          {`${i18next.t("Submit")}`}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="getin_touch"></Col>
          </Row>
        </Container>
      </div>
      <BestBrands brands={brands && brands} />
      <ValuedCusmomer
        our_valued_customers={our_valued_customers && our_valued_customers}
      />
    </section>
  );
}

const mapStateToProps = (state) => {
  const {
    AppReducer: { home },
  } = state;
  return { home };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHomeData: () => {
      dispatch(globalAction(GET_HOME_DATA));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
