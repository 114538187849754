// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyANtMDM5OpXyXIh-XH3-Uc8CROBKrLS8Jo",
  authDomain: "starapple-f4dfe.firebaseapp.com",
  projectId: "starapple-f4dfe",
  storageBucket: "starapple-f4dfe.appspot.com",
  messagingSenderId: "146897199409",
  appId: "1:146897199409:web:9fb0c317f0382d398b9010",
  measurementId: "G-QESPEYGFS8",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });

const facebookProvider = new FacebookAuthProvider();

// Get Auth instance
const auth = getAuth(firebaseApp);

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
export const signInWithFacebookPopup = () =>
  signInWithPopup(auth, facebookProvider);

export { auth };
