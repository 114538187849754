import React, { useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getRefundPolicyApi } from "../../../api";
import i18next from "../../Translation";
import parser from 'react-html-parser'

function ReturnPolicy() {
  const [state, setState] = useState({
    description:"",
    title:""
  })
  useEffect(() => {
    document.title = "Returns & Refunds";
    getRefundPolicyApi().then(res => {
      const { status, data } = res;
      if (status === 200) {
        console.log(data.return_and_refund)
        setState(data.return_and_refund)
      }
    })
  }, []);

  return (
    <section className="return_policy">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                {`${i18next.t('Home')}`}
                  <span>/</span>
                </Link>
              </li>
              <li>
              {`${i18next.t('Returns & Refunds')}`}
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>{`${i18next.t('RETURN POLICY')}`}</h5>
              </div>
              <div className="common_pages_content">
                {/* <div className="date">
                  <span>Last updated on</span> <span>29-March-2017</span>
                </div> */}
                <div className="common_pages_inner">
                {parser(state.description)}
                  {/* <h6>RETURNS</h6>
                  <p>Return is made available within 48 hours from the date of delivery of goods and the amount of the goods to be paid to customers through bank as per company policy within 2 to 7 working days from the date of pick up the goods.</p>

                  <p>Returned Product Will Be Accept Only in India.</p>

                  <h6>REPLACEMENT</h6>
                  <p>If the customer received an item in a damaged / defective condition on that event the said item will be replaced. No extra cost will be charged on replacement.</p>

                  <h6></h6>
                  <p></p>

                  <h6></h6>
                  <p></p> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ReturnPolicy;
