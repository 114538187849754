import React from "react";
import "./frequently-bought-together.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image1 from "../../../assets/product/solar2.jpg";
import { BiRupee } from "react-icons/bi";

function FrequentlyBoughtTogether() {
  //   title,
  //   image1,
  //   image2,
  //   image3,
  //   imageAlit1,
  //   imageAlit2,
  //   imageAlit3,
  //   productTitle1,
  //   productTitle2,
  //   productTitle3,
  //   oldPrice1,
  //   oldPrice2,
  //   oldPrice3,
  //   offerPrice1,
  //   offerPrice2,
  //   offerPrice3
  return (
    <section className="frequently_buy">
      <Container>
        <Row>
          <Col lg={9}>
            <div className="slider_title col">
              <h3> FrequentlyBoughtTogether</h3>
            </div>
            <div className="frequently_buy_card_wrap">
              <div className="frequently_buy_card">
                <Link to="/">
                  <img src={Image1} alt="image_alt" />
                  <span>Deven Study Table</span>
                </Link>

                <div className="frequently_buy_card_price">
                  <del>
                    <BiRupee />
                    100.00$
                  </del>
                  <span>
                    <BiRupee />
                    70.00$
                  </span>
                </div>
              </div>
              +
              <div className="frequently_buy_card">
                <Link to="/">
                  <img src={Image1} alt="image_alt" />
                  <span>Naurished Neighborhood</span>
                </Link>

                <div className="frequently_buy_card_price">
                  <del>
                    <BiRupee />
                    300.00
                  </del>
                  <span>
                    <BiRupee />
                    249.00
                  </span>
                </div>
              </div>
              +
              <div className="frequently_buy_card">
                <Link to="/">
                  <img src={Image1} alt="image_alt" />
                  <span>Laiki Rhodes Town</span>
                </Link>

                <div className="frequently_buy_card_price">
                  <del>
                    <BiRupee />
                    599.00
                  </del>
                  <span>
                    <BiRupee />
                    269.00
                  </span>
                </div>
              </div>
              +
              <div className="frequently_buy_card">
                <Link to="/">
                  <img src={Image1} alt="image_alt" />
                  <span>Fresh Grow Gold</span>
                </Link>

                <div className="frequently_buy_card_price">
                  <del>
                    <BiRupee />
                    699.00
                  </del>
                  <span>
                    <BiRupee />
                    369.00
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} className="aad_to_card">
            <div className="total_price">
              <span>Price For All</span>
              <span className="Amount">
                <BiRupee />
                780.5
              </span>
            </div>
            <div class="form_button">
              <button type="submit" class="cart_button">
                Add all to cart
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="label_check">
            <Form>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check inline label="Devans Study Table" name="group1" type={type} id={`inline-${type}-1`} />
                  <Form.Check inline label="Naurished Neighborhood" name="group1" type={type} id={`inline-${type}-1`} />
                  <Form.Check inline label="Laiki Rhodes Town" name="group1" type={type} id={`inline-${type}-1`} />
                  <Form.Check inline label="Fresh Grow Gold" name="group1" type={type} id={`inline-${type}-1`} />
                </div>
              ))}
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FrequentlyBoughtTogether;
