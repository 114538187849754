import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./bulkOrder.scss";
import i18next from "../../Translation";

function BulkOrder() {
  useEffect(() => {
    document.title = "Bulk Order";
  }, []);

  return (
    <section className="bulk_order">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                {`${i18next.t('Home')}`}
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/bulk-order">{`${i18next.t('Bulk Order')}`}</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>{`${i18next.t('Bulk Order')}`} !</h5>
              </div>
              <div className="common_pages_content">
                <div className="common_pages_inner">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{`${i18next.t('First Name')}`}</Form.Label>
                        <Form.Control type="text" placeholder={`${i18next.t('First Name')}`} />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{`${i18next.t('Last Name')}`}</Form.Label>
                        <Form.Control type="text" placeholder={`${i18next.t('Last Name')}`} />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{`${i18next.t('Email')}`}</Form.Label>
                        <Form.Control type="email" placeholder={`Enter ${i18next.t('Email')}`}/>
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{`${i18next.t('Contact Number')}`}</Form.Label>
                        <Form.Control type="number" placeholder={`${i18next.t('Contact Number')}`} />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress1">
                      <Form.Label>{`${i18next.t('Address')}`}</Form.Label>
                      <Form.Control placeholder="1234 Main St" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridAddress2">
                      <Form.Label>{`${i18next.t('Address')}`} 2</Form.Label>
                      <Form.Control placeholder="Apartment, studio, or floor" />
                    </Form.Group>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>{`${i18next.t('City')}`}</Form.Label>
                        <Form.Control />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>{`${i18next.t('State')}`}</Form.Label>

                        <select className="form-select" id="cars" name="cars">
                          <option value="0">Choose...</option>
                          <option value="1">Delhi</option>
                          <option value="2">Bihar</option>
                          <option value="3">Uttar Pradesh</option>
                        </select>
                        {/* <Form.Control /> */}
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>{`${i18next.t('Zip')}`}</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress2">
                      <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: "120" }} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                    {`${i18next.t('Submit')}`}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default BulkOrder;
