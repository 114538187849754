import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch, withRouter } from "react-router-dom";
import Header from "../components/layouts/header/Header";
import { connect } from "react-redux";
import Footer from "../components/layouts/footer/Footer";
import Home from "../components/components";
import Register from "../components/pages/register/register";
import Login from "../components/pages/login/login";
import ContactUs from "../components/pages/contactus/ContactUs";
import CategoryDetail from "../components/pages/category-detail/category-detail";
import Dashboard from "../components/userDashboard/userDashboard";
import productDetail from "../components/pages/product-detail/product-detail";
import NotFound from "../components/notFound/notFound";
import PrivacyPolicy from "../components/pages/privacy-policy/PrivacyPolicy";
import TermsCondition from "../components/pages/terms-condition/TermsCondition";
import DeliveryInfo from "../components/pages/delivery-Info/DeliveryInfo";
import ReturnPolicy from "../components/pages/returns-refunds/ReturnsRefunds";
import PaymentMathods from "../components/pages/payment-methods/PaymentMethods";
import OfflineBusiness from "../components/pages/offline-bussiness/OfflineBusiness";
import BulkOrder from "../components/pages/bulk-order/BulkOrder";
import SellOnBellstone from "../components/pages/sell-on-bellstone/SellOnBellstone";
import AboutUs from "../components/pages/aboutUs/AboutUs";
import Cart from "../components/pages/cart/Cart";
import ShippingAddress from "../components/pages/shipping-address/ShippingAddress";
import ChooseAddress from "../components/pages/Choose-Address/ChooseAddress";
import UpdateAddress from "../components/pages/UpdateAddress/UpdateAddress";
import OrderSummary from "../components/pages/OrderSummary/OrderSummary";
import AddNewAddress from "../components/pages/NewAddress/AddNewAddress";
import AddToCompare from "../components/pages/AddToCompare/AddToCompare";
import OTPVerify from "../components/pages/otp-verify/OTPVerify";
import Blog from '../components/pages/blog/blog';
import BlogDetail from '../components/pages/blog_detail/blog_detail';
import SuccessMessage from "../components/pages/sucessMessage/SuccessMessage";
import ProductFeedback from "../components/pages/ProductFeedback/ProductFeedback";
import Whatappswidget from "../components/Whatapps-widget";
import ForgetPassword from "../components/pages/forgetPassword/ForgetPassword";
import GoogleAnalytical from "./GoogleAnalytical";
import ConversationForBulkEnquiry from "../components/userDashboard/Bluk-Enquiry/ConversationForBulkEnquiry";


const PublicRoute = ({ component: Component, isLogin, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin && props.location.pathname === "/login" ? (
          <Redirect to="/" />
        ) : (
          <>
            {props.location.pathname !== "/login" && localStorage.setItem('url', props.location.pathname)}
            <Component {...props} />
          </>
        )
      }
    />
  );
};

const PrivateRoute = ({ component: Component, isLogin, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest}
      render={(props) =>
      (isLogin ?
        <Component {...props} />
        :
        <Redirect to="/login" />)} />
  );
};

class router extends Component {



  render() {
    console.log('res render')
    const { isLogin } = this.props;
    return (
      <BrowserRouter>
        <Header />
        {/* {<GoogleAnalytical/>} */}
        {/* <ScrollToTop /> */}
        <Switch>
          <PublicRoute restricted={false} component={Home} path="/" exact />
          <PublicRoute restricted={false} component={Login} path="/login" exact />
          <PublicRoute restricted={false} component={Register} path="/register" exact />
          <PublicRoute restricted={false} component={ContactUs} path="/contact-us" exact />
          {/* <PublicRoute restricted={false} component={productDetail} path="/:id" /> */}



          <PrivateRoute isLogin={isLogin} component={ChooseAddress} path="/address" exact />
          <PrivateRoute isLogin={isLogin} component={UpdateAddress} path="/update-address" exact />
          <PrivateRoute isLogin={isLogin} component={UpdateAddress} path="/update-address" exact />
          <PublicRoute isLogin={isLogin} component={OrderSummary} path="/order-summary" exact />
          <PublicRoute isLogin={isLogin} component={AddNewAddress} path="/add-new-address" exact />
          <PublicRoute restricted={false} component={OTPVerify} path="/verify-account" exact />
          <PublicRoute restricted={false} component={SuccessMessage} path="/order-success" exact />
          <PublicRoute restricted={false} component={SuccessMessage} path="/order-success" exact />
          <PublicRoute restricted={false} component={ForgetPassword} path="/forget-password" exact />
          <PublicRoute restricted={false} component={ProductFeedback} path="/product-feedback" exact />
          {/* <PublicRoute restricted={false} component={AddTocart} path="/addtocart" /> */}



          {/* /////////////  Footer pages Links ////////////// */}
          <PublicRoute restricted={false} component={PrivacyPolicy} path="/Privacy-policy" exact />
          <PublicRoute restricted={false} component={TermsCondition} path="/terms-condition" exact />
          <PublicRoute restricted={false} component={DeliveryInfo} path="/delivery-info" exact />
          <PublicRoute restricted={false} component={ReturnPolicy} path="/return" exact />
          <PublicRoute restricted={false} component={PaymentMathods} path="/payment-methods" exact />
          <PublicRoute restricted={false} component={OfflineBusiness} path="/offline-business" exact />
          <PublicRoute restricted={false} component={BulkOrder} path="/bulk-order" exact />
          <PublicRoute restricted={false} component={SellOnBellstone} path="/sell-on-bellstone" exact />
          <PublicRoute restricted={false} component={AboutUs} path="/About-Us" exact />
          <PublicRoute restricted={false} component={Cart} path="/cart" exact />
          {/* <PublicRoute restricted={false} component={Cart} path="/cart" exact /> */}
          <PublicRoute restricted={false} component={ShippingAddress} path="/shipping-address" exact />
          <PublicRoute restricted={false} component={AddToCompare} path="/compare-product" exact />
          <PublicRoute restricted={false} component={Blog} path="/blog/category/:id" exact />
          <PublicRoute restricted={false} component={Blog} path="/blog" exact />
          <PublicRoute restricted={false} component={BlogDetail} path="/blog-detail/:id" exact />


          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/my-account" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/saved-products" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/my-order" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/Address" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/Update-address" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/new-address" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/bulk-enquiry" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/order-detail/:id" exact />
          <PrivateRoute isLogin={isLogin} component={Dashboard} path="/dashboard/conversation/:id" exact />
 
          <PublicRoute restricted={false} component={CategoryDetail} path="/view-all" exact />
          <PublicRoute restricted={false} component={CategoryDetail} path="/results" exact />
          <PublicRoute restricted={false} component={CategoryDetail} path="/category-detail" exact />
          <PublicRoute restricted={false} component={CategoryDetail} path="/:cat_id" exact />
          <PublicRoute restricted={false} component={CategoryDetail} path="/:cat_id/:sub_catId" exact />
          <PublicRoute restricted={false} component={productDetail} path="/:cat_id/:sub_catId/:id" exact />
          <Route component={NotFound} />
        </Switch>
        <Footer />
        <Whatappswidget />
      </BrowserRouter>
    );
  }
}

const mapStateFromProps = (state) => {
  return {
    isLogin: localStorage.getItem("bellStone_idToken") ? true : false,
  };
};

export default withRouter(connect(mapStateFromProps)(router));
