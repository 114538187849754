import React, { useEffect } from "react";
import "./slider.scss";
import { Container, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { BiRupee } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  globalAction,
  SHOW_TOAST,
  ADD_TO_CART,
  SAVE_PRODUCT,
  ADD_TO_CART_INTO_DB,
} from "../../../redux";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { MdCompareArrows } from "react-icons/md";
import i18next from "../../Translation";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function RelatedProducts({
  related_products,
  getProductDetail,
  handleAddToCompare,
  cat_slug,
  sub_catSlug,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cart } = useSelector((state) => state.AppReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { currentLang } = useSelector((state) => state.AppReducer);
  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const addToCart = (item) => {
    let flag = true;

    if (item) {
      // Check if the item is already in the cart
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} ${i18next.t("is already in your cart.")}`,
            status: "warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });

      // If the item is not in the cart, add it
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} ${i18next.t(
            "has been successfully added to your cart."
          )}`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));

        if (user) {
          dispatch(globalAction(ADD_TO_CART_INTO_DB, { userId: user.id }));
        } else {
          const data = {
            msg: `${i18next.t("Please login to save your cart.")}`,
            status: "warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          history.push("/login");
        }
      }
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  return (
    <section className="related_products spacing">
      <Container>
        <Row>
          <div className="slider_title">
            <h3>{`${i18next.t("Related Products")}`}</h3>
          </div>
        </Row>
        <Row>
          {related_products && related_products.length > 0 && (
            <OwlCarousel
              className="owl-theme"
              items={4}
              autoplay={false}
              smartSpeed={300}
              dots={true}
              nav={true}
              slideBy={1}
              margin={10}
              responsive={{
                0: {
                  items: 1,
                  nav: false,
                },
                576: {
                  items: 2,
                  nav: false,
                },
                768: {
                  items: 3,
                  nav: false,
                },
                1025: {
                  items: 4,
                  nav: false,
                },
                1100: {
                  nav: true,
                },
              }}
            >
              {related_products.map((item) => {
                const {
                  slug,
                  offers,
                  id,
                  image_url,
                  title,
                  title_french,
                  regular_price,
                  sale_price,
                  brand_name,
                  stock_qty,
                  category_id,
                  subcategory_id,
                } = item;

                return (
                  <div className="item" key={id}>
                    <div className="related_products_card">
                      <Link onClick={() => getProductDetail(id)}>
                        <img src={image_url} alt="solar" />
                        {offers !== null && <span>-37%</span>}
                      </Link>
                      <div className="related_products_card_body">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 0, hide: 400 }}
                          overlay={
                            <Tooltip
                              id={`tooltip-top`}
                              style={{
                                "font-size": "12px",
                                "text-transform": "capitalize",
                              }}
                            >
                              {currentLang === "en"
                                ? title
                                : title_french || title}
                            </Tooltip>
                          }
                        >
                          <Link
                            to={`/${cat_slug}/${sub_catSlug}/${slug}`}
                            onClick={() => getProductDetail(id)}
                          >
                            <h5>
                              {currentLang === "en"
                                ? title.slice(0, 25)
                                : (title_french && title_french.slice(0, 25)) ||
                                  title.slice(0, 25)}
                              ...
                            </h5>
                          </Link>
                        </OverlayTrigger>
                        <div className="review">
                          <ReactStars
                            count={5}
                            //   onChange={this.ratingChanged}
                            size={20}
                            isHalf={true}
                            edit={false}
                            activeColor="#ffd731"
                          />
                          <span className="text">
                            1 {`${i18next.t("Review")}`}
                          </span>
                        </div>
                        <div className="price">
                          <del>
                            <BiRupee />
                            {regular_price}
                          </del>
                          <h6>
                            <BiRupee />
                            {sale_price}
                          </h6>
                        </div>
                        <p>
                          {`${i18next.t("Brand")}`}: <span>{brand_name}</span>
                        </p>
                        <div className="product_icon">
                          {/* <span className="wishlist">
                          <AiTwotoneHeart  onClick={()=>{saveProductByUser(id)}}/>
                        </span> */}
                          <span
                            className={`shop ${stock_qty <= 0 && "disabled"}`}
                            onClick={() => stock_qty > 0 && addToCart(item)}
                          >
                            <FaShoppingCart />
                          </span>
                          <span
                            className="compare"
                            onClick={() => {
                              handleAddToCompare(
                                id,
                                category_id,
                                subcategory_id,
                                title,
                                image_url
                              );
                            }}
                          >
                            <MdCompareArrows />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="item">
              <div className="related_products_card">
                <Link to="/">
                  <img src={Solar2} alt="solar" />
                  <span>-70%</span>
                </Link>
                <div className="related_products_card_body">
                  <Link to="/">
                    <h5>Product title</h5>
                  </Link>
                  <div className="review">
                    <ReactStars
                      count={5}
                      //   onChange={this.ratingChanged}
                      size={20}
                      isHalf={true}
                      edit={false}
                      activeColor="#ffd731"
                    />
                    <span className="text">1 Review</span>
                  </div>
                  <div className="price">
                    <del> $250</del>
                    <h6> $125</h6>
                  </div>
                  <p>
                    Brand: <span>Brand Name</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="related_products_card">
                <Link to="/">
                  <img src={Solar3} alt="solar" />
                  <span>-15%</span>
                </Link>
                <div className="related_products_card_body">
                  <Link to="/">
                    <h5>Product title</h5>
                  </Link>
                  <div className="review">
                    <ReactStars
                      count={5}
                      //   onChange={this.ratingChanged}
                      size={20}
                      isHalf={true}
                      edit={false}
                      activeColor="#ffd731"
                    />
                    <span className="text">1 Review</span>
                  </div>
                  <div className="price">
                    <del> $250</del>
                    <h6> $125</h6>
                  </div>
                  <p>
                    Brand: <span>Brand Name</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="related_products_card">
                <Link to="/">
                  <img src={Solar} alt="solar" />
                  <span>-80%</span>
                </Link>
                <div className="related_products_card_body">
                  <Link to="/">
                    <h5>Product title</h5>
                  </Link>
                  <div className="review">
                    <ReactStars
                      count={5}
                      //   onChange={this.ratingChanged}
                      size={20}
                      isHalf={true}
                      edit={false}
                      activeColor="#ffd731"
                    />
                    <span className="text">1 Review</span>
                  </div>
                  <div className="price">
                    <del> $250</del>
                    <h6> $125</h6>
                  </div>
                  <p>
                    Brand: <span>Brand Name</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="related_products_card">
                <Link to="/">
                  <img src={Solar} alt="solar" />
                  <span>-10%</span>
                </Link>
                <div className="related_products_card_body">
                  <Link to="/">
                    <h5>Product title</h5>
                  </Link>
                  <div className="review">
                    <ReactStars
                      count={5}
                      //   onChange={this.ratingChanged}
                      size={20}
                      isHalf={true}
                      edit={false}
                      activeColor="#ffd731"
                    />
                    <span className="text">1 Review</span>
                  </div>
                  <div className="price">
                    <del> $250</del>
                    <h6> $125</h6>
                  </div>
                  <p>
                    Brand: <span>Brand Name</span>
                  </p>
                </div>
              </div>
            </div>
           */}
            </OwlCarousel>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default RelatedProducts;
