// Initial State

import { SHOW_LOADING,CLOSE_LOADING,SHOW_MESSAGE,HIDE_MESSAGE, REMOVE_MESSAGE,  } from "../actionTypes";

const initialState = {
    status: null,
    message:"",
    visibleMessage:false,
    isLoading:false,
    error_code:null
  };
  // Redux: Counter Reducer
  const errorReducer = (state = initialState, action) => {
   const {type,payload}=action
   
    switch (type) {
      case SHOW_LOADING: {
        return {
          ...state,
          isLoading:true,

        };
      }
      case CLOSE_LOADING: {
        return {
          ...state,
          isLoading:false,

        };
      }
      case SHOW_MESSAGE: {
       let errorMsg=null
       console.log(payload)
       if(payload?.errors){
         errorMsg = Object.values(payload.errors);
       }
       console.log(payload)
        return {
          ...state,
          visibleMessage:true,
          //message: status==="success" || status==="warning" || status==="error" ?payload.msg?payload.msg:payload.error:payload.error ?payload.error:payload.errors?[errorMsg][0][0]:payload.message,
          message: payload?.msg || payload?.error  || payload?.message || errorMsg[0][0],
          status:payload.status
        };
      }
      case HIDE_MESSAGE: {
        return {
          ...state,
          visibleMessage:false,
          // message:"",
          // status:null
        };
      }
      case REMOVE_MESSAGE: {
        return {
          ...state,
         // visibleMessage:false,
          message:"",
          status:null
        };
      }
     
      
     
      default: {
        return state;
      }
    }
  };
  // Exports
  export default errorReducer;