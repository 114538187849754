import React, { useEffect, useState } from "react";
import "./slider.scss";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";
import OwlCarousel from "react-owl-carousel";
import { connect } from "react-redux";
import NoImage from "../../../assets/noImage.jpg";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaCheck, FaShoppingCart } from "react-icons/fa";
// import Productimg2 from "../image/product/41fvnfrtcbl.jpg";
// import Productimg3 from "../image/product/41sj2zbfcsl.jpg";
// import Productimg4 from "../image/product/470dbg.jpg";
// import Productimg5 from "../image/product/7822222.jpg";
// import Productimg6 from "../image/product/dn1_1.jpg";
// import Productimg7 from "../image/product/dn1_1.jpg";
// import Productimg8 from "../image/product/ladd18.jpg";
// import Productimg9 from "../image/product/pre6.jpg";
// import Productimg10 from "../image/product/ry.jpg";
// import Productimg11 from "../image/product/soil18.jpg";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../../Translation";
import {
  globalAction,
  SHOW_TOAST,
  ADD_TO_CART,
  SAVE_PRODUCT,
  ADD_TO_CART_INTO_DB,
} from "../../../redux";

function BestDealOn({ home, cart, user }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentLang } = useSelector((state) => state.AppReducer);
  const { best_deal_product, most_popular_product, new_product } = home;
  // State to track wishlisted items
  const [wishlistedItems, setWishlistedItems] = useState([]);

  // Function to save wishlist items to localStorage
  const saveWishlistToLocalStorage = (items) => {
    localStorage.setItem("wishlist", JSON.stringify(items));
  };

  // Function to load wishlist items from localStorage
  const loadWishlistFromLocalStorage = () => {
    const savedWishlist = localStorage.getItem("wishlist");
    return savedWishlist ? JSON.parse(savedWishlist) : [];
  };

  useEffect(() => {
    // Load wishlist items from localStorage when the component mounts
    const storedWishlist = loadWishlistFromLocalStorage();
    setWishlistedItems(storedWishlist);

    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  const saveProductByUser = (product_id) => {
    if (user) {
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
      setWishlistedItems((prevItems) => {
        const newWishlist = [...prevItems, product_id]; // Add to wishlist
        saveWishlistToLocalStorage(newWishlist);
        return newWishlist;
      });
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
      history.push("/login");
    }
  };

  const addToCart = (item) => {
    if (!item) return; // Return early if no item is provided

    // Check if the item is already in the cart
    const itemExists = cart.some((el) => el.id === item.id);

    if (itemExists) {
      const data = {
        msg: `${item.title} ${i18next.t(
          "are successfully added in your cart"
        )}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    } else {
      // If the user is not logged in, redirect to /login
      if (!user) {
        const data = {
          msg: `${i18next.t("Please login or Register to save this product.")}`,
          status: "warning",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        history.push("/login");
        return; // Stop execution
      }

      // Add new item to the cart
      item.quantity = 1;
      const updatedCart = [...cart, item];
      const data = {
        msg: `${item.title} ${i18next.t(
          "are successfully added in your cart"
        )}`,
        status: "success",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));

      // Update local storage and Redux state
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      dispatch(globalAction(ADD_TO_CART, updatedCart));

      // Add item to the database
      dispatch(
        globalAction(ADD_TO_CART_INTO_DB, {
          userId: user.id,
          cart: updatedCart,
        })
      );
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);
  const isProductInCart = (productId) => {
    return cart.some((cartItem) => cartItem.id === productId);
  };

  const fivePair = (input) => {
    var temp = input.slice();
    var arr = [];
    while (temp.length) {
      arr.push(temp.splice(0, 5));
    }

    return arr;
  };
  return (
    <section className="best_dealon_wrapp spacing">
      <Container>
        <Row>
          <Col md={6} lg={4}>
            <section className="best_dealon">
              <div className="slider_title">
                <h3>{`${i18next.t("Best Deals On")}`}</h3>
              </div>
              {best_deal_product.data.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  items={1}
                  autoplay={false}
                  smartSpeed={300}
                  dots={false}
                  nav={true}
                  slideBy={1}
                >
                  {fivePair(best_deal_product.data).map((bestDeal, index) => (
                    <div class="item" key={index + 1}>
                      {bestDeal.map((item) => {
                        const {
                          id,
                          image_url,
                          title,
                          title_french,
                          sale_price,
                          stock_qty,
                          slug,
                        } = item;
                        const isWishlisted = wishlistedItems.includes(id);
                        const isInCart = isProductInCart(id);
                        return (
                          <div className="start_card" key={id}>
                            <div className="start_card_img">
                              <Link
                                to={`/${item.category.slug}/${
                                  item.subcategory.slug
                                }/${item.slug !== null ? item.slug : id}`}
                              >
                                <img
                                  className={stock_qty <= 0 && "outofstock"}
                                  src={image_url ? image_url : NoImage}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="start_card_body">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    id={`tooltip-top`}
                                    style={{
                                      "font-size": "12px",
                                      "text-transform": "capitalize",
                                    }}
                                  >
                                    {currentLang === "en"
                                      ? title
                                      : title_french || title}
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={`/${item.category.slug}/${
                                    item.subcategory.slug
                                  }/${item.slug !== null ? item.slug : id}`}
                                >
                                  {currentLang === "en"
                                    ? title && title.slice(0, 30)
                                    : (title_french &&
                                        title_french.slice(0, 30)) ||
                                      title.slice(0, 30)}
                                  ...
                                </Link>
                              </OverlayTrigger>{" "}
                              <span>
                                <AiFillStar />
                                <AiFillStar />
                                <BsStarHalf />
                                <AiOutlineStar />
                              </span>
                              <span>
                                <BiRupee />
                                {sale_price}
                              </span>
                              <div className="product_icon">
                                <span className="wishlist">
                                  <AiTwotoneHeart
                                    onClick={() =>
                                      !isWishlisted && saveProductByUser(id)
                                    }
                                    className={`wishlist-icon ${
                                      isWishlisted ? "wishlisted" : ""
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`shop ${
                                    isInCart || stock_qty <= 0 ? "disabled" : ""
                                  }`}
                                  onClick={() =>
                                    stock_qty > 0 && addToCart(item)
                                  }
                                >
                                  {isInCart ? <FaCheck /> : <FaShoppingCart />}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </section>
          </Col>
          <Col md={6} lg={4}>
            <section className="best_dealon">
              <div className="slider_title">
                <h3>{`${i18next.t("New Products")}`}</h3>
              </div>
              {new_product.data.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  items={1}
                  autoplay={false}
                  smartSpeed={300}
                  dots={false}
                  nav={true}
                  slideBy={1}
                >
                  {fivePair(new_product.data).map((product, index) => (
                    <div class="item" key={"prod" + index + 1}>
                      {product.map((item) => {
                        const {
                          id,
                          image_url,
                          title,
                          title_french,
                          sale_price,
                          stock_qty,
                          slug,
                        } = item;
                        const isWishlisted = wishlistedItems.includes(id);
                        const isInCart = isProductInCart(id);
                        return (
                          <div className="start_card" key={id}>
                            <div className="start_card_img">
                              <Link
                                className={stock_qty <= 0 && "outofstock"}
                                to={`/${item.category.slug}/${
                                  item.subcategory.slug
                                }/${item.slug !== null ? item.slug : id}`}
                              >
                                <img
                                  src={image_url ? image_url : NoImage}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="start_card_body">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    id={`tooltip-top`}
                                    style={{
                                      "font-size": "12px",
                                      "text-transform": "capitalize",
                                    }}
                                  >
                                    {currentLang === "en"
                                      ? title
                                      : title_french || title}
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={`/${item.category.slug}/${
                                    item.subcategory.slug
                                  }/${item.slug !== null ? item.slug : id}`}
                                >
                                  {currentLang === "en"
                                    ? title && title.slice(0, 30)
                                    : (title_french &&
                                        title_french.slice(0, 30)) ||
                                      title.slice(0, 30)}
                                  ...
                                </Link>
                              </OverlayTrigger>{" "}
                              <span>
                                <AiFillStar />
                                <AiFillStar />
                                <BsStarHalf />
                                <AiOutlineStar />
                              </span>
                              <span>
                                <BiRupee />
                                {sale_price}
                              </span>
                              <div className="product_icon">
                                <span className="wishlist">
                                  <AiTwotoneHeart
                                    onClick={() =>
                                      !isWishlisted && saveProductByUser(id)
                                    }
                                    className={`wishlist-icon ${
                                      isWishlisted ? "wishlisted" : ""
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`shop ${
                                    isInCart || stock_qty <= 0 ? "disabled" : ""
                                  }`}
                                  onClick={() =>
                                    stock_qty > 0 && addToCart(item)
                                  }
                                >
                                  {isInCart ? <FaCheck /> : <FaShoppingCart />}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </section>
          </Col>
          <Col md={6} lg={4}>
            <section className="best_dealon">
              <div className="slider_title">
                <h3>{`${i18next.t("Most Popular Products")}`}</h3>
              </div>
              {most_popular_product.data.length > 0 && (
                <OwlCarousel
                  className="owl-theme"
                  items={1}
                  autoplay={false}
                  smartSpeed={300}
                  dots={false}
                  nav={true}
                  slideBy={1}
                >
                  {fivePair(most_popular_product.data).map((product, index) => (
                    <div class="item" key={"prod" + index + 1}>
                      {product.map((item) => {
                        const {
                          id,
                          image_url,
                          title,
                          title_french,
                          sale_price,
                          stock_qty,
                          slug,
                          category,
                          subcategory,
                        } = item;
                        const isWishlisted = wishlistedItems.includes(id);
                        const isInCart = isProductInCart(id);
                        return (
                          <div className="start_card" key={id}>
                            <div className="start_card_img">
                              <Link
                                className={stock_qty <= 0 && "outofstock"}
                                to={`/${item.category.slug}/${
                                  item.subcategory.slug
                                }/${item.slug !== null ? item.slug : id}`}
                              >
                                <img
                                  src={image_url ? image_url : NoImage}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="start_card_body">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    id={`tooltip-top`}
                                    style={{
                                      "font-size": "12px",
                                      "text-transform": "capitalize",
                                    }}
                                  >
                                    {currentLang === "en"
                                      ? title
                                      : title_french || title}
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={`/${item.category.slug}/${
                                    item.subcategory.slug
                                  }/${item.slug !== null ? item.slug : id}`}
                                >
                                  {currentLang === "en"
                                    ? title && title.slice(0, 30)
                                    : (title_french &&
                                        title_french.slice(0, 30)) ||
                                      title.slice(0, 30)}
                                  ...
                                </Link>
                              </OverlayTrigger>{" "}
                              <span>
                                <AiFillStar />
                                <AiFillStar />
                                <BsStarHalf />
                                <AiOutlineStar />
                              </span>
                              <span>
                                <BiRupee />
                                {sale_price}
                              </span>
                              <div className="product_icon">
                                <span className="wishlist">
                                  <AiTwotoneHeart
                                    onClick={() =>
                                      !isWishlisted && saveProductByUser(id)
                                    }
                                    className={`wishlist-icon ${
                                      isWishlisted ? "wishlisted" : ""
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`shop ${
                                    isInCart || stock_qty <= 0 ? "disabled" : ""
                                  }`}
                                  onClick={() =>
                                    stock_qty > 0 && addToCart(item)
                                  }
                                >
                                  {isInCart ? <FaCheck /> : <FaShoppingCart />}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </OwlCarousel>
              )}
            </section>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
const mapStateFromProps = (state) => {
  const {
    AppReducer: { home },
    AppReducer: { cart },
  } = state;
  const {
    authReducer: { user },
  } = state;
  return { home, cart, user };
};
export default connect(mapStateFromProps)(BestDealOn);
