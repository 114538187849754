import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    DELETE_ADDRESS,
    GET_USER_SAVED_ADDRESS,
    globalAction,
    HIDE_MESSAGE,
    REMOVE_MESSAGE,
    SHOW_LOADING,
    SHOW_MESSAGE,
} from "../../../redux";
import { Link, withRouter } from "react-router-dom";
import DeliveryImg from "../../../assets/Delivery.png";
import "./ChooseAddress.scss";
import { FaCheck, FaEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import AddressForm from "./AddressForn";
import i18next from "../../Translation";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";

function ChooseAddress({ history }) {
    const dispatch = useDispatch();
    const { user, savedAddresses } = useSelector((state) => state.authReducer);
    const { isLoading, } = useSelector(
        (state) => state.errorReducer
    );
    const [fromFlag, setfromFlag] = useState(false);
    const [isGSTBill, setIsGSTBill] = useState("no");
    const [gstInfo, setGstInfo] = useState({
        gst_number: "",
        company_name: "",
        company_address: "",
    });
    const [getAddressInfo, setGetAddressInfo] = useState({
        delivery_address: {},
        billing_address: {}
    });
    const [flag, setflag] = useState(true);
    const [defaultAddress, setdefaultAddress] = useState(false)
    const [GSTNumberIsValid, setGSTNumberIsValid] = useState(true)
    useEffect(() => {
        if (user !== null) {
            dispatch(globalAction(SHOW_LOADING));
            dispatch(globalAction(GET_USER_SAVED_ADDRESS, user.id));
        }
        if (savedAddresses.length > 0) {
            savedAddresses.map((item, index) => {
                if (item.default_address === "yes") {
                    getAddressInfo["delivery_address"] = item;
                    getAddressInfo["billing_address"] = item;
                }

            });
        }
    }, [dispatch]);
    useEffect(() => { }, [flag]);

    const handleAddressDelete = (e, item) => {
        const { id, user_id } = item;
        dispatch(globalAction(DELETE_ADDRESS, { id: id, user_id: user_id }));
        dispatch(globalAction(SHOW_LOADING));
    };

    const handleAddressEdit = (e, item) => {
        localStorage.setItem("UpdateAddress", JSON.stringify(item));
    };

    const Validation = () => {
        let isValid = true;
        if (isGSTBill === "yes") {
            for (const property in gstInfo) {
                if (String(gstInfo[property]).trim() === "") {
                    isValid = false;
                }
            }
        }
        
        return isValid;
    };

    const finalShippingAddress = (e, item) => {
        e.preventDefault()
        if (Validation()&&GSTNumberIsValid) {
            let finalOrderDetails = {
                "delivery_address": getAddressInfo.delivery_address,
                "billing_address": getAddressInfo.billing_address,
                "isGSTBill": isGSTBill,
                'gstInfo': gstInfo
            }
            localStorage.setItem("finalOrderDetails", JSON.stringify(finalOrderDetails));
            history.push('/order-summary')
        }
        else if (!GSTNumberIsValid){
            let data = {
                msg: `${i18next.t('Enter valid Gst number')}`,
                status: "waring",
            };
            dispatch(globalAction(SHOW_MESSAGE, data));
            setTimeout(() => {
                dispatch(globalAction(HIDE_MESSAGE));
                dispatch(globalAction(REMOVE_MESSAGE));
            }, 2000);
        }
        else {
            let data = {
                msg: `${i18next.t('Please fill up the all fields.')}`,
                status: "waring",
            };

            dispatch(globalAction(SHOW_MESSAGE, data));
            setTimeout(() => {
                dispatch(globalAction(HIDE_MESSAGE));
                dispatch(globalAction(REMOVE_MESSAGE));
            }, 2000);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "gst_billing") {
            if(value==='no'){
                setGSTNumberIsValid(true); 
            }
            setIsGSTBill(value);
        }
        if (name === "gst_number") {
            // setIsGSTBill(value);
            if (/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value)) {
                setGSTNumberIsValid(true);
            }
            else{
                setGSTNumberIsValid(false);
            }
           
        }
        let temp = gstInfo;
        temp[name] = value;
        setGstInfo(temp);
    };

    const selectAddressFor = (e, item) => {
        const { name, value } = e.target;
        let temp = getAddressInfo;
        temp[name] = item;
        setGetAddressInfo(temp);
        setflag(!flag);
    };

    return (
        <div className="choose-address-wrap">
            {isLoading && <AnimatedLogo />}
            <div className="container">
                <div className="ca-top-section">
                    <h1>
                        {savedAddresses && savedAddresses.length > 0
                            ? "   Select delivery address"
                            : "Fill Delivery Address"}
                    </h1>
                </div>
                <div className="ca-main-body">
                    <Link
                        to="/add-new-address"
                        className="ca-new-saved ca-saved"
                        onClick={() => {
                            window.scroll({ top: 0 });
                            setfromFlag(!fromFlag);
                        }}
                    >
                        <img src={DeliveryImg} alt="Add new address" />
                        <h5>{`${i18next.t('Add new Address')}`}</h5>
                    </Link>

                    <div className="ca-saved-addresses">
                        {savedAddresses &&
                            savedAddresses.length > 0 &&
                            savedAddresses.map((item, index) => {

                                return (
                                    <div key={index}
                                        className={`ca-saved-items ca-saved ${item.default_address}`}
                                    >
                                        <h5>{item.full_name}</h5>
                                        <p>{item.address_line_1}</p>
                                        <p>
                                            {item.country_data.name},{item.state_data.name} {item.zip}
                                        </p>
                                        <p>+{item.country_data.phonecode}-{item.mobile_no}</p>
                                        <div className="address-edit-remove">
                                            <Link
                                                to="/update-address"
                                                onClick={(e) => {
                                                    handleAddressEdit(e, item);
                                                }}
                                            >
                                                <span>
                                                    <FaEdit /> {`${i18next.t('Edit')}`}
                                                </span>
                                            </Link>
                                            <span
                                                onClick={(e) => {
                                                    handleAddressDelete(e, item);
                                                }}
                                            >
                                                <RiDeleteBin2Line /> {`${i18next.t('Remove')}`}
                                            </span>
                                        </div>
                                        {getAddressInfo.delivery_address !== undefined &&
                                            getAddressInfo.billing_address !== undefined ? (
                                            <div className="address-choice">
                                                {getAddressInfo.delivery_address.id !== item.id ? (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="delivery_address"
                                                            name="delivery_address"
                                                            value={item}
                                                            onChange={(e) => {
                                                                selectAddressFor(e, item);
                                                            }}
                                                        />
                                                        <label htmlFor="delivery_address">
                                                        {`${i18next.t('Delivery Address')}`}
                                                        </label>
                                                    </div>
                                                ) : (
                                                    <p><FaCheck /> {`${i18next.t('Selected for delivery')}`}</p>
                                                )}
                                                {getAddressInfo.billing_address.id !== item.id ? (
                                                    <div>
                                                        <input
                                                            type="checkbox"
                                                            id="billing_address"
                                                            name="billing_address"
                                                            value={item}
                                                            onChange={(e) => {
                                                                selectAddressFor(e, item);
                                                            }}
                                                        />
                                                        <label htmlFor="billing_address">{`${i18next.t('Billing Address')}`}</label>
                                                    </div>
                                                ) : (
                                                    <p><FaCheck /> {`${i18next.t('Selected for Billing')}`}</p>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        {/* <div className="delivery-btn wrap">
                                            <Link onClick={(e) => { finalShippingAddress(e, item) }} className="button" to="/order-summary">Deliver to this address</Link>
                                        </div> */}
                                    </div>
                                );
                            })}
                    </div>
                    <div className="gst-form-wrap">
                        <p>GST Billing ?</p>
                        <div>
                            <input
                                type="radio"
                                id="gstYes"
                                name="gst_billing"
                                value="yes"
                                defaultChecked={isGSTBill === "yes"}
                                onChange={(e) => handleChange(e)}
                            />
                            <label htmlFor="gstYes">Yes</label>
                            <input
                                type="radio"
                                id="gstNo"
                                name="gst_billing"
                                value="no"
                                defaultChecked={isGSTBill === "no"}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                            <label htmlFor="gstno">No</label>
                        </div>
                        <div className={`gst-form-main-wrap ${isGSTBill}`}>
                            <div className="gst-form-inner">
                                <div className="input">
                                    <label htmlFor="gst_number">GST number</label>
                                    <input
                                    className={!GSTNumberIsValid?'invalid-input':''}
                                        type="text"
                                        id="gst_nummber"
                                        name="gst_number"
                                        defaultValue={gstInfo.gst_number}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                    />
                                </div>
                                <div className="input">
                                    <label htmlFor="company_name">{`${i18next.t('Company name')}`}</label>
                                    <input
                                        type="text"
                                        id="company_name"
                                        name="company_name"
                                        defaultValue={gstInfo.company_name}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                    />
                                </div>
                                <div className="input">
                                    <label htmlFor="company_address">{`${i18next.t('Company Address')}`}</label>
                                    <input
                                        type="text"
                                        id="company_address"
                                        name="company_address"
                                        defaultValue={gstInfo.company_address}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>


                        {getAddressInfo.delivery_address !== undefined &&
                            getAddressInfo.billing_address !== undefined &&
                            (getAddressInfo.delivery_address.id !== undefined && getAddressInfo.billing_address.id !== undefined) ? (
                            <div className="delivery-btn wrap">
                                <Link
                                    onClick={(e) => {
                                        finalShippingAddress(e);
                                    }}
                                    className="button"
                                    to="/order-summary"
                                >
                                    {`${i18next.t('Continue')}`}
                                </Link>
                            </div>

                        )
                            : ""
                        }





                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(ChooseAddress);
