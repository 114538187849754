import React, { useEffect } from "react";
import "./slider.scss";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../../Translation";
import {
  globalAction,
  SHOW_TOAST,
  ADD_TO_CART,
  SAVE_PRODUCT,
  ADD_TO_CART_INTO_DB,
} from "../../../redux";

function MeasuringInstrument({ measuringData }) {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.AppReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { currentLang } = useSelector((state) => state.AppReducer);

  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const addToCart = (item) => {
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} ${i18next.t(
              "are successfully added in your cart"
            )}`,
            status: "waring",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} ${i18next.t(
            "are successfully added in your cart"
          )}`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
        if (user) {
          dispatch(globalAction(ADD_TO_CART_INTO_DB, { userId: user.id }));
        }
      }
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  return (
    <section className="measuring_instrument product_cart spacing">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>
              {currentLang === "en"
                ? measuringData && measuringData.title_3
                : measuringData.title_3_french || measuringData.title_3}
            </h3>
          </Col>
          {measuringData.product3 && measuringData.product3.length > 0 && (
            <div className="product_wrapper col">
              {measuringData &&
                measuringData.product3.map((item) => {
                  const {
                    id,
                    title,
                    sale_price,
                    regular_price,
                    image_url,
                    stock_qty,
                    slug,
                  } = item;
                  return (
                    <div class="item " key={id + ""}>
                      <div className="product cm-product">
                        <Link
                          to={`/${item.category.slug}/${
                            item.subcategory.slug
                          }/${item.slug !== null ? item.slug : id}`}
                          className={stock_qty <= 0 && "outofstock"}
                        >
                          <img src={image_url} alt="img" />
                        </Link>
                        <div className="mobile_img">
                          <Link
                            to={`/${item.category.slug}/${
                              item.subcategory.slug
                            }/${item.slug !== null ? item.slug : id}`}
                            className={stock_qty <= 0 && "outofstock"}
                          >
                            <img src={image_url} alt="img" />
                          </Link>
                        </div>
                        <div className="product_body">
                          <p>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 0, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-top`}
                                  style={{
                                    "font-size": "12px",
                                    "text-transform": "capitalize",
                                  }}
                                >
                                  {title}
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/${item.category.slug}/${
                                  item.subcategory.slug
                                }/${item.slug !== null ? item.slug : id}`}
                              >
                                {title.slice(0, 30)}...
                              </Link>
                            </OverlayTrigger>
                            <span>
                              {`${i18next.t(" regular price")}`}: <BiRupee />
                              {regular_price}
                            </span>
                            <span>
                              {`${i18next.t("Special Price")}`}: <BiRupee />
                              {sale_price}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {measuringData && measuringData.product3.length > 0 && (
                <div className="product last_child cm-product">
                  <div className="product_body">
                    <span>{`${i18next.t("Items Available")}`}</span>
                    {measuringData.product3[0].subcategory_id === null ? (
                      <Link
                        className="button"
                        to={`/${
                          measuringData.product3[0].category.slug !== null
                            ? measuringData.product3[0].category.slug !== null
                            : measuringData.product3[0].category_id
                        }"`}
                      >
                        {`${i18next.t("View All")}`}
                      </Link>
                    ) : (
                      <Link
                        className="button"
                        to={`/${
                          measuringData.product3[0].category.slug !== null
                            ? measuringData.product3[0].category.slug
                            : measuringData.product3[0].category_id
                        }/${measuringData.product3[0].subcategory.slug}`}
                      >
                        {`${i18next.t("View All")}`}
                      </Link>
                    )}

                    <div className="product_icon"></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default MeasuringInstrument;
