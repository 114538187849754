import React, { useEffect, useState } from 'react';
import './ProductFeedback.css';
import FeedbackImage from '../../../assets/Feedback.png'
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_LOADING, GET_HOME_DATA, globalAction, GET_PRODUCTS, REMOVE_MESSAGE, HIDE_MESSAGE, SHOW_MESSAGE, REGISTER_REVIEW, SHOW_LOADING } from '../../../redux';
import { getSubCategoryApi } from '../../../api';
import { MdTitle } from 'react-icons/md';
import { Button, Modal } from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import i18next from '../../Translation';
import AnimatedIcon from '../../../components/AnimatedLogo/AnimatedLogo'
import { FcNext, FcPrevious } from 'react-icons/fc';

function ProductFeedback() {
    const { home: { category }, products } = useSelector(state => state.AppReducer)
    const { isLoading, message } = useSelector(state => state.errorReducer)
    const [subCategory, setsubCategory] = useState(undefined)
    const [selectCategoryId, setSelectCategoryId] = useState(1)
    const [modalFlag, setmodalFlag] = useState(false)
    const [modalImg, setModalImg] = useState('')
    const [feedbackFormData, setfeedbackFormData] = useState({
        product_id: '',
        name: '',
        email: '',
        rating: 0,
        review: ''
    })
    const dispatch = useDispatch()

    useEffect(() => {
        window.scroll({ top: 0 })
        dispatch(globalAction(GET_HOME_DATA));
    }, [])

    const getSubCategory = async (id) => {
        await getSubCategoryApi(id)
            .then(async res => {
                const { data, status } = res;
                if (status === 200) {
                    await setsubCategory(data.subcategory.data)
                }
            })
            .catch()
    }

    const handleChange = ((e, name1) => {
        if (name1 === 'category') {
            getSubCategory(e.target.value)
            setSelectCategoryId(e.target.value)
        }
        if (name1 === 'subcategory') { 
            let query = `?limit=24&category_slug=${selectCategoryId}&sub_category_slug=${e.target.value}`
            dispatch(globalAction(GET_PRODUCTS, query))
        }
        let inputData = feedbackFormData;
        inputData[name1] = e.target.value;
        setfeedbackFormData(inputData);
    })

    const selectPage = (pageNumber) => {
        window.scroll({ top: 0 })
        let query = `?limit=24&category_slug=${selectCategoryId}&sub_category_slug=${feedbackFormData.subcategory}&page=${pageNumber}`
        dispatch(globalAction(GET_PRODUCTS, query))
    }

    const modalToggle = ((e, item) => {
        setmodalFlag(!modalFlag)
        if (item !== undefined) {
            feedbackFormData['product_id'] = item.id
            setfeedbackFormData(feedbackFormData)
            setModalImg(item.image_url)
        }
    })

    const handleformValidate = (() => {
        let isValid = true;
        for (const property in feedbackFormData) {
            if (String(feedbackFormData[property]).trim() === "") {
                isValid = false;
            }
        }
        return isValid;
    })

    const handleSubmit = (() => {
        if (handleformValidate()) {
            delete feedbackFormData.category;
            delete feedbackFormData.subcategory;
            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(REGISTER_REVIEW, feedbackFormData))
        }
        else {
            let data = {
                msg: `${i18next.t('Please fill up the all fields.')}`,
                status: "waring",
            };

            dispatch(globalAction(SHOW_MESSAGE, data));
            setTimeout(() => {
                dispatch(globalAction(HIDE_MESSAGE));
                dispatch(globalAction(REMOVE_MESSAGE));
            }, 2000);
        }
    })

    

    useEffect(() => {
        if (message === 'Review added successfully') {
            setfeedbackFormData({
                product_id: '',
                name: '',
                email: '',
                rating: 0,
                review: ''
            })
            setmodalFlag(false)
        }
    }, [message])


    return (
        <div className="product-feedback-main-wrap ">
            {isLoading && <AnimatedIcon />}
            <div className="product-feedback-inner">
                <div className="pf-header-wrap">
                    <h2 className="pf-header">{`${i18next.t('Feedback Form')}`}</h2>
                    <p>{`${i18next.t('Give your valuable feedback about our products which helps to our other customers.')}`}</p>
                </div>
                <div className="pf-form">
                    <div className="pf-form-inner">
                        <div className="pf-form-category">
                            <label for="category">{`${i18next.t('Select Category')}`}</label>
                            <select name="category"
                             onChange={(e) => { handleChange(e, 'category') }} 
                             id="category">
                                <option value='0'>{`${i18next.t('Select Category')}`}</option>
                                {category && category.length > 0
                                    &&
                                    category.map((cat, i) => {
                                        return (
                                            <option value={cat.slug}>{cat.name}</option>
                                        )
                                    })

                                }
                            </select>
                        </div>
                        {subCategory && subCategory.length > 0
                            &&
                            <div className="pf-form-category subcategory">
                                <label for="subcategory">{`${i18next.t('Select Sub Category')}`}</label>

                                <select name="subcategory" onChange={(e) => { handleChange(e, 'subcategory') }} id="cars">
                                    <option value='0'>{`${i18next.t('Select Sub Category')}`}</option>
                                    {subCategory.map((cat, i) => {
                                        return (
                                            <option value={cat.slug}>{cat.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        }
                    </div>
                </div>
                <div className="pf-products-wrap">
                    <div className="pf-products-inner">
                   
                        {((products.data).length > 0 && subCategory !== undefined)
                            ?
                            <>
                            <label className="pf-product-title" for="pf-product-title">{`${i18next.t('Select product & give your feedback.')}`}</label>
                            <div className="pf-products-items">
                                {(products.data).map((item, i) => {
                                    return (
                                        <div className="pf-product-item" onClick={(e) => { modalToggle(e, item) }}>
                                            <div className="pf-product-item-img">
                                                <img src={item.image_url} alt={item.seo_title} />
                                            </div>
                                            <h6>{item.title}</h6>
                                        </div>
                                    )
                                })}
                            </div>
                            </>
                            :
                            <div className="pf-no-products">
                                {/* <img src={FeedbackImage} alt="Give your feedback" /> */}
                                <h5>{`${i18next.t('Products Not Found')}`}</h5>
                            </div>
                        }
                        {(subCategory === undefined) &&
                            <div className="pf-no-products">
                                <img src={FeedbackImage} alt="Give your feedback" />
                                <h5>{`${i18next.t('Add your valuable feedback.')}`}</h5>
                            </div>
                        }
                    </div>
                </div>
                {(products !== null && (products.data).length>0) ?
                    <div className="pagination-wrap">
                        <div >
                            {products.current_page > 1 &&

                                <div className="prev-page"
                                    onClick={((e) => { selectPage(products.current_page - 1) })}>
                                    <FcPrevious />  <span>{`${i18next.t('Prev')}`} </span>
                                </div>
                            }
                        </div>

                        {products.last_page !== products.current_page &&
                            <div className="next-page" onClick={((e) => { selectPage(products.current_page + 1) })}>
                                <span> {`${i18next.t('Next')}`}</span> <FcNext />
                            </div>
                        }

                    </div>

                    : ''
                }

                <div className="pf-modal-from">
                    <Modal
                        show={modalFlag}
                        onHide={() => { modalToggle() }}
                        centered
                        backdrop="static"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{`${i18next.t('Feedback')}`}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="pf-form-modal-img">
                                <img src={modalImg} alt='Product Image' />
                            </div>
                            <div className="pf-form-modal rating">
                                <label for="name">{`${i18next.t('Rating')}`}</label>
                                <ReactStars
                                    count={5}
                                    onChange={((value) => { feedbackFormData['rating'] = value; setfeedbackFormData(feedbackFormData) })}
                                    size={20}
                                    isHalf={true}
                                    edit={true}
                                    activeColor="#ffd731"
                                />
                            </div>
                            <div className="pf-form-modal">
                                <label for="name">{`${i18next.t('Name')}`}</label>
                                <input type="text" placeholder={`${i18next.t('Enter your fullname')}`} onChange={(e) => { handleChange(e, 'name') }} />
                            </div>
                            <div className="pf-form-modal">
                                <label for="Email">{`${i18next.t('Email')}`}</label>
                                <input type="text" placeholder={`${i18next.t('Enter your email')}`} onChange={(e) => { handleChange(e, 'email') }} />
                            </div>
                            <div className="pf-form-modal">
                                <label for="mobile">{`${i18next.t('Mobile')}`}</label>
                                <input type="text" placeholder={`${i18next.t('Enter your mobile number')}`} onChange={(e) => { handleChange(e, 'mobile') }} />
                            </div>
                            <div className="pf-form-modal">
                                <label for="Feedback">{`${i18next.t('Feedback')}`}</label>
                                <textarea type="text" rows="6" placeholder={`${i18next.t('write your feedback')}`} onChange={(e) => { handleChange(e, 'review') }} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {isLoading ?
                                <Button variant="primary" style={{ opacity: 0.5 }}>
                                    {`${i18next.t('Submitting..')}`}  <AnimatedIcon />
                                </Button>
                                :
                                <Button variant="primary" onClick={() => { handleSubmit() }}>
                                    {`${i18next.t('Submit')}`}
                                </Button>
                            }
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default ProductFeedback
