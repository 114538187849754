import React, { useEffect, useState } from "react";
import "./slider.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaCheck, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { BiRupee } from "react-icons/bi";
import i18next from "../../Translation";
import { globalAction, SAVE_PRODUCT, SHOW_TOAST } from "../../../redux";

function CivilLabSlider({ products, addToCart }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.authReducer);
  const { cart } = useSelector((state) => state.AppReducer);
  const [wishlistedItems, setWishlistedItems] = useState([]);

  // Load wishlist items from localStorage
  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    setWishlistedItems(storedWishlist);
  }, []);

  // Function to handle saving product to wishlist
  const saveProductByUser = (product_id) => {
    if (user) {
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
      setWishlistedItems((prevItems) => {
        const newWishlist = [...prevItems, product_id];
        localStorage.setItem("wishlist", JSON.stringify(newWishlist));
        return newWishlist;
      });
    } else {
      history.push("/login");
      const data = {
        msg: i18next.t("Please login or Register to save this product."),
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const isProductInCart = (productId) => {
    return cart.some((cartItem) => cartItem.id === productId);
  };

  const handleAddToCart = (item) => {
    if (user) {
      addToCart(item);
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
      history.push("/login");
    }
  };

  return (
    <section className="civil_lab_slider">
      <Container>
        <Row>
          <Col>
            {products && products.length > 0 && (
              <OwlCarousel
                className="owl-theme"
                nav={true}
                dots={false}
                loop={true}
                items={2}
                autoplay={true}
                smartSpeed={500}
                slideBy={1}
                responsive={{
                  0: { items: 1 },
                  576: { items: 2 },
                }}
              >
                {products.map((item, index) => {
                  const {
                    id,
                    title,
                    image_url,
                    regular_price,
                    sale_price,
                    stock_qty,
                    slug,
                    category,
                    subcategory,
                  } = item;

                  // Check if the product is in the wishlist
                  const isWishlisted = wishlistedItems.includes(id);
                  const isInCart = isProductInCart(id);

                  if (index > 1) {
                    return (
                      <div className="item" key={id}>
                        <div className="product">
                          <Link
                            className={stock_qty <= 0 ? "outofstock" : ""}
                            to={`/${category.slug}/${subcategory.slug}/${
                              slug || id
                            }`}
                          >
                            <img src={image_url} alt={title} />
                          </Link>
                          <div className="product_body">
                            <p>
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    id={`tooltip-top`}
                                    style={{
                                      fontSize: "12px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {title}
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={`/${category.slug}/${subcategory.slug}/${
                                    slug || id
                                  }`}
                                >
                                  {title.slice(0, 30)}...
                                </Link>
                              </OverlayTrigger>{" "}
                              <span>
                                {i18next.t("Regular price")}: <BiRupee />
                                {regular_price}
                              </span>
                              <span>
                                {i18next.t("Special Price")}: <BiRupee />
                                {sale_price}
                              </span>
                            </p>
                            <div className="product_icon">
                              <span className="wishlist">
                                <AiTwotoneHeart
                                  onClick={() => saveProductByUser(id)}
                                  className={`wishlist-icon ${
                                    isWishlisted ? "wishlisted" : ""
                                  }`}
                                />
                              </span>
                              <span
                                className={`shop ${
                                  isInCart || stock_qty <= 0 ? "disabled" : ""
                                }`}
                                onClick={() =>
                                  stock_qty > 0 && handleAddToCart(item)
                                }
                              >
                                {isInCart ? <FaCheck /> : <FaShoppingCart />}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </OwlCarousel>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CivilLabSlider;
