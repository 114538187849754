import React, { useState } from 'react';
import { BsCardList } from 'react-icons/bs';
import './BulkEnquiry.css'
import second from '../../../assets/product/1_12.jpg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { bulkEnquiryMessageApi, showBulkEnquiryDetailsApis } from '../../../api';
import { CLOSE_LOADING, globalAction, SHOW_LOADING } from '../../../redux';
import { useRef } from 'react';
import moment from 'moment';
;


function ConversationForBulkEnquiry(props) {
  const [message, setMessage] = useState(undefined);
  const [equiryData, setEnquiryData] = useState('')
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.authReducer)
  const { match: { params } } = props;
  let enquiryId = params?.id
  let myform = useRef()


  const handleSubmit = ((e) => {
    e.preventDefault()
    let finalObj = {
      user_id: user?.id,
      enq_id: Number(enquiryId),
      message: message
    }
    if (message.trim() !== '') {
      dispatch(globalAction(SHOW_LOADING))
      sendMessage(finalObj)
    }
    else {
      alert(' Please enter message first')
    }
  }
  )

  const getEnquiryListing = (id) => {
    dispatch(globalAction(CLOSE_LOADING))
    showBulkEnquiryDetailsApis(id)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          setEnquiryData(data.data)
        }
      })
      .catch((err) => {
        dispatch(globalAction(CLOSE_LOADING))
        alert('something went wrong')
        console.log(err)
      })
  }

  const sendMessage = (data) => {
    bulkEnquiryMessageApi(data)
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          getEnquiryListing(enquiryId)
          myform.current.reset()
          setMessage('')
        }
      })
      .catch((err) => {
        dispatch(globalAction(CLOSE_LOADING))
        alert('something went wrong')
        console.log(err)
      })
  }

  useEffect(() => {
    window.scroll({ top: 0 })
    dispatch(globalAction(SHOW_LOADING))
    getEnquiryListing(enquiryId)
    document.getElementById('message-section').scrollIntoView(false);
  }, [])

  useEffect(() => {
    let element = document.getElementById('message-section')
    element.scrollTop = element.scrollHeight;
  }, [equiryData])

  




  return (
    <div className="BE_conversation_wrap">
      <div className="BE-inner">
        <div className="BE-main-wrap">
          <div className="top-section">
            <div className="left">
              <img src={equiryData?.product?.image_url} alt={equiryData?.product?.title} />
            </div>
            <div className="center">
              <h1>{equiryData?.product?.title}</h1>
              <p>Enquiry for <strong>{equiryData?.qty}</strong> quantity</p>
            </div>
            {/* <div className="right">
              <span className='enquiry-list'>
                <BsCardList />
              </span>
            </div> */}
          </div>
          <div className="messages-section" id='message-section'>
            {equiryData?.messages?.length > 0 ?
              equiryData?.messages?.map((item, i) => {
                const { id } = item
                return (
                  <div className={`message-content-wrap`} key={item?.created_at}>
                    <div className={`message-content ${item?.user?.role_id === 1 ? '' : 'user'}`}>
                      <p>{item?.message}</p>
                      <span>{moment(item?.created_at).format('ll')} | {item?.user?.role_id === 1 ? 'By Admin' : 'By You'} </span>
                    </div>
                  </div>
                )
              })

              :
              <div className="message-content-wrap" >
                <h5 align="center"> No message yet...!</h5>
              </div>
            }



          </div>
          <div className="bottom-section">
            <form
              ref={myform}
              onSubmit={(e) => handleSubmit(e)} >
              <div className="input-div">
                <input
                  type="text"
                  className='input-text'
                  onChange={(e) => { setMessage(e.target.value) }} />
                <button
                  type="submit"
                  className='submit button'
                >Send</button>
              </div>

            </form>
          </div>

        </div>
      </div>

    </div>

  )
}

export default ConversationForBulkEnquiry;
