import React, { useEffect } from "react";
import "./heardware-products.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiTwotoneHeart } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import i18next from "../../Translation";
import { useDispatch, useSelector } from "react-redux";
import {
  globalAction,
  SAVE_PRODUCT,
  SHOW_TOAST,
  ADD_TO_CART,
  ADD_TO_CART_INTO_DB,
} from "../../../redux";

function HeardwareProducts({ hardware }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authReducer);
  const { cart } = useSelector((state) => state.AppReducer);
  const { currentLang } = useSelector((state) => state.AppReducer);

  const saveProductByUser = (product_id) => {
    if (user) {
      // saveProduct({product_id,user_id:user.id})
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const addToCart = (item) => {
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} ${i18next.t(
              "are successfully added in your cart"
            )}`,
            status: "waring",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} ${i18next.t(
            "are successfully added in your cart"
          )}`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
        if (user) {
          dispatch(globalAction(ADD_TO_CART_INTO_DB, { userId: user.id }));
        }
      }
    }
  };

  useEffect(() => {
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  return (
    <section className="Hard_products">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>
              {currentLang === "en"
                ? hardware && hardware.title_1
                : hardware.title_1_french || hardware.title_1}
            </h3>
          </Col>
          <Col className="product_wrapper">
            {hardware &&
              hardware.product1.map((item, index) => {
                if (index <= 8) {
                  const {
                    id,
                    title,
                    title_french,
                    image_url,
                    regular_price,
                    sale_price,
                    stock_qty,
                    slug,
                    category,
                    subcategory,
                  } = item;
                  return (
                    <div className="product" key={id}>
                      <Link
                        className={stock_qty <= 0 && "outofstock"}
                        to={`/${category.slug}/${subcategory.slug}/${
                          slug !== null ? slug : id
                        }`}
                      >
                        <img src={image_url} alt="img" />
                      </Link>
                      <div className="mobile_img">
                        <Link
                          className={stock_qty <= 0 && "outofstock"}
                          to={`/${category.slug}/${subcategory.slug}/${
                            slug !== null ? slug : id
                          }`}
                        >
                          <img src={image_url} alt="img" />
                        </Link>
                      </div>
                      <div className="product_body">
                        <p>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={
                              <Tooltip
                                id={`tooltip-top`}
                                style={{
                                  "font-size": "12px",
                                  "text-transform": "capitalize",
                                }}
                              >
                                {currentLang === "en"
                                  ? title
                                  : title_french || title}
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/${category.slug}/${subcategory.slug}/${
                                slug !== null ? slug : id
                              }`}
                            >
                              {currentLang === "en"
                                ? title && title.slice(0, 30)
                                : (title_french && title_french.slice(0, 30)) ||
                                  title.slice(0, 30)}
                              ...
                            </Link>
                          </OverlayTrigger>
                          <span>
                            {`${i18next.t(" regular price")}`}: <BiRupee />
                            {regular_price}
                          </span>
                          <span>
                            {`${i18next.t("Special Price")}`}: <BiRupee />
                            {sale_price}
                          </span>
                        </p>
                        <div className="product_icon">
                          <span className="wishlist">
                            <AiTwotoneHeart
                              onClick={() => saveProductByUser(id)}
                            />
                          </span>
                          <span
                            className={`shop ${stock_qty <= 0 && "disabled"}`}
                            onClick={() => stock_qty > 0 && addToCart(item)}
                          >
                            <FaShoppingCart />
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

            {hardware && hardware.product1.length > 0 && (
              <div className="product last_child">
                <div className="product_body">
                  <span>{`${i18next.t("Items Available")}`} </span>
                  {hardware.product1[0].subcategory_id === null ? (
                    <Link
                      className="button"
                      to={`/${
                        hardware.product1[0].category.slug !== null
                          ? hardware.product1[0].category.slug !== null
                          : hardware.product1[0].category_id
                      }"`}
                    >
                      {`${i18next.t("View All")}`}
                    </Link>
                  ) : (
                    <Link
                      className="button"
                      to={`/${
                        hardware.product1[0].category.slug !== null
                          ? hardware.product1[0].category.slug
                          : hardware.product1[0].category_id
                      }/${hardware.product1[0].subcategory.slug}`}
                    >
                      {`${i18next.t("View All")}`}
                    </Link>
                  )}

                  <div className="product_icon"></div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeardwareProducts;
