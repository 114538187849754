import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './AddToCompare.css'
import { ImCross } from "react-icons/im";
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux'
import i18next from '../../Translation';
import { ADD_TO_COMPARE, GET_COMPARED_PRODUCTS, globalAction } from '../../../redux';


function AddToCompareBox() {
    const [compareProducts, SetcompareProducts] = useState([])
    const dispatch = useDispatch()
    const { compareList: { products }, compareList } = useSelector((state) => state.AppReducer)

    useEffect(() => {
        SetcompareProducts(products)
        dispatch(globalAction(GET_COMPARED_PRODUCTS));
    }, [products])

    const handleClearCompareList = (() => {
        let tempCompareList = {
            data: [],
            products: [],
            category: {
                id: '',
                subCategoryId: '',
            }
        }
        dispatch(globalAction(ADD_TO_COMPARE, tempCompareList))
        localStorage.removeItem('BS_ComparedProductList')
    })

    const removeComparedProduct = ((name, dataItem) => {
        let tempProduct = compareProducts.filter(function (item) {
            return item.name !== name
        })
        let tempdata = compareList.data
        tempdata = tempdata.filter(function (item) {
            return item !== dataItem
        })

        let tempCompareList = {
            data: tempdata,
            products: tempProduct,
            category: {
                id: compareList.category.id,
                subCategoryId: compareList.category.subCategoryId,
            }
        }
        dispatch(globalAction(ADD_TO_COMPARE, tempCompareList))
        localStorage.setItem("BS_ComparedProductList", JSON.stringify(tempCompareList))
    })

    return (
        <>
            {compareProducts.length > 0 &&
                <div className="add-to-compare-box-wrap  animate-pop-in">
                    <div className="close-icon" onClick={() => { handleClearCompareList() }}><ImCross /></div>
                    <div className="atc-box">
                        {compareProducts.map((item, i) => {
                            {console.log(item.image)}
                            return (
                                <div className="atc-items animate-pop-in" key={item.id}>
                                    <div className="atc-item-images">
                                        <img src={item.image_url} alt={item.title} />
                                    </div>
                                    <div className="atc-item-name"> {item.title}</div>
                                    {compareProducts.length >= 2 &&
                                        <div className="atc-item-remove" 
                                        onClick={() => { removeComparedProduct(item.name, compareList.data[i]) }}><AiOutlineMinusCircle /></div>
                                    }
                                </div>
                            )
                        })}



                        {compareProducts.length >= 1 &&
                            <Link
                             className="add-to-compare button animate-pop-in " 
                             to='/compare-product'>{`${i18next.t('Lets Compare')}`}</Link>
                        }
                    </div>

                </div>
            }
        </>
    )
}

export default AddToCompareBox
