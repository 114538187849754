import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import '../Toast/Toast.scss'

export default class Toast extends Component {

    render() {
       const {status,message,show}=this.props
        return (
            <div>

           {status && 
            <Modal show={show} backdrop="static"
            keyboard={false}>
               
                <Modal.Body className={status==="success"?"success-body":status==="warning"?"warning-body":"error-body"}>
                    <div className="without-login">
                        <div className={status==='success'?"success-msg":status==='warning'?"warning-msg":"error-msg"}>
                            <p>
                                {status==="success"? <i className="fa fa-check-circle"></i>:status==="warning"?<i className="fa fa-exclamation-circle pr-2"></i>:<i className="fa fa-times-circle"></i>}
                                
                                <h6>{status}:</h6> <span> {message}</span></p>
                        </div>
                    </div>
                    
                </Modal.Body>
                
            </Modal>
    }
            </div>
        )
    }
}
