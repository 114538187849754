import React, { Component } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux'
// import { getDashboardApi } from '../../../api';
// import AnimatedLogo from '../../AnimatedLogo/AnimatedLogo'
import moment from 'moment'
import { Link } from 'react-router-dom';
import './userDetails.scss'
import { globalAction } from '../../../redux/actions';
import { TOTAL_ORDER } from '../../../redux/actionTypes';
import { getRecentOrderByUserIdApi } from '../../../api';
import { BiRupee } from 'react-icons/bi';
import i18next from '../../Translation';

class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            count_save_product: "",
            count_all_order: "",
            count_new_order: "",
            recent_order: [],
            isLoading: false,

        }
    }



    componentDidMount() {
        const { user: { id },getTotalOrderlist } = this.props;
        getTotalOrderlist(id)
        this.getRecentOrders(id)
    }

    getRecentOrders=((id)=>{
        getRecentOrderByUserIdApi(id)
        .then((res)=>{
            const { data, status } = res;
            if (status === 200) {
            console.log(res.data.data)
            this.setState({...this.state,recent_order:res.data.data})
            }
        })
    })
    

    render() {
        const { isLoading, count_all_order, count_new_order, count_save_product, recent_order } = this.state;
        const{ totalOrder}=this.props;
        console.log(this.props)
        return (
            <div className="user-details">
                {isLoading ? null :
                    <>
                        <div className="user-dashboard">{`${i18next.t('User Dashboard')}`}</div>
                        <Row className="userDetail-row">
                            <Col md={4} className="userDetail-4">
                                <div className="card userDetail-card bg-danger">
                                    <Link to='/dashboard/my-order'>
                                    <div className="card-body userDetail-body">
                                        <h6>{`${i18next.t('New Orders')}`}</h6>
                                        <p>{count_new_order}</p>
                                    </div>
                                    </Link>
                                </div>

                            </Col>
                            <Col md={4} className="userDetail-4">
                                <div className="card userDetail-card bg-success">

                                    <div className="card-body userDetail-body">
                                       
                                        <h6>{`${i18next.t('Total Orders')}`} = <span>{totalOrder}</span></h6>
                                       
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="userDetail-4">
                                <div className="card userDetail-card bg-info">
                                <Link to='/dashboard/saved-products'>
                                    <div className="card-body userDetail-body">
                                        <h6>{`${i18next.t('Saved Products')}`}</h6>
                                        <p>{count_save_product}</p>
                                    </div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <div className="savedProduct">
                            <div className="my-heading">{`${i18next.t('Recent Orders')}`}</div>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>{`${i18next.t('Order no')}`}.</th>
                                            <th>{`${i18next.t('Price')}`}</th>
                                            <th>{`${i18next.t('Payment mode')}`}</th>
                                            <th>{`${i18next.t('Status')}`}</th>
                                            <th>{`${i18next.t('Order At')}`}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            recent_order.map((item) => {
                                                const { id, order_number, created_at, order_price, order_status, payment_type } = item;
                                                return (
                                                    <tr key={id + 1}>
                                                        <td><Link to={`/dashboard/order-detail/${id}`}>{order_number}</Link> </td>
                                                        <td >  <BiRupee />{order_price}</td>
                                                        <td >{payment_type === "cash_on_delivery" ? "Cash on delivery" : payment_type}</td>
                                                        <td>{order_status}</td>
                                                        <td>{moment(created_at).format("LL")}</td>
                                                    </tr>
                                                )

                                            }
                                            )}
                                    </tbody>
                                </Table>
                        
                        <div className="read-more">
                            <Link to="/dashboard/my-order" className="read-btn">{`${i18next.t('Read more')}`}</Link>
                        </div>
                        </div>
                    </>}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const { user } = state.authReducer;
    const {myOrderList,totalOrder}=state.AppReducer;
    return { user,myOrderList,totalOrder  }
}

const mapStateToDispatch=((dispatch)=>{
    return{
        getTotalOrderlist:(data) => dispatch(globalAction(TOTAL_ORDER, data)),
    }
})


export default connect(mapStateToProps,mapStateToDispatch)(UserDetails)