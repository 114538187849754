// Initial State

import {
  SET_BLOG,
  SET_BLOG_CATEGORY_LIST,
  SET_BLOG_COMMENTS,
  SET_BLOG_DETAILS,
  SET_BULK_ENQUIRY_LIST,
  SET_BUY_NOW_ITEM,
  SET_COMPARED_PRODUCTS,
  SET_MY_ORDER_LIST,
  SET_ORDER_DETAILS,
  SET_PRODUCT_MAX_PRICE,
  SET_SEARCH_PRODUCTS,
  SET_SEARCH_PRODUCTS_FOR_COMPARE,
  SET_SHIPPING_ESTIMATE,
  SET_TOTAL_ORDER,
} from "..";
import {
  SET_CATEGORY,
  SET_FILTER,
  SET_HOME_DATA,
  SET_LATEST_PRODUCTS,
  SET_PRODUCTS,
  SET_PRODUCT_DETAILS,
  SET_SAVED_PRODUCTS,
  ADD_TO_CART,
  SET_DELIVERY_STATUS,
  ADD_TO_COMPARE,
  CHANGE_LANG,
  CURRENT_LANG,
  //   SET_SHIPPING_ESTIMATE,
} from "../actionTypes";

const compareList = JSON.parse(localStorage.getItem("BS_ComparedProductList"));
const initialState = {
  home: {
    best_deal_product: {
      data: [],
    },
    best_selling_product: {
      data: [],
    },
    category: [],
    count_best_deal_product: 0,
    count_best_selling_product: 0,
    count_featured_product: 0,
    count_most_popular_product: 0,
    count_new_product: 0,
    featured_product: {
      data: [],
    },
    most_popular_product: {
      data: [],
    },
    new_product: {
      data: [],
    },
    slider_images: [],
    home_category_1: {
      title_1: "",
      product1: [],
      category_1: "",
    },
    home_category_2: {
      title_2: "",
      product2: [],
      category_2: "",
      banner_2: "",
    },
    home_category_3: {
      title_3: "",
      category_3: "",
    },
  },
  products: {
    data: [],
    page: 1,
    from: 1,
    to: 1,
    last_page: 1,
    total: 1,
  },
  filter: {
    limit: 24,
    page: 1,
    max_price: 10000,
    min_price: 0,
    // category_id: null,
    // sub_category_id: null,
    category_slug: null,
    sub_category_slug: null,
    order: "asc",
    // rating: [],
    // brand_id: [],
    // offer_id:null
  },
  categories: [],
  products_detail: {
    product_images: [],
  },
  related_products: [],
  savedProducts: {
    data: [],
    current_page: 1,
    last_page: 1,
    total: 0,
  },
  latest_products: {
    data: [],
  },
  cart: [],
  buyNow: undefined,
  Check_Delivery: {
    msg: "",
    COD: 0,
  },
  myOrderList: {
    data: [],
    current_page: 1,
    last_page: 1,
    total: 0,
  },
  totalOrder: 0,
  pendingOrder: 0,
  orderDetail: undefined,
  bulkEnquiryList: [],
  compareProducts: [],
  searchForCompare: [],
  compareList: compareList
    ? compareList
    : {
        data: [],
        products: [],
        category: {
          id: undefined,
          subCategoryId: undefined,
        },
      },
  blog: {
    data: [],
  },
  blog_categoryList: [],
  popular_tags: [],
  blogDetails: {
    title: "",
    body: "",
    tags: "",
    created_at: "",
    comment_count: "",
    like_count: "",
    image: null,
  },
  recent_posts: [],
  blogComments: {
    data: [],
  },
  product_max_price: 100000,
  shipping_estimate: [],
  currentLang: "en",
  currency: "eur",
};
// Redux: Counter Reducer
const AppReducer = (state = initialState, action) => {
  const { type, payload } = action;

  console.log(payload, "payloadpayload");

  switch (type) {
    case SET_HOME_DATA: {
      return {
        ...state,
        home: payload, // setting into reduce state
      };
    }
    case SET_FILTER: {
      return {
        ...state,
        filter: payload,
      };
    }
    case CHANGE_LANG: {
      return {
        ...state,
        currentLang: payload,
        currency: payload === "en" ? "usd" : "eur",
      };
    }
    case CURRENT_LANG: {
      const data = state.currentLang;
      return data;
    }
    case SET_PRODUCTS: {
      return {
        ...state,
        products: payload.product_list, // setting into reduce state
        // products_detail: payload.product_list.data,
        category_banner: payload.category_details,
      };
    }

    case SET_CATEGORY: {
      // let arr = [];
      // payload.forEach(item => {
      //   arr.push({ title: item.name, itemId: item.id })
      // });
      return {
        ...state,
        categories: payload,
        // sideCategory: arr
      };
    }
    case SET_PRODUCT_DETAILS: {
      // debugger;
      let temp = { product_variants: payload.product_variants };
      let product_details_with_variants = { ...payload.product, ...temp };
      return {
        ...state,
        products_detail: product_details_with_variants, // setting into reduce state
        related_products: payload.related_products,
      };
    }
    case SET_SAVED_PRODUCTS: {
      return {
        ...state,
        savedProducts: payload,
      };
    }
    case SET_LATEST_PRODUCTS: {
      return {
        ...state,
        latest_products: payload, // setting into reduce state
      };
    }

    case ADD_TO_CART: {
      return {
        ...state,
        cart: payload,
      };
    }

    case SET_DELIVERY_STATUS: {
      return {
        ...state,
        Check_Delivery: payload,
      };
    }

    case SET_MY_ORDER_LIST: {
      return {
        ...state,
        myOrderList: payload,
      };
    }

    case SET_TOTAL_ORDER: {
      return {
        ...state,
        totalOrder: payload,
      };
    }
    case SET_ORDER_DETAILS: {
      return {
        ...state,
        orderDetail: payload,
      };
    }
    case SET_BULK_ENQUIRY_LIST: {
      return {
        ...state,
        bulkEnquiryList: payload,
      };
    }

    case ADD_TO_COMPARE: {
      return {
        ...state,
        compareList: payload,
      };
    }

    case SET_COMPARED_PRODUCTS: {
      return {
        ...state,
        compareProducts: payload,
      };
    }

    case SET_BLOG: {
      return {
        ...state,
        blog: payload, // setting into reduce state
      };
    }
    case SET_BLOG_CATEGORY_LIST: {
      return {
        ...state,
        blog_categoryList: payload,
      };
    }
    case SET_BLOG_DETAILS: {
      const { blog, popular_tags, recent_posts } = payload;
      return {
        ...state,
        popular_tags,
        blogDetails: blog,
        recent_posts,
      };
    }
    case SET_BLOG_COMMENTS: {
      return {
        ...state,
        blogComments: payload,
      };
    }

    case SET_PRODUCT_MAX_PRICE:
      return {
        ...state,
        product_max_price: payload,
      };

    case SET_SHIPPING_ESTIMATE:
      console.log(payload, "payloadpayloadpayload");
      return {
        ...state,
        shipping_estimate: payload,
      };

    case SET_SEARCH_PRODUCTS_FOR_COMPARE:
      return {
        ...state,
        searchForCompare: payload,
      };

    case SET_BUY_NOW_ITEM:
      return {
        ...state,
        buyNow: payload,
      };

    default: {
      return state;
    }
  }
};
// Exports
export default AppReducer;
