import React from "react";
import Home from "./pages/Home";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Components() {
  return (
    <section className="components">
      <Home />
    </section>
  );
}

export default Components;
