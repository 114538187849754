import React, { useState } from "react";
import "./slider.scss";
import { Container, Row, Col } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { Link, withRouter } from "react-router-dom";
// import BestBrandImg1 from "../image/best_brands/brand1.jpg";
// import BestBrandImg2 from "../image/best_brands/brand2.jpg";
// import BestBrandImg3 from "../image/best_brands/brand3.jpg";
// import BestBrandImg4 from "../image/best_brands/brand4.jpg";
// import BestBrandImg5 from "../image/best_brands/brand5.jpg";
// import BestBrandImg6 from "../image/best_brands/brand6.jpg";
// import BestBrandImg7 from "../image/best_brands/brand7.jpg";
// import BestBrandImg8 from "../image/best_brands/brand8.jpg";
// import BestBrandImg9 from "../image/best_brands/brand9.jpg";
// import BestBrandImg10 from "../image/best_brands/brand10.jpg";
// import BestBrandImg11 from "../image/best_brands/brand11.jpg";
// import BestBrandImg12 from "../image/best_brands/brand12.jpg";
// import BestBrandImg13 from "../image/best_brands/brand13.jpg";
// import BestBrandImg14 from "../image/best_brands/brand14.jpg";
// import BestBrandImg15 from "../image/best_brands/brand15.jpg";
// import BestBrandImg16 from "../image/best_brands/brand16.jpg";
// import BestBrandImg17 from "../image/best_brands/brand17.jpg";
// import BestBrandImg18 from "../image/best_brands/brand18.jpg";
// import BestBrandImg19 from "../image/best_brands/brand19.jpg";
// import BestBrandImg20 from "../image/best_brands/brand20.jpg";
// import BestBrandImg21 from "../image/best_brands/brand21.jpg";
// import BestBrandImg22 from "../image/best_brands/brand22.jpg";
// import BestBrandImg23 from "../image/best_brands/brand23.jpg";
// import BestBrandImg24 from "../image/best_brands/brand24.jpg";
// import BestBrandImg25 from "../image/best_brands/brand25.jpg";
// import BestBrandImg26 from "../image/best_brands/brand26.jpg";

import { useDispatch } from "react-redux";
import { GET_SEARCH_PRODUCT, globalAction } from "../../../redux";
import i18next from "../../Translation";

function BestBrands({ brands, history }) {
  const dispatch = useDispatch();
  const onSearch = (search) => {
    let param = "";
    param += `?search=${search}`;
    // console.log("params", param);
    dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: history }));
  };
  return (
    <section className="best_brands spacing">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>{`${i18next.t('Best Brands')}`}</h3>
          </Col>
          {brands && brands.length > 0 && (
            <OwlCarousel
              className="owl-theme"
              autoplay={true}
              smartSpeed={300}
              loop={true}
              margin={10}
              responsive={{
                0: {
                  nav: false,
                  dots: true,
                  items: 3,
                },
                768: {
                  dots: true,
                  nav: false,
                  items: 4,
                },
                992: {
                  items: 6,
                  dots: false,
                  nav: true,
                },
                1024: {
                  items: 8,
                  dots: false,
                  nav: true,
                },
              }}
            >
              {brands &&
                brands.map((item) => (
                
                  <div className="item" onClick={() => onSearch(item.name)}>
                    
                    <div className="img_wrap">
                      <Link to="">
                        <img src={item.image && item.image} alt="img" />
                      </Link>
                    </div>
                  </div>
                ))}
              {/* <div class="item">
              <img src={BestBrandImg1} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg2} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg3} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg4} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg5} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg6} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg7} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg8} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg9} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg10} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg11} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg12} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg13} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg14} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg15} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg16} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg17} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg18} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg19} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg20} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg21} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg22} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg23} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg24} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg25} alt="img" />
            </div>
            <div class="item">
              <img src={BestBrandImg26} alt="img" />
            </div> */}
            </OwlCarousel>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default withRouter(BestBrands);
