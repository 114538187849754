import React from "react";
import "./slider.scss";
import { Container, Row, Col } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import i18next from "../../Translation";
// import BestBrandImg1 from "../image/best_brands/brand1.jpg";
// import BestBrandImg2 from "../image/best_brands/brand2.jpg";
// import BestBrandImg3 from "../image/best_brands/brand3.jpg";
// import BestBrandImg4 from "../image/best_brands/brand4.jpg";
// import BestBrandImg5 from "../image/best_brands/brand5.jpg";
// import BestBrandImg6 from "../image/best_brands/brand6.jpg";
// import BestBrandImg7 from "../image/best_brands/brand7.jpg";
// import BestBrandImg8 from "../image/best_brands/brand8.jpg";
// import BestBrandImg9 from "../image/best_brands/brand9.jpg";
// import BestBrandImg10 from "../image/best_brands/brand10.jpg";
// import BestBrandImg11 from "../image/best_brands/brand11.jpg";
// import BestBrandImg12 from "../image/best_brands/brand12.jpg";
// import BestBrandImg13 from "../image/best_brands/brand13.jpg";
// import BestBrandImg14 from "../image/best_brands/brand14.jpg";
// import BestBrandImg15 from "../image/best_brands/brand15.jpg";
// import BestBrandImg16 from "../image/best_brands/brand16.jpg";
// import BestBrandImg17 from "../image/best_brands/brand17.jpg";
// import BestBrandImg18 from "../image/best_brands/brand18.jpg";
// import BestBrandImg19 from "../image/best_brands/brand19.jpg";
// import BestBrandImg20 from "../image/best_brands/brand20.jpg";
// import BestBrandImg21 from "../image/best_brands/brand21.jpg";
// import BestBrandImg22 from "../image/best_brands/brand22.jpg";
// import BestBrandImg23 from "../image/best_brands/brand23.jpg";
// import BestBrandImg24 from "../image/best_brands/brand24.jpg";
// import BestBrandImg25 from "../image/best_brands/brand25.jpg";
// import BestBrandImg26 from "../image/best_brands/brand26.jpg";

function ValuedCusmomer({ our_valued_customers }) {
  
  return (
    <section className="value_customer spacing">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>{`${i18next.t('Our Valued Customres')}`}</h3>
          </Col>
        </Row>
      </Container>
      <div className="marquee_wrapper">
        <Container>
          <Row>
            <Marquee>
              {our_valued_customers &&
                our_valued_customers.map((item) => (
                  <div class="item">
                    <div className="img_wrap">
                      <img src={item.image && item.image} alt="img" />
                    </div>
                  </div>
                ))}
            </Marquee>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default ValuedCusmomer;
