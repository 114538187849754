import React from "react";
import OwlCarousel from "react-owl-carousel";
import "./slider.scss";
import {Link} from 'react-router-dom';
// import BannerImg2 from "../image/banner2.jpg";
// import BannerImg3 from "../image/banner3.jpg";
// import BannerImg from "../image/banner.jpg";

function BannerSlider({ slider_images }) {
  return (
    <section className="banner_slider">
      {slider_images.length > 0 && (
        <OwlCarousel className="owl-theme" items={1} autoplay={true} smartSpeed={500} loop={true}>
          {slider_images.map((item) => {
            const { id, image,category_slug, } = item;
            return (
              <div class="item" key={id}>
                <img src={image} alt="slider" />
                {category_slug!==null &&
                 <Link to={`/${category_slug}`}>

                 </Link>
                }
               
              </div>
            );
          })}
        </OwlCarousel>
      )}
    </section>
  );
}

export default BannerSlider;
