import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillYoutube } from "react-icons/ai";
import { ImFacebook, ImLinkedin2, ImTwitter } from "react-icons/im";
import VisaMaster from "../../../assets/visa_mas.png";
import American from "../../../assets/american.png";
import Ppal from "../../../assets/ppal.png";
import { Link } from "react-router-dom";

function FooterBottom(props) {
  const{data}=props;
  return (
    <section className="footer_bottom">
      <Container>
        <Row>
          <Col>
            <div className="social_icon">
              <ul>
                <li className="twitter">
                  <Link to="#" onClick={() => window.open(`${data && data.twitter_link}`)}>
                    <ImTwitter />
                  </Link>
                </li>
                <li className="youtube" onClick={() => window.open(`${data&& data.youtube_link}`)}>
                  <Link to="#">
                    <AiFillYoutube />
                  </Link>
                </li>
                <li className="facebook" onClick={() => window.open(`${data && data.fb_link}`)}>
                  <Link to="#">
                    <ImFacebook />
                  </Link>
                </li>
                <li className="linkedin" onClick={() => window.open(`${data && data.linkedin_link}`)}>
                  <Link to="#">
                    <ImLinkedin2 />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="copyright">
              <p>{data && data.copyright_text}</p>
            </div>
            <div className="pgateway">
              <Link to="">
                <img src={VisaMaster} alt="img" />
                <img src={American} className="american_ex" alt="img" />
                <img src={Ppal} alt="img" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FooterBottom;
