import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ShippingAddress.scss";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import i18next from "../../Translation";
import { Form, Button, Accordion, Card } from "react-bootstrap";

function ShippingAddress() {
  const [totalPrice, settotalPrice] = useState(0);
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.AppReducer);
  const { user, Login } = useSelector((state) => state.authReducer);
  const { visibleMessage } = useSelector((state) => state.errorReducer);

  const calculateTotalPrice = (cart) => {
    let total = 0;
    cart.map((item, i) => {
      total += parseFloat(item.regular_price * item.quantity);
      return total;
    });
    settotalPrice(Number(total).toFixed(2));
  };

  useEffect(() => {
    calculateTotalPrice(cart);
  }, [cart]);

  return (
    <div className="shipping-address-wrap">
      <Container>
        <Row>
          <Col md={{ span: 12, order: 2 }} lg={{ span: 8, order: 1 }} className="left-col">
            <div className="left-inner">
              <div className="top-header">
                <h1>{`${i18next.t('Add Shipping Address')}`}</h1>
                <div className="shiping-notice">
                  <p>{`${i18next.t('Bellstone does not ship to PO Box, mailbox, and APO/FPO addresses.')}`}</p>
                </div>
              </div>
              <div className="form-wrap">
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>{`${i18next.t('First Name')}`}</Form.Label>
                      <Form.Control type="text" placeholder={`${i18next.t('First Name')}`} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>{`${i18next.t('Last Name')}`}</Form.Label>
                      <Form.Control type="text" placeholder={`${i18next.t('Last Name')}`} />
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>{`${i18next.t('Email')}`}</Form.Label>
                      <Form.Control type="email" placeholder={`${i18next.t('Email')}`} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>{`${i18next.t('Contact Number')}`}</Form.Label>
                      <Form.Control type="number" placeholder={`${i18next.t('Contact Number')}`} />
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>{`${i18next.t('Address')}`}</Form.Label>
                    <Form.Control placeholder="1234 Main St" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGridAddress2">
                    <Form.Label>{`${i18next.t('Address')}`} 2</Form.Label>
                    <Form.Control placeholder="Apartment, studio, or floor" />
                  </Form.Group>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridCity">
                      <Form.Label>{`${i18next.t('City')}`}</Form.Label>
                      <Form.Control />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                      <Form.Label>{`${i18next.t('State')}`}</Form.Label>

                      <select className="form-select" id="cars" name="cars">
                        <option value="0">Choose...</option>
                        <option value="1">Delhi</option>
                        <option value="2">Bihar</option>
                        <option value="3">Uttar Pradesh</option>
                      </select>
                      {/* <Form.Control /> */}
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridZip">
                      <Form.Label>{`${i18next.t('Zip')}`}</Form.Label>
                      <Form.Control />
                    </Form.Group>
                  </Row>

                  <Form.Group className="mb-3" controlId="formGridAddress2">
                    <Form.Control as="textarea" placeholder="Additional Notes" style={{ height: "120" }} />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                  {`${i18next.t('Submit')}`}
                  </Button>
                </Form>

                <Accordion defaultActiveKey="0" className="shipping_address_form">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        <Form.Check type="checkbox" label="Billing Address as a Delivery Address" />
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Form>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{`${i18next.t('First Name')}`}</Form.Label>
                            <Form.Control type="text" placeholder={`${i18next.t('First Name')}`} />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{`${i18next.t('Last Name')}`}</Form.Label>
                            <Form.Control type="text" placeholder={`${i18next.t('Last Name')}`} />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{`${i18next.t('Email')}`}</Form.Label>
                            <Form.Control type="email" placeholder={`${i18next.t('Email')}`} />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{`${i18next.t('Contact Number')}`}</Form.Label>
                            <Form.Control type="number" placeholder={`${i18next.t('Contact Number')}`} />
                          </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="formGridAddress1">
                          <Form.Label>{`${i18next.t('Address')}`}</Form.Label>
                          <Form.Control placeholder="1234 Main St" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                          <Form.Label>{`${i18next.t('Address')}`} 2</Form.Label>
                          <Form.Control placeholder="Apartment, studio, or floor" />
                        </Form.Group>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>{`${i18next.t('City')}`}</Form.Label>
                            <Form.Control />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>{`${i18next.t('State')}`}</Form.Label>

                            <select className="form-select" id="cars" name="cars">
                              <option value="0">Choose...</option>
                              <option value="1">Delhi</option>
                              <option value="2">Bihar</option>
                              <option value="3">Uttar Pradesh</option>
                            </select>
                            {/* <Form.Control /> */}
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>{`${i18next.t('Zip')}`}</Form.Label>
                            <Form.Control />
                          </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="formGridAddress2">
                          <Form.Control as="textarea" placeholder="Additional Notes" style={{ height: "120" }} />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                        {`${i18next.t('Submit')}`}
                        </Button>
                      </Form>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </Col>
          <Col md={{ span: 12, order: 2 }} lg={{ span: 4, order: 1 }} className="left-col">
            <div className="right-inner">
              <div className="order-summary-wrap">
                <div class="item">
                  <h5>
                  {`${i18next.t('Order Summary')}`} <span>({cart.length} {`${i18next.t('Items')}`})</span>
                  </h5>
                </div>
                <div class="subtotal">
                  <p>{`${i18next.t('Subtotal:')}`}</p>
                  <p>
                    <span>
                      <BiRupee /> {totalPrice}
                    </span>
                  </p>
                </div>
                <div class="subtotal">
                  <p>{`${i18next.t('Est. Shipping:')}`}</p>
                  <p>
                    <span>
                      <BiRupee /> 0.00
                    </span>
                  </p>
                </div>
                <div class="total">
                  <h4>{`${i18next.t('Order Total:')}`}</h4>
                  <h4>
                    <span>
                      <BiRupee />
                      {totalPrice}
                    </span>
                  </h4>
                </div>
                <div class="hr-line">
                  <hr />
                </div>
                <div class="PlacedOrder">
                  <button>{`${i18next.t('Place Order')}`}</button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(ShippingAddress);
