import React, { useState, useEffect, useRef } from "react";
import "./review-discription.scss";
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Dropdown,
  InputGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { HiOutlineThumbUp, HiOutlineThumbDown } from "react-icons/hi";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { GET_PRODUCT_DETAILS, globalAction, SHOW_TOAST } from "../../../redux";
import { addReviewApi } from "../../../api";
import parser from 'react-html-parser';
import i18next from "../../Translation";

function ReviewDiscription({ description, description_french, productReviews, productId, additional_information, additional_information_french, keyFeatures }) {
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const { products_detail } = useSelector((state) => state.AppReducer);
  const { product_reviews } = products_detail;
  const dispatch = useDispatch();
  const {  currentLang } = useSelector((state) => state.AppReducer);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);
  const [finalReviews, SetfinalReviews] = useState(productReviews);
  const refInput = useRef()

  const reviewSorting = (productReviews, typeOfSorting) => {
    let tempArray = []
    if (productReviews !== undefined && productReviews.length > 0) {
      tempArray = productReviews.filter(
        (review) => Math.round(review.rating) === Number(typeOfSorting)
      );
    }
    SetfinalReviews(tempArray)
    if (Number(typeOfSorting) === 0) {
      SetfinalReviews(productReviews)
    }
  };

  const CountReviewByRating = (productReviews, rating) => {
    let tempArray = 0;
    if (productReviews !== undefined && productReviews.length > 0) {
      tempArray = productReviews.filter(
        (review) => Math.round(review.rating) === rating
      );
    }
    return tempArray.length;
  };

  const getProductDetail = async (id) => {
    dispatch(globalAction(GET_PRODUCT_DETAILS, id));
  };

  const onSendReview = () => {
   
    if (isLogin) {
      const { name, email, id } = user;
      addReviewApi({
        name,
        email,
        user_id: id,
        product_id: products_detail.id,
        review,
        rating,
      })
        .then((res) => {
          const { status, data } = res;
          if (status === 200) {
            dispatch(globalAction(SHOW_TOAST, { data }));
            getProductDetail(products_detail.id);
            refInput.current.value=''
          
          }
        })
        .catch((err) => {
          dispatch(globalAction(SHOW_TOAST, err.response));
        });
    } else {
      dispatch(
        globalAction(SHOW_TOAST, {
          data: {
            msg: `${i18next.t('Please login or register for add review!')}`,
            status: "warning",
          },
        })
      );
    }
  };

  const onDropDownSelect = (e, allReviews) => {
    let rating = e.target.value
    reviewSorting(allReviews, rating)
  };

  useEffect(() => {
    SetfinalReviews(productReviews)
  }, [productReviews]);

  return (
    <section className="product-discription spacing">
      <Container>
        <Row>
          <Col md={12}>
            <div className="discription">
              <Tabs
                eventKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3 title_bar"
              >
                <Tab eventKey="Description" title={`${i18next.t('Description')}`}>
                  <div className="discription_content details">
                    <div className="details_content">{parser(currentLang === 'en' ? description : description_french || description)}</div>
                    {}
                    {/* <div className="related_img">
                      <div class="img">
                        <img src={Civil1} alt="solar" />
                        <img src={Civil2} alt="solar" />
                        <img src={Civil3} alt="solar" />
                        <img src={Civil1} alt="solar" />
                      </div>
                      <p>Uniquely engineered solar solutions to cater to specific energy needs & space requirements. Our Solar solutions for small commercial complexes are business and planet-friendly. 25 years Warranty. Solar</p>
                    </div> */}
                  </div>
                </Tab>
                <Tab
                  eventKey="Reviews"
                  title={`${i18next.t('Review')} (${product_reviews && product_reviews.length
                    })`}
                    className="reviews-wrap"
                >
                  <div className="discription_content reviews">
                    <Row>
                      <Col md={12}>
                        <div className="review_title">
                          <h6>{`${i18next.t('CUSTOMER REVIEWS')}`}</h6>
                          <p>
                          {`${i18next.t('Customer Reviews on')}`} {products_detail.title}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} className="rating_wrap">
                        <Row>
                          <Col sm={6}>
                            <div className="start">
                              <p>
                                <AiFillStar />
                                <span>
                                  {Number(products_detail.avg_rating).toFixed(
                                    1
                                  )}
                                </span>
                              </p>
                              <p>
                              {`${i18next.t('Average rating based on')}`}{" "}
                                {product_reviews && product_reviews.length}{" "}
                                {`${i18next.t('ratings')}`}
                              </p>
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="reting">
                              <div className="reting_inner">
                                <ReactStars
                                  count={5}
                                  // onChange={this.ratingChanged}
                                  size={20}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                />
                                <span>
                                  {CountReviewByRating(product_reviews, 5)}
                                </span>
                              </div>
                              <div className="reting_inner">
                                <ReactStars
                                  count={4}
                                  // onChange={ratingChanged}
                                  size={20}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                />
                                <span>
                                  {CountReviewByRating(product_reviews, 4)}
                                </span>
                              </div>
                              <div className="reting_inner">
                                <ReactStars
                                  count={3}
                                  //   onChange={this.ratingChanged}
                                  size={20}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                />
                                <span>
                                  {CountReviewByRating(product_reviews, 3)}
                                </span>
                              </div>
                              <div className="reting_inner">
                                <ReactStars
                                  count={2}
                                  //   onChange={this.ratingChanged}
                                  size={20}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                />
                                <span>
                                  {CountReviewByRating(product_reviews, 2)}
                                </span>
                              </div>
                              <div className="reting_inner">
                                <ReactStars
                                  count={1}
                                  //   onChange={this.ratingChanged}
                                  size={20}
                                  isHalf={true}
                                  edit={false}
                                  activeColor="#ffd731"
                                />
                                <span>
                                  {CountReviewByRating(product_reviews, 1)}
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      {user !== null && isLogin && (
                        <Col lg={6} className="share_rating">
                          <p>{`${i18next.t('Share Your Thoughts With Other Customers')}`}</p>
                          <input
                          ref={refInput}
                            placeholder={`${i18next.t('Enter you comment')}`}
                            type="text-area"
                            onChange={(e) => setReview(e.target.value)}
                          />
                          <span>{`${i18next.t('Rate the Product')}`}</span>
                          <div className="share_rating_button">
                            <ReactStars
                              count={5}
                              onChange={(value) => setRating(value)}
                              size={30}
                              value={0}
                              isHalf={true}
                              activeColor="#ffd731"
                            />
                            <button
                              type="button"
                              onClick={() => onSendReview()}
                              class="cart_button "
                            >
                              {`${i18next.t('Add Review')}`}
                            </button>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col md={12} className="reting_bar">

                        <h5>{`${i18next.t('Top Customer Reviews')}`}</h5>
                        <div className="filter">
                          <span>{`${i18next.t('FILTER BY')}`}:</span>
                          <select name="cars" className="sortingRating" onChange={(e) => { onDropDownSelect(e, product_reviews) }}>
                            <option value="0">{`${i18next.t('All')}`}</option>
                            <option value="1">1 {`${i18next.t('Start Rating')}`}</option>
                            <option value="2">2 {`${i18next.t('Start Rating')}`}</option>
                            <option value="3">3 {`${i18next.t('Start Rating')}`}</option>
                            <option value="4">4 {`${i18next.t('Start Rating')}`}</option>
                            <option value="5">5 {`${i18next.t('Start Rating')}`}</option>
                          </select>
                        </div>


                        {/* <Dropdown onSelect={(e)=>{onDropDownSelect()}} >
                            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" >
                              All start
                            </Dropdown.Toggle>

                            <Dropdown.Menu variant="dark"  >
                              <Dropdown.Item href="#/action-1" active>
                                All start
                              </Dropdown.Item>
                              <Dropdown.Item >5 Start Rating</Dropdown.Item>
                              <Dropdown.Item >4 Start Rating</Dropdown.Item>
                              <Dropdown.Item >3 Start Rating</Dropdown.Item>
                              <Dropdown.Item >2 Start Rating</Dropdown.Item>
                              <Dropdown.Item>1 Start Rating</Dropdown.Item>
                            </Dropdown.Menu> 
                        </Dropdown>*/}

                      </Col>

                      <Col md={12} className="customre_review">
                        {finalReviews && finalReviews.length > 0 ? (
                          finalReviews.map((review, i) => {
                            return (
                              <Row>
                                <Col md={4} className="review_writer">
                                  <ReactStars
                                    count={review.rating}
                                    //   onChange={this.ratingChanged}
                                    size={20}
                                    isHalf={true}
                                    edit={false}
                                    activeColor="#fdb92c"
                                    color="#fdb92c"
                                    className="active-rating"
                                  />
                                  <span className="name">{review.name}</span>
                                  <date>{review.created_at}</date>
                                  <span>{`${i18next.t('Verified User')}`}</span>
                                </Col>
                                <Col md={8} className="review_text">
                                  <h6>{review.review.slice(0, 20)}...</h6>
                                  <p>{review.review}</p>
                                  <div className="like">
                                    {products_detail.likes && (
                                      <span>
                                      {`${i18next.t('0 of 0 users found this review helpful. Was this review helpful?')}`}  
                                      </span>
                                    )}

                                    <button>
                                      <HiOutlineThumbUp />
                                    </button>
                                    <button>
                                      <HiOutlineThumbDown />
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })
                        ) : (
                          <p>
                            {`${i18next.t('No reviews.')}`}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Tab>
                
              
                  <Tab eventKey="Additional_Information" title={`${i18next.t('Additional Information')}`}>
                    <div className="discription_content">{parser(currentLang === 'en' ? additional_information : additional_information_french || additional_information)}</div>
                  </Tab>
                   
                {keyFeatures!==undefined &&(
                keyFeatures.length>0 &&(
                  <Tab eventKey="key Features" title={`${i18next.t('Key Features')}`}>
                    <div className="keyFeatures">
                      <table>
                        <tbody>
                        {keyFeatures.map((item,i)=>{
                          return(
                            <tr>
                            <td height="57"  width="210"><strong>{item.name}</strong></td>
                            <td width="248"><strong>{item.value}</strong></td>
                          </tr>
                          )
                        })}
                          

                        </tbody>
                      </table>
                      </div>
                  </Tab>
                  )
                )
                }

              </Tabs>
            </div>
          </Col>
          <Col md={12} className="related_img"></Col>
        </Row>
      </Container>
    </section>
  );
}

export default ReviewDiscription;
