import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  globalAction,
  ADD_TO_CART,
  SHOW_TOAST,
  GET_HOME_DATA,
  REMOVE_MESSAGE,
  SHOW_LOADING,
  CLOSE_LOADING,
  GET_SHIPPING_ESTIMATE,
  UPDATE_QTY_ADD_TO_CART_INTO_DB,
  DELETE_ADD_TO_CART_FROM_DB,
} from "../../../redux";
import { Container, Row, Col } from "react-bootstrap";
import { BiRupee } from "react-icons/bi";
import "./OrderSummary.css";
import { FaEdit, FaMinus, FaPlus } from "react-icons/fa";

import { Link } from "react-router-dom";
import { RiDeleteBin2Line } from "react-icons/ri";
import AnimatedLogo from "../../AnimatedLogo/AnimatedLogo";
import {
  addCodOrderApi,
  checkEstimateShippingApi,
  getPaymentSettingApis,
} from "../../../api";
import { withRouter } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import i18next from "../../Translation";

function OrderSummary({ history }) {
  const dispatch = useDispatch();
  const { cart, shipping_estimate, buyNow } = useSelector(
    (state) => state.AppReducer
  );
  console.log(shipping_estimate, "shipping_estimate");
  const { user } = useSelector((state) => state.authReducer);
  const { visibleMessage, isLoading } = useSelector(
    (state) => state.errorReducer
  );
  const [totalPrice, settotalPrice] = useState(0);
  const [orderDetails, SetorderDetails] = useState(undefined);
  const [paymentOption, setpaymentOption] = useState(null);
  const [totalGSTCharges, settotalGSTCharges] = useState(0);
  const [razorPayKeyID, setrazorPayKeyID] = useState(undefined);
  const [codeAvailable, setcodeAvailable] = useState("yes");
  const [MinCodPrice, setMinCodPrice] = useState(null);
  const [maxCodPrice, setMaxCodPrice] = useState(null);
  const [withoutShippingPrice, setwithoutShippingPrice] = useState(0);
  const [quantityAlert, setQuantityAlert] = useState(undefined);
  // const [buyNow, setBuyNow] = useState(JSON.parse(localStorage.getItem('buyNow')))

  // console.log('orderDetails', orderDetails)

  const shippingEstimate = (cart, pincode) => {
    console.log(cart, pincode, "cart");
    let totalWeight = 0;
    cart.map((item, i) => {
      let weightInKg = item.weigth;
      // calculate weigth for checking shipping address
      if (item.weight.slice(-2) === "gm" || item.weight.slice(-2) === "GM") {
        weightInKg =
          (Number(item.weight.substring(0, item.weight.length - 2)) / 1000) *
          item.quantity;
      }
      totalWeight += 1 * item.quantity;
      console.log(totalWeight, weightInKg, item.quantity, "totalWeight");
    });
    if (totalWeight > 0) {
      dispatch(globalAction(SHOW_LOADING));
      let query = `?delivery_postcode=${pincode}&cod=1&weight=${totalWeight}`;
      dispatch(globalAction(GET_SHIPPING_ESTIMATE, query));
    }

    checkEstimateShippingApi(
      `?delivery_postcode=${pincode}&cod=1&weight=${totalWeight}`
    );
  };

  const calculateTotalPrice = (cart) => {
    let total = 0;
    let AllGSTCharges = 0;
    cart.map((item, i) => {
      let price;
      let GSTcharges;
      price = item.regular_price;
      if (item.sale_price !== null) {
        price = item.sale_price;
      }
      if (item.tax !== null) {
        GSTcharges = Math.ceil((Number(price) / 100) * Number(item.tax));
        price = Math.ceil(
          (Number(price) / 100) * Number(item.tax) + Number(price)
        );
      }
      total += parseFloat(price * item.quantity);
      AllGSTCharges += GSTcharges;
      return total;
    });
    settotalGSTCharges(Number(AllGSTCharges).toFixed(2));
    settotalPrice(
      (Number(total) + Number(shipping_estimate.shipping_price || 0)).toFixed(2)
    );
    setwithoutShippingPrice(Number(total).toFixed(2));
    if (cart.length === 0) {
      settotalGSTCharges(0);
      settotalPrice(0);
    }
  };

  const getPaymentSetting = async (id) => {
    await getPaymentSettingApis(id)
      .then(async (res) => {
        const { data, status } = res;
        if (status === 200) {
          await setcodeAvailable(data.payment_setting.cod_enable);
          await setMinCodPrice(data.payment_setting.cod_minimum_price_limit);
          await setMaxCodPrice(data.payment_setting.cod_max_price_limit);
          await setrazorPayKeyID(data.payment_setting.razor_pay_key_id);
        }
      })
      .catch();
  };

  useEffect(() => {
    getPaymentSetting();
    if (buyNow) {
      calculateTotalPrice(buyNow);
    } else {
      calculateTotalPrice(cart);
    }
    let tempOrderDetails = localStorage.getItem("finalOrderDetails");
    tempOrderDetails = JSON.parse(tempOrderDetails);
    SetorderDetails(tempOrderDetails);
    if (buyNow) {
      shippingEstimate(buyNow, tempOrderDetails.delivery_address.zip);
    } else {
      shippingEstimate(cart, tempOrderDetails?.delivery_address?.zip);
    }
  }, [cart, totalPrice, buyNow]);

  /** Change the quantity of cart items */
  const quantityChange = (text, id, index) => {
    let data = cart[index];
    if (text === "add") {
      let value = data.quantity + 1;
      setQuantityAlert("");
      if (value > data.maximum_qty_per_order) {
        value = data.maximum_qty_per_order;
        setQuantityAlert(
          `Only ${data.maximum_qty_per_order} item per customer.`
        );
      }
      data = { ...data, quantity: value };

      const index = cart.findIndex((data) => data.id === id);
      cart[index] = data;
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
    } else {
      let value;
      if (data.quantity > 1) {
        value = data.quantity - 1;
        data = { ...data, quantity: value };
        const index = cart.findIndex((data) => data.id === id);
        cart[index] = data;
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
      }
      // changeQuantity({id,value})
    }
    if (user) {
      dispatch(
        globalAction(UPDATE_QTY_ADD_TO_CART_INTO_DB, {
          data: data,
          userId: user.id,
        })
      );
      // console.log({data:data,userId:user.id})
    }
    calculateTotalPrice(cart);
  };

  const quantityChangeForBuyNow = (text, id, index) => {
    let data = buyNow[index];
    if (text === "add") {
      let value = data.quantity + 1;
      data = { ...data, quantity: value };
      const index = buyNow.findIndex((data) => data.id === id);
      buyNow[index] = data;
      localStorage.setItem("buyNow", JSON.stringify(buyNow));
    } else {
      let value;
      if (data.quantity > 1) {
        value = data.quantity - 1;
        data = { ...data, quantity: value };
        const index = buyNow.findIndex((data) => data.id === id);
        buyNow[index] = data;
        localStorage.setItem("buyNow", JSON.stringify(buyNow));
      }
      // changeQuantity({id,value})
    }
    calculateTotalPrice(buyNow);
  };

  /** Delete Items in cart */
  const DeleteCardItems = (id) => {
    let index = cart.findIndex(function (el) {
      return el.id === id;
    });
    if (index !== -1) {
      if (user) {
        dispatch(
          globalAction(DELETE_ADD_TO_CART_FROM_DB, {
            data: cart[index],
            userId: user.id,
          })
        );
      }
      cart.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
      const data = {
        msg: `${i18next.t("Item successfully deleted in your cart")}`,
        status: "success",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const handleAddressEdit = (e, item) => {
    localStorage.setItem("UpdateAddress", JSON.stringify(item));
  };

  useEffect(() => {
    if (buyNow) {
      calculateTotalPrice(buyNow);
    } else {
      calculateTotalPrice(cart);
    }
  }, [visibleMessage, isLoading]);

  const placedOrder = () => {
    let fCart = buyNow ? buyNow : cart;
    console.log(fCart);
    if (paymentOption == null) {
      const data = {
        msg: `${i18next.t("Selected Payment Option")}`,
        status: "waring",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
      setTimeout(function () {
        dispatch(globalAction(REMOVE_MESSAGE));
      }, 2000);
    }
    if (paymentOption === "cod") {
      if (fCart.length > 0) {
        let order_product = [];
        fCart.forEach((item) => {
          const { id, quantity, sale_price, regular_price } = item;
          let price = item.regular_price;
          if (item.sale_price !== undefined) {
            price = item.sale_price;
          }
          order_product.push({
            product_id: id,
            qty: quantity,
            single_price: sale_price !== null ? sale_price : regular_price,
            total_price: Number(price) * Number(quantity),
          });
        });

        let addOrder = {
          user_id: user !== null ? user.id : null,
          order_duration: 5,
          order_quantity: fCart.length,
          order_price: Math.ceil(Number(totalPrice)).toFixed(2),
          payment_type: paymentOption,
          order_status: "pending",
          shipping_addess:
            orderDetails.delivery_address.id !== undefined
              ? orderDetails.delivery_address.id
              : Number(localStorage.getItem("shippingId")),
          billing_address:
            orderDetails.billing_address.id !== undefined
              ? orderDetails.billing_address.id
              : Number(localStorage.getItem("billingId")),
          gst_billing: orderDetails.isGSTBill,
          gst_number: orderDetails.gstInfo.gst_number,
          company_name: orderDetails.gstInfo.company_name,
          company_address: orderDetails.gstInfo.company_address,
          order_product,
          shiping_fee: shipping_estimate?.shipping_price,
          tax_fee: Math.ceil(Number(totalGSTCharges)).toFixed(2),
        };
        if (user === null) {
          addOrder.email = orderDetails.email;
          addOrder.name = orderDetails.name;
          addOrder.mobile = orderDetails.mobile;
          addOrder.user_type = orderDetails.userType;
        }

        dispatch(globalAction(SHOW_LOADING));
        console.log(addOrder);
        addCodOrderApi(addOrder).then((res) => {
          const { data, status } = res;
          if (status === 200) {
            if (buyNow) {
              localStorage.removeItem("buyNow");
            } else {
              let cart = [];
              localStorage.setItem("cart", JSON.stringify(cart));
              dispatch(globalAction(ADD_TO_CART, cart));
            }
            dispatch(globalAction(SHOW_TOAST, { data }));
            localStorage.removeItem("finalOrderDetails");
            dispatch(globalAction(CLOSE_LOADING));
            setpaymentOption("");
            localStorage.removeItem("billingId");
            localStorage.removeItem("shippingId");
            history.push("/order-success");
          } else {
            dispatch(globalAction(SHOW_TOAST, { data }));
            dispatch(globalAction(CLOSE_LOADING));
          }
        });
      }
    }
    if (paymentOption === "online_payment" && razorPayKeyID !== undefined) {
      if (fCart.length > 0) {
        let order_product = [];
        fCart.forEach((item) => {
          const { id, quantity, sale_price, regular_price } = item;
          let price = item.regular_price;
          if (item.sale_price !== null) {
            price = item.sale_price;
          }

          order_product.push({
            product_id: id,
            qty: quantity,
            single_price: sale_price !== null ? sale_price : regular_price,
            total_price: Number(price) * Number(quantity),
          });
        });

        let addOrder = {
          user_id: user !== null ? user.id : null,
          order_duration: 5,
          order_quantity: fCart.length,
          order_price: Math.ceil(Number(totalPrice)).toFixed(2),
          payment_type: paymentOption,
          order_status: "pending",
          shipping_addess:
            orderDetails.delivery_address.id !== undefined
              ? orderDetails.delivery_address.id
              : Number(localStorage.getItem("shippingId")),
          billing_address:
            orderDetails.billing_address.id !== undefined
              ? orderDetails.billing_address.id
              : Number(localStorage.getItem("billingId")),
          gst_billing: orderDetails.isGSTBill,
          gst_number: orderDetails.gstInfo.gst_number,
          company_name: orderDetails.gstInfo.company_name,
          company_address: orderDetails.gstInfo.company_address,
          transaction_id: null,
          order_product,
          shiping_fee: shipping_estimate?.shipping_price,
          tax_fee: Math.ceil(Number(totalGSTCharges)).toFixed(2),
        };

        if (user === null) {
          addOrder.email = orderDetails.email;
          addOrder.name = orderDetails.name;
          addOrder.mobile = orderDetails.mobile;
          addOrder.user_type = orderDetails.userType;
        }

        console.log("Razorpay Key ID:", razorPayKeyID);
        console.log("Add Order:", addOrder);

        // For Online Payment
        var options = {
          key: razorPayKeyID,
          amount: Number(totalPrice) * 100,
          name: addOrder.billing_address.full_name || "",
          description: `${i18next.t("Thankyou! For Order")}`,
          image: { Logo },
          // Uncomment and implement the handler to handle the payment success
          // handler: function (response) {
          //   console.log('Payment Successful:', response);
          //   addOrder.transaction_id = response.razorpay_payment_id;
          //   // Dispatch actions or make API calls here
          // },
          theme: {
            color: "#3399cc",
          },
        };

        console.log("Razorpay Options:", options);

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          console.error("Payment Failed:", response.error);
          alert(response.error.code);
          const data = {
            msg: `${response.error.code}-${response.error.description}`,
            status: "warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          setTimeout(function () {
            dispatch(globalAction(REMOVE_MESSAGE));
          }, 2000);
        });

        rzp1.open();
      }
    }
  };

  return (
    <div className="choose-address-wrap review-main-wrap">
      {isLoading && <AnimatedLogo />}
      <div className="container">
        <div className="cart-page-wrap">
          <Container>
            <Row>
              <Col
                md={{ span: 12, order: 2 }}
                lg={{ span: 8, order: 1 }}
                className="left-col"
              >
                <div className="ca-top-section os-top-section">
                  <h1>
                    {" "}
                    {`${i18next.t(
                      "Review Your Delivery, Biliing, GST, & Orders."
                    )}`}
                  </h1>
                  <p>
                    {`${i18next.t(
                      "By placing your order, you agree to bellstone policies and agreements"
                    )}`}
                  </p>
                </div>

                <div className="review-order-wrap-outer ">
                  {orderDetails !== undefined && (
                    <div className="review-order-wrap">
                      <div className="row-top-section">
                        <div className="address-edit-remove os-1">
                          <Link
                            to="/update-address"
                            onClick={(e) => {
                              handleAddressEdit(
                                e,
                                orderDetails && orderDetails.delivery_address
                              );
                            }}
                          >
                            <span>
                              <FaEdit /> {`${i18next.t("Edit")}`}
                            </span>
                          </Link>
                        </div>
                        <h4>{`${i18next.t("Delivery Address")}`}</h4>
                        <h5>
                          {orderDetails &&
                            orderDetails.delivery_address.full_name}
                        </h5>
                        <p>
                          {orderDetails &&
                            orderDetails.delivery_address.address_line_1}
                        </p>
                        <p className="country">
                          {orderDetails?.delivery_address.state_data !==
                          undefined
                            ? orderDetails?.delivery_address.state_data.name
                            : orderDetails?.delivery_address.state}{" "}
                          {orderDetails.delivery_address.zip}
                        </p>
                        <p>
                          {orderDetails?.delivery_address.country_data !==
                          undefined
                            ? orderDetails?.delivery_address.country_data.name
                            : orderDetails?.delivery_address.country}
                        </p>
                        <p>
                          {`${i18next.t("Phone:")}`} +
                          {orderDetails.delivery_address.country_data !==
                          undefined
                            ? orderDetails.delivery_address.country_data
                                .phonecode
                            : ""}
                          -{orderDetails.delivery_address.mobile_no}
                        </p>
                      </div>
                      <div className="row-top-section billing">
                        <h4>{`${i18next.t("Billing Address")}`}</h4>
                        <h5>{orderDetails.billing_address.full_name}</h5>
                        <p>{orderDetails.billing_address.address_line_1}</p>
                        <p className="country">
                          {orderDetails.billing_address.state_data !== undefined
                            ? orderDetails.billing_address.state_data.name
                            : orderDetails.billing_address.state}{" "}
                          {orderDetails.billing_address.zip}
                        </p>
                        <p>
                          {orderDetails.billing_address.country_data !==
                          undefined
                            ? orderDetails.billing_address.country_data.name
                            : orderDetails.billing_address.country}
                        </p>
                        <p>
                          {`${i18next.t("Phone:")}`} +
                          {orderDetails.billing_address.country_data !==
                          undefined
                            ? orderDetails.billing_address.country_data
                                .phonecode
                            : ""}
                          -{orderDetails.billing_address.mobile_no}
                        </p>
                      </div>
                      {orderDetails.isGSTBill === "yes" && (
                        <div className="row-top-section gst-info">
                          <h4>GST {`${i18next.t("Billing Details")}`}</h4>
                          <h5>GST No : {orderDetails.gstInfo.gst_number}</h5>
                          <p>{orderDetails.gstInfo.company_name}</p>
                          <p>{orderDetails.gstInfo.company_address}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="ca-top-section os-top-section middle-section">
                  <h1>{`${i18next.t("Orders")}`}</h1>
                </div>
                <div className="review-order-wrap-cart">
                  {buyNow
                    ? buyNow.map((item, i) => {
                        return (
                          <div className="sc-item-wrap">
                            <div className="sc-item-row">
                              <div className="sc-item-col cm-col-2">
                                <div className="col-name">
                                  <h6>{`${i18next.t("Name")}`}</h6>
                                </div>
                                <div className="sc-item-info">
                                  <div className="sc-item-img">
                                    <Link
                                      to={`/${item.category.slug}/${
                                        item.subcategory.slug
                                      }/${
                                        item.slug !== null ? item.slug : item.id
                                      }`}
                                    >
                                      <img
                                        src={item.image_url}
                                        alt={item.seo_title}
                                      />
                                    </Link>
                                  </div>

                                  <div className="info">
                                    <Link
                                      to={`/${item.category.slug}/${
                                        item.subcategory.slug
                                      }/${
                                        item.slug !== null ? item.slug : item.id
                                      }`}
                                    >
                                      <h6>{item.title}</h6>
                                    </Link>
                                    <p>
                                      {`${i18next.t("Product id")}`} :{" "}
                                      <span>{item.id}</span>
                                    </p>
                                    <p>
                                      {`${i18next.t("Unit Price")}`} :{" "}
                                      <span>
                                        <BiRupee />
                                        {item.sale_price !== null
                                          ? item.sale_price
                                          : item.regular_price}
                                      </span>
                                    </p>
                                    {item.tax && (
                                      <p className="gst-price-product-page">
                                        {" "}
                                        GST {`${i18next.t("Price")}`}:
                                        <span>
                                          <BiRupee />
                                          {item.sale_price !== null
                                            ? Math.ceil(
                                                (Number(item.sale_price) /
                                                  100) *
                                                  Number(item.tax) +
                                                  Number(item.sale_price)
                                              ).toFixed(2)
                                            : Math.ceil(
                                                (Number(item.regular_price) /
                                                  100) *
                                                  Number(item.tax) +
                                                  Number(item.regular_price)
                                              ).toFixed(2)}
                                        </span>
                                        <span className="gst-span-product-page">{`(include ${item.tax}% GST)`}</span>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="sc-item-col cm-col-1">
                                <div className="sc-item-inner-col">
                                  <div className="col-name">
                                    <h6>QTY</h6>
                                  </div>
                                  <div className="si-qty-count">
                                    <span
                                      className="minus"
                                      onClick={() => {
                                        if (
                                          item.minimum_qty_per_order <
                                          item.quantity
                                        ) {
                                          quantityChangeForBuyNow(
                                            "sub",
                                            item.id,
                                            i
                                          );
                                        }
                                      }}
                                    >
                                      <FaMinus />
                                    </span>
                                    <span className="count">
                                      {item.quantity}
                                    </span>
                                    <span
                                      className="plus"
                                      onClick={() => {
                                        if (
                                          item.maximum_qty_per_order === null ||
                                          item.maximum_qty_per_order >
                                            item.quantity
                                        ) {
                                          quantityChangeForBuyNow(
                                            "add",
                                            item.id,
                                            i
                                          );
                                        }
                                      }}
                                    >
                                      <FaPlus />
                                    </span>
                                  </div>

                                  {item.minimum_qty_per_order >=
                                    item.quantity && (
                                    <p className="red-alert-text">
                                      {`${i18next.t("Minimum")}`}{" "}
                                      {item.minimum_qty_per_order}{" "}
                                      {`${i18next.t("per QTY")}`}
                                    </p>
                                  )}
                                  {item.maximum_qty_per_order &&
                                    item.maximum_qty_per_order <=
                                      item.quantity && (
                                      <p className="red-alert-text">
                                        {`${i18next.t("Maximum")}`}{" "}
                                        {item.maximum_qty_per_order}{" "}
                                        {`${i18next.t("per QTY")}`}
                                      </p>
                                    )}
                                </div>
                                <div className="sc-item-inner-col">
                                  <div className="col-name">
                                    <h6>{`${i18next.t("ITEM TOTAL")}`}</h6>
                                  </div>
                                  {item.sale_price !== null ? (
                                    <p>
                                      <span>
                                        <BiRupee />
                                        {(
                                          Math.ceil(
                                            (Number(item.sale_price) / 100) *
                                              Number(item.tax) +
                                              Number(item.sale_price)
                                          ) * Number(item.quantity)
                                        ).toFixed(2)}
                                      </span>
                                    </p>
                                  ) : (
                                    <p>
                                      <span>
                                        <BiRupee />
                                        {(
                                          Math.ceil(
                                            (Number(item.sale_price) / 100) *
                                              Number(item.tax) +
                                              Number(item.sale_price)
                                          ) * Number(item.quantity)
                                        ).toFixed(2)}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="sc-item-inner-col">
                                  <div className="col-name">
                                    <h6>{`${i18next.t("REMOVE")}`}</h6>
                                  </div>
                                  <div className="eelete-icon">
                                    <span
                                      className="itemDelete"
                                      onClick={() => {
                                        DeleteCardItems(item.id);
                                      }}
                                    >
                                      <RiDeleteBin2Line />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : cart.length > 0
                    ? cart.map((item, i) => {
                        return (
                          <div className="sc-item-wrap">
                            <div className="sc-item-row">
                              <div className="sc-item-col cm-col-2">
                                <div className="col-name">
                                  <h6>{`${i18next.t("Name")}`}</h6>
                                </div>
                                <div className="sc-item-info">
                                  <div className="sc-item-img">
                                    <Link
                                      to={`/${item.category.slug}/${
                                        item.subcategory.slug
                                      }/${
                                        item.slug !== null ? item.slug : item.id
                                      }`}
                                    >
                                      <img
                                        src={item.image_url}
                                        alt={item.seo_title}
                                      />
                                    </Link>
                                  </div>

                                  <div className="info">
                                    <Link
                                      to={`/${item.category.slug}/${
                                        item.subcategory.slug
                                      }/${
                                        item.slug !== null ? item.slug : item.id
                                      }`}
                                    >
                                      <h6>{item.title}</h6>
                                    </Link>
                                    <p>
                                      {`${i18next.t("Product id")}`} :{" "}
                                      <span>{item.id}</span>
                                    </p>
                                    <p>
                                      {`${i18next.t("Unit Price")}`} :{" "}
                                      <span>
                                        <BiRupee />
                                        {item.sale_price !== null
                                          ? item.sale_price
                                          : item.regular_price}
                                      </span>
                                    </p>
                                    {item.tax && (
                                      <p className="gst-price-product-page">
                                        {" "}
                                        GST {`${i18next.t("Price")}`}:
                                        <span>
                                          <BiRupee />
                                          {item.sale_price !== null
                                            ? Math.ceil(
                                                (Number(item.sale_price) /
                                                  100) *
                                                  Number(item.tax) +
                                                  Number(item.sale_price)
                                              ).toFixed(2)
                                            : Math.ceil(
                                                (Number(item.regular_price) /
                                                  100) *
                                                  Number(item.tax) +
                                                  Number(item.regular_price)
                                              ).toFixed(2)}
                                        </span>
                                        <span className="gst-span-product-page">{`(include ${item.tax}% GST)`}</span>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="sc-item-col cm-col-1">
                                <div className="sc-item-inner-col">
                                  <div className="col-name">
                                    <h6>QTY</h6>
                                  </div>
                                  <div className="si-qty-count">
                                    <span
                                      className="minus"
                                      onClick={() => {
                                        if (
                                          item.minimum_qty_per_order <
                                          item.quantity
                                        ) {
                                          quantityChange("sub", item.id, i);
                                        }
                                      }}
                                    >
                                      <FaMinus />
                                    </span>
                                    <span className="count">
                                      {item.quantity}
                                    </span>
                                    <span
                                      className="plus"
                                      onClick={() => {
                                        if (
                                          item.maximum_qty_per_order === null ||
                                          item.maximum_qty_per_order >
                                            item.quantity
                                        ) {
                                          quantityChange("add", item.id, i);
                                        }
                                      }}
                                    >
                                      <FaPlus />
                                    </span>
                                  </div>

                                  {item.minimum_qty_per_order >=
                                    item.quantity && (
                                    <p className="red-alert-text">
                                      {`${i18next.t("Minimum")}`} $
                                      {item.minimum_qty_per_order}{" "}
                                      {`${i18next.t("per QTY")}`}
                                    </p>
                                  )}
                                  {item.maximum_qty_per_order &&
                                    item.maximum_qty_per_order <=
                                      item.quantity && (
                                      <p className="red-alert-text">
                                        {`${i18next.t("Maximum")}`} $
                                        {item.maximum_qty_per_order}{" "}
                                        {`${i18next.t("per QTY")}`}
                                      </p>
                                    )}
                                </div>
                                <div className="sc-item-inner-col">
                                  <div className="col-name">
                                    <h6>{`${i18next.t("ITEM TOTAL")}`}</h6>
                                  </div>
                                  {item.sale_price !== null ? (
                                    <p>
                                      <span>
                                        <BiRupee />
                                        {(
                                          Math.ceil(
                                            (Number(item.sale_price) / 100) *
                                              Number(item.tax) +
                                              Number(item.sale_price)
                                          ) * Number(item.quantity)
                                        ).toFixed(2)}
                                      </span>
                                    </p>
                                  ) : (
                                    <p>
                                      <span>
                                        <BiRupee />
                                        {(
                                          Math.ceil(
                                            (Number(item.sale_price) / 100) *
                                              Number(item.tax) +
                                              Number(item.sale_price)
                                          ) * Number(item.quantity)
                                        ).toFixed(2)}
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="sc-item-inner-col">
                                  <div className="col-name">
                                    <h6>{`${i18next.t("REMOVE")}`}</h6>
                                  </div>
                                  <div className="eelete-icon">
                                    <span
                                      className="itemDelete"
                                      onClick={() => {
                                        DeleteCardItems(item.id);
                                      }}
                                    >
                                      <RiDeleteBin2Line />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </Col>
              <Col
                md={{ span: 12, order: 1 }}
                lg={{ span: 4, order: 1 }}
                className="right-col"
              >
                <div className="order-summary-wrap row-wrap">
                  <div className="item">
                    <h5>
                      {`${i18next.t("Order Summary")}`}{" "}
                      <span>
                        ({cart.length} {`${i18next.t("Items")}`})
                      </span>
                    </h5>
                  </div>
                  <div className="subtotal">
                    <p>{`${i18next.t("Subtotal:")}`}</p>
                    <p>
                      <span>
                        <BiRupee /> {withoutShippingPrice}
                      </span>
                    </p>
                  </div>
                  {shipping_estimate ? (
                    <>
                      <div className="subtotal">
                        <p>{`${i18next.t("Shipping price:")}`}</p>
                        <p>
                          <span>
                            <BiRupee /> {shipping_estimate.shipping_price}
                          </span>
                        </p>
                      </div>
                      <div className="subtotal">
                        <p>{`${i18next.t("Est. shipping date:")}`}</p>
                        <p>
                          <span>{shipping_estimate.delivery_estimate}</span>
                        </p>
                      </div>
                    </>
                  ) : (
                    <div className="subtotal">
                      <p className="cm-warn">{shipping_estimate.msg}</p>
                    </div>
                  )}

                  <div className="total">
                    <h4>{`${i18next.t("Order Total:")}`}</h4>
                    <h4>
                      <span>
                        <BiRupee />
                        {Math.ceil(Number(totalPrice)).toFixed(2)}
                      </span>
                    </h4>
                  </div>
                  <div className="hr-line">
                    <hr />
                  </div>
                  <div className="payment-options-wrap">
                    {MinCodPrice < totalPrice + Number(totalGSTCharges) &&
                      maxCodPrice > totalPrice &&
                      codeAvailable === "yes" && (
                        <div className="po-item">
                          <div className="po-inner">
                            <input
                              type="radio"
                              id="payment_options"
                              name="payment_options"
                              value="cod"
                              onChange={(e) => setpaymentOption(e.target.value)}
                            />
                            <h5>{`${i18next.t("COD (Cash On Delivery)")}`}</h5>
                          </div>
                        </div>
                      )}

                    <div className="po-item">
                      <div className="po-inner">
                        <input
                          type="radio"
                          id="payment_options"
                          name="payment_options"
                          value="online_payment"
                          onChange={(e) => setpaymentOption(e.target.value)}
                        />
                        <h5>{`${i18next.t(
                          "Credit Card / Debit Card / Net Banking / UPI"
                        )}`}</h5>
                      </div>
                    </div>
                  </div>
                  {shipping_estimate.status ? (
                    buyNow ? (
                      <div class="checkout">
                        {buyNow.length > 0 && (
                          <button
                            onClick={(e) => {
                              placedOrder(e, paymentOption);
                            }}
                          >
                            {`${i18next.t("Pay & Placed Order")}`}
                          </button>
                        )}
                      </div>
                    ) : (
                      <div class="checkout">
                        {cart.length > 0 && (
                          <button
                            onClick={(e) => {
                              placedOrder(e, paymentOption);
                            }}
                          >
                            {`${i18next.t("Pay & Placed Order")}`}
                          </button>
                        )}
                      </div>
                    )
                  ) : (
                    //   <div class="checkout">
                    //   {cart.length>0
                    //   &&
                    //   <button
                    //     onClick={(e) => {
                    //       placedOrder(e, paymentOption);
                    //     }}
                    //   >
                    //     Pay & Placed Order
                    //   </button>
                    //   }

                    // </div>

                    <div class="checkout danger">
                      <button disabled="true">
                        {`${i18next.t("Not Deliverable")}`}
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default withRouter(OrderSummary);
