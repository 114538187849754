export const english = {
    translation: {
        //home page menu
        Welcome_to_React: 'Welcome to English',
        'All Categories': 'All Categories',
        'Electronics': 'Electronics',
        'View All': 'View All',
        'All': 'All',
        'Best Seller': 'Best Seller',
        'Brands': 'Brands',
        'Brand': 'Brand',
        'Regular Price': 'Regular Price',
        'Special Price': 'Special Price',
        'Price': 'Price',
        'Latest Product': 'Latest Product',
        'Shop order': 'Shop order',
        'Sorting': 'Sorting',
        'Show': 'Show',
        'CUSTOMER SERVICES': 'CUSTOMER SERVICES',
        'Contact Us': 'Contact Us',
        'Low': 'Low',
        'High': 'High',
        'Review': 'Review',
        'OFF': 'OFF',
        'sale': 'sale',
        'Bulb Technology: Halogen': 'Bulb Technology: Halogen',
        'Bulb Color: Clear': 'Bulb Color: Clear',
        'Package Quantity': 'Package Quantity',
        'Power: 40 MW': 'Power: 40 MW',
        'Voltage: 12 VDC': 'Voltage: 12 VDC',
        'Add to cart': 'Add to cart',
        'Add to wishlist': 'Add to wishlist',
        'No Products Found...!': 'No Products Found...!',
        'Dashboard': 'Dashboard',
        'Logout': 'Logout',
        'Sign In': 'Sign In',
        'Wishlist': 'Wishlist',
        'Minimum': 'Minimum',
        'Maximum': 'Maximum',
        'per QTY': 'per QTY',
        'Your is cart empty': 'Your is cart empty',
        'View Cart': 'View Cart',
        'Main Menu': 'Main Menu',
        'Home': 'Home',
        'About Us': 'About Us',
        'Blog': 'Blog',
        'Blogs': 'Blogs',
        'Contact us': 'Contact us',
        'Category': 'Category',
        'Account': 'Account',
        'Cart': 'Cart',
        'Search product by title,supplier': 'Search product by title,supplier',
        'Privacy Policy': 'Privacy Policy',
        'Delivery Information': 'Delivery Information',
        'Terms & Conditions': 'Terms & Conditions',
        'Returns & Refunds': 'Returns & Refunds',
        'Payment Methods': 'Payment Methods',
        'Sell On Bellstone': 'Sell On Bellstone',
        'Bulk Order': 'Bulk Order',
        'Offline Business': 'Offline Business',
        'Important Links': 'Important Links',
        'News & Events': 'News & Events',
        'Top Categories': 'Top Categories',
        'Top Brands': 'Top Brands',
        'Newsletter': 'Newsletter',
        'Subscribe Our newsletter to get our latest update & news': 'Subscribe Our newsletter to get our latest update & news',
        'Please login or Register to save this product.': 'Please login or Register to save this product.',
        'are successfully added in your cart': 'are successfully added in your cart',
        'Please login or register for add review!': 'Please login or register for add review!',
        'Share Your Thoughts With Other Customers': 'Share Your Thoughts With Other Customers',
        'CUSTOMER REVIEWS': 'CUSTOMER REVIEWS',
        'Customer Reviews on': 'Customer Reviews on',
        'Average rating based on': 'Average rating based on',
        'ratings': 'ratings',
        'Enter you comment': 'Enter you comment',
        'Rate the Product': 'Rate the Product',
        'Add Review': 'Add Review',
        'Top Customer Reviews': 'Top Customer Reviews',
        'FILTER BY': 'FILTER BY',
        'Start Rating': 'Start Rating',
        'Verified User': 'Verified User',
        '0 of 0 users found this review helpful. Was this review helpful?': '0 of 0 users found this review helpful. Was this review helpful?',
        'No reviews.': 'No reviews.',
        'Best Brands': 'Best Brands',
        'Best Deals On': 'Best Deals On',
        'New Products': 'New Products',
        'Most Popular Products': 'Most Popular Products',
        'Best Selling Products': 'Best Selling Products',
        'BEST SELLING': 'BEST SELLING',
        'already added in your cart': 'already added in your cart',
        'Items Available': 'Items Available',
        'Related Products': 'Related Products',
        '404 Not Found Page': '404 Not Found Page',
        'Already added in compare list': 'Already added in compare list',
        'Compare Products': 'Compare Products',
        'by': 'by',
        'Reviews': 'Reviews',
        'Weight': 'Weight',
        'Description': 'Description',
        'Additional Information': 'Additional Information',
        'Out of Stock': 'Out of Stock',
        'Add product to compare': 'Add product to compare',
        'Search': 'Search',
        'No product found..!': 'No product found..!',
        'Lets Compare': 'Lets Compare',
        'Load more posts': 'Load more posts',
        'No blog found in this category': 'No blog found in this category',
        'Categories': 'Categories',
        'Most Popular': 'Most Popular',
        'Share': 'Share',
        'Blog Detail': 'Blog Detail',
        'Author': 'Author',
        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy',
        'Comment': 'Comment',
        'Reply Comments': 'Reply Comments',
        'Please enter your comments.': 'Please enter your comments.',
        'Post': 'Post',
        'Recent Post': 'Recent Post',
        'Search Keywoards': 'Search Keywoards',
        'Update Address': 'Update Address',
        'Full Name': 'Full Name',
        'Mobile': 'Mobile',
        'Address Line': 'Address Line',
        'Country': 'Country',
        'Select Country': 'Select Country',
        'State': 'State',
        'Select State': 'Select State',
        'Zip': 'Zip',
        'Would you like to make this address default for future orders.?': 'Would you like to make this address default for future orders.?',
        'Yes, I would like to receive tracking information via SMS (SMS and data rates may apply).': 'Yes, I would like to receive tracking information via SMS (SMS and data rates may apply).',
        'Update and Save': 'Update and Save',
        'Terms and Conditions': 'Terms and Conditions',
        'TERMS & CONDITIONS OF SALE:(BETWEEN SELLER AND THE CUSTOMER)': 'TERMS & CONDITIONS OF SALE:(BETWEEN SELLER AND THE CUSTOMER)',
        'Last updated on': 'Last updated on',
        'Email': 'Email',
        'Please fill up the all fields.': 'Please fill up the all fields.',
        'Welcome': 'Welcome',
        'Back!': 'Back!',
        'Please Sign In to your account.': 'Please Sign In to your account.',
        'Please enter your valid email.': 'Please enter your valid email.',
        'Password': 'Password',
        'Please enter your password.': 'Please enter your password.',
        'Forget Password?': 'Forget Password?',
        'Log In Now': 'Log In Now',
        'OR Login with': 'OR Login with',
        "Don't Have account?": "Don't Have account?",
        "Signup!": "Signup!",
        "Verification": "Verification",
        "required": "required",
        "Forget": "Forget",
        "To continue, complete this verification step. We've sent an OTP to the email": "To continue, complete this verification step. We've sent an OTP to the email",
        "Please enter it below to complete verification.": "Please enter it below to complete verification.",
        "Enter your registered email": "Enter your registered email",
        "Send": "Send",
        "Enter one time password": "Enter one time password",
        "Enter new password": "Enter new password",
        "Confirm new password": "Confirm new password",
        "Reset Password": "Reset Password",
        "Already have an account?": "Already have an account?",
        "Login": "Login",
        "Register": "Register",
        "Please enter repeate password.": "Please enter repeate password.",
        "Please enter password.": "Please enter password.",
        "Please enter contact number.": "Please enter contact number.",
        "Please enter valid email.": "Please enter valid email.",
        "Please enter your name.": "Please enter your name.",
        "Create your account here.": "Create your account here.",
        "Here": "Here",
        "Full name": "Full name",
        "Repeat Password": "Repeat Password",
        "Your order is complete !": "Your order is complete !",
        "Your will be receiving a conformation email with order detail": "Your will be receiving a conformation email with order detail",
        "we will redirect to homepage in": "we will redirect to homepage in",
        "Back to home": "Back to home",
        "View orders": "View orders",
        "Add Shipping Address": "Add Shipping Address",
        "Bellstone does not ship to PO Box, mailbox, and APO/FPO addresses.": "Bellstone does not ship to PO Box, mailbox, and APO/FPO addresses.",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "Contact Number": "Contact Number",
        "Address": "Address",
        "City": "City",
        "Submit": "Submit",
        "Order Summary": "Order Summary",
        "Items": "Items",
        "Subtotal:": "Subtotal:",
        "Est. Shipping:": "Est. Shipping:",
        "Order Total:": "Order Total:",
        "Place Order": "Place Order",
        "RETURN POLICY": "RETURN POLICY",
        "Please fill up the all fields.": "Please fill up the all fields.",
        "Feedback Form": "Feedback Form",
        "Give your valuable feedback about our products which helps to our other customers.": "Give your valuable feedback about our products which helps to our other customers.",
        "Select Sub Category": "Select Sub Category",
        "Select product & give your feedback.": "Select product & give your feedback.",
        "Products Not Found": "Products Not Found",
        "Add your valuable feedback.": "Add your valuable feedback.",
        "Next": "Next",
        "Feedback": "Feedback",
        "Rating": "Rating",
        "Name": "Name",
        "Prev": "Prev",
        "Submitting..": "Submitting..",
        "Enter your fullname": "Enter your fullname",
        "Enter your email": "Enter your email",
        "Enter your mobile number": "Enter your mobile number",
        "write your feedback": "write your feedback",
        "GET BULK QUOTE NOW": "GET BULK QUOTE NOW",
        "Want to buy even more quantity?": "Want to buy even more quantity?",
        "Shipping charge applicable as per serviceability": "Shipping charge applicable as per serviceability",
        "Check": "Check",
        "Check delivery and payment options ?": "Check delivery and payment options ?",
        "Area PIN Code": "Area PIN Code",
        "Out Of Stock": "Out Of Stock",
        "Add to wishlist": "Add to wishlist",
        "Buy Now": "Buy Now",
        "Send Enquiry": "Send Enquiry",
        "Add to Compare": "Add to Compare",
        "None": "None",
        "Model": "Model",
        "Brand": "Brand",
        "Weight": "Weight",
        "Color": "Color",
        "Inclusive Price:": "Inclusive Price:",
        "You can only compare similar product": "You can only compare similar product",
        "Item successfully deleted in your cart": "Item successfully deleted in your cart",
        "Already added in your cart with same quantity": "Already added in your cart with same quantity",
        "Please login or Register to save this product.": "Please login or Register to save this product.",
        "Payment Methods": "Payment Methods",
        "PAYMENT METHODS": "PAYMENT METHODS",
        "You need to verified your email account for first time to login your": "You need to verified your email account for first time to login your",
        "We already sent a verification code to your email": "We already sent a verification code to your email",
        "Verify your": "Verify your",
        "email": "email",
        "Verify": "Verify",
        "Required": "Required",
        "Not Deliverable": "Not Deliverable",
        "Pay & Placed Order": "Pay & Placed Order",
        "Credit Card / Debit Card / Net Banking / UPI": "Credit Card / Debit Card / Net Banking / UPI",
        "COD (Cash On Delivery)": "COD (Cash On Delivery)",
        "Order Total:": "Order Total:",
        "Est. shipping date:": "Est. shipping date:",
        "Shipping price:": "Shipping price:",
        "REMOVE": "REMOVE",
        "ITEM TOTAL": "ITEM TOTAL",
        "Unit Price": "Unit Price",
        "Product id": "Product id",
        "Orders": "Orders",
        "Billing Details": "Billing Details",
        "Phone:": "Phone:",
        "Billing Address": "Billing Address",
        "Delivery Address": "Delivery Address",
        "Selected Payment Option": "Selected Payment Option",
        "Thankyou! For Order": "Thankyou! For Order",
        "By placing your order, you agree to bellstone policies and agreements": "By placing your order, you agree to bellstone policies and agreements",
        "Review Your Delivery, Biliing, GST, & Orders.": "Review Your Delivery, Biliing, GST, & Orders.",
        "Edit": "Edit",
        "Price": "Price",
        "Fill Delivery Address": "Fill Delivery Address",
        "DELIVERY INFORMATION": "DELIVERY INFORMATION",
        "Delivery Information": "Delivery Information",
        "Message": "Message",
        "Email address": "Email address",
        "Subject": "Subject",
        "Get In Touch": "Get In Touch",
        "You email address will not be published. Required fields are marked": "You email address will not be published. Required fields are marked",
        "Store Location:": "Store Location:",
        "Call US:": "Call US:",
        "Contact With US!": "Contact With US!",
        "Contact us": "Contact us",
        "Enter Email address": "Enter Email address",
        "Would you like to make this address default for future orders.?": "Would you like to make this address default for future orders.?",
        "Company name": "Company name",
        "Company Address": "Company Address",
        "Save & Deliver to this address": "Save & Deliver to this address",
        "Add new Address": "Add new Address",
        "Remove": "Remove",
        "Selected for Billing": "Selected for Billing",
        "Continue": "Continue",
        "Enter valid Gst number": "Enter valid Gst number",
        "Shopping Cart": "Shopping Cart",
        "Shipping Update": "Shipping Update",
        "As major carriers like FedEx and UPS have acknowledged, unprecedented demand may cause 1-2 day shipping delays from the ETA given at checkout. We apologize for any inconvenience.": "As major carriers like FedEx and UPS have acknowledged, unprecedented demand may cause 1-2 day shipping delays from the ETA given at checkout. We apologize for any inconvenience.",
        "QTY": "QTY",
        "Your Cart Is Empty": "Your Cart Is Empty",
        "Proceed to checkout": "Proceed to checkout",
        "Add Products to checkout": "Add Products to checkout",
        "Key Features": "Key Features",
        "Cancel Order": "Cancel Order",
        "Are you sure to Cancel this order..?": "Are you sure to Cancel this order..?",
        "Cancellation Reason": "Cancellation Reason",
        "optional": "optional",
        "Cancel": "Cancel",
        "Cancel Order": "Cancel Order",    
        "Order Detail": "Order Detail",    
        "View/Download Invoice": "View/Download Invoice",    
        "Invoice": "Invoice",    
        "Track Order": "Track Order",    
        "Order Number": "Order Number",    
        "Date & Time": "Date & Time",    
        "Status": "Status",    
        "Payment Method": "Payment Method",    
        "Pay with cash upon delivery.": "Pay with cash upon delivery.",    
        "Order Details": "Order Details",    
        "Item": "Item",    
        "Price/Unit": "Price/Unit",    
        "Qty": "Qty",    
        "Total": "Total",    
        "HSN code": "HSN code",    
        "Including GST": "Including GST",    
        "Shipping Cost": "Shipping Cost",    
        "Gst Charges": "Gst Charges",    
        "Grand Total": "Grand Total",    
        "Shipping Address": "Shipping Address",    
        "Company": "Company",    
        "PostCode": "PostCode",    
        "Phone Number": "Phone Number",    
        "Fax Number": "Fax Number",    
        "Email Address": "Email Address",    
        "Company": "Company",    
        "GST Billing": "GST Billing",    
        "GST Number": "GST Number",    
        "Compnay Name": "Compnay Name",    
        "Company Addredd": "Company Addredd",    
        "Items Available": "Items Available",    
        "Keep me Log In": "Keep me Log In",    
        "User Dashboard": "User Dashboard",    
        "New Orders": "New Orders",    
        "Total Orders": "Total Orders",    
        "Saved Products": "Saved Products",    
        "Recent Orders": "Recent Orders",    
        "Order no": "Order no",    
        "Payment mode": "Payment mode",    
        "Order At": "Order At",    
        "Read more": "Read more",    
        "Dashboard": "Dashboard",    
        "My Account": "My Account",    
        "Saved Address": "Saved Address",    
        "My Order": "My Order",    
        "My Order": "My Order",    
        "Bulk Enquiry": "Bulk Enquiry",    
        "Contact Information": "Contact Information",    
        "Please enter your email.": "Please enter your email.",    
        "Please enter your contact number.": "Please enter your contact number.",    
        "Upload Profile": "Upload Profile",    
        "Change image": "Change image",    
        "Save": "Save",    
        "Current Password": "Current Password",    
        "Please enter your current password.": "Please enter your current password.",    
        "New Password": "New Password",    
        "Please enter your new password.": "Please enter your new password.",    
        "Confirm new Password": "Confirm new Password",    
        "Please enter your confirm password.": "Please enter your confirm password.",    
        "Selected for delivery": "Selected for delivery",    
        "Select delivery address": "Select delivery address",    
        "All Enquires": "All Enquires",    
        "Pending": "Pending",    
        "Enquiry Id": "Enquiry Id",    
        "Product quantity which you inquired ?": "Product quantity which you inquired ?",    
        "Pieces": "Pieces",    
        "Your initial Message is": "Your initial Message is",    
        "View Conversation": "View Conversation",    
        "Product Info": "Product Info",    
        "Delete Request": "Delete Request",    
        "No enquiries": "No enquiries",    
        "Out of stock": "Out of stock",    
        "By creating an account you agree to our Terms & Privacy": "By creating an account you agree to our Terms & Privacy",    
        "previous": "previous",    
        "next": "next",    
        "Quantity": "Quantity",    
        "Product Name": "Product Name",    
        "Product ID": "Product ID",    
        "Want to buy even more quantity ?": "Want to buy even more quantity ?",    
        "How many quanity you wanna buy, Enter here.": "How many quanity you wanna buy, Enter here.",    
        "Enter Mobile Number": "Enter Mobile Number",    
        "Enter email": "Enter email",    
        "Enter Full Name": "Enter Full Name",    
    }
};
