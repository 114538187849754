import React from 'react'
import './ChooseUserType.css'
import { Button, Modal } from 'react-bootstrap'
import { Link } from "react-router-dom";

function ChooseUserType(props) {
    const { isLogin, user, modalToggle, modalFlag, checkUserType } = props;
    return (
        <Modal
            show={modalFlag}
            onHide={() => modalToggle()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Proceed to checkout 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="choose-user-type-wrap">
                    <div className="user-type guest">
                        <div className="content">
                            
                            <Button onClick={() => { checkUserType(user,isLogin,'guest') }} >Guest</Button>
                            <h6>Continue as a guest</h6>
                           
                        </div>

                    </div>
                    <div className="user-type login">
                    <div className="content">
                            
                            <Button onClick={() => { checkUserType(user,isLogin,'user') }} >Login</Button>
                            <h6>With an exiting account.                        
                            </h6>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modalToggle}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChooseUserType
