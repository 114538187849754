import React, { useState, useEffect, useRef } from "react";
import "./header.scss";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {
  FaPhoneAlt,
  FaHeart,
  FaBars,
  FaTimes,
  FaUserAlt,
  FaShoppingCart,
  FaPlus,
  FaMinus,
} from "react-icons/fa";
import {
  AiOutlineMail,
  AiOutlineMenu,
  AiOutlineClose,
  AiFillHome,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BiRupee } from "react-icons/bi";

import Logo from "../../../assets/logo-white.png";
import Navigation from "../navigation/Navigation";
import { Link, Redirect, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../Toast/Toast";
import {
  CHANGE_LANG,
  GET_CATEGORY,
  GET_SEARCH_PRODUCT,
  globalAction,
  LOGOUT_USER,
  ADD_TO_CART,
  SHOW_TOAST,
  GET_LIST_OF_ADD_TO_CART_INTO_DB,
  UPDATE_QTY_ADD_TO_CART_INTO_DB,
  DELETE_ADD_TO_CART_FROM_DB,
  GET_SAVED_PRODUCTS,
  SET_BUY_NOW_ITEM,
} from "../../../redux";
import { ImCross, ImSad } from "react-icons/im";
import { RiDeleteBin2Line, RiShoppingBasket2Fill } from "react-icons/ri";
import { HeaderApi } from "../../../api";
import i18next from "../../Translation/index";

function Header({ history }) {
  const formRef = useRef();
  const [activeHeader, setactiveHeader] = useState(false);
  const [search, setSearch] = useState("");
  const [selected_category, setSelected_category] = useState("");
  const { status, message, visibleMessage } = useSelector(
    (state) => state.errorReducer
  );
  const { isLogin, user } = useSelector((state) => state.authReducer);
  const { categories, savedProducts } = useSelector(
    (state) => state.AppReducer
  );
  const { home, cart, currentLang } = useSelector((state) => state.AppReducer);
  const [activeCategory, setActiveCategory] = useState(false);
  const [totalPrice, settotalPrice] = useState(0);
  const { category } = home;
  const [showShopingCart, setShowShopingCart] = useState(false);
  const dispatch = useDispatch();
  const [headerLogo, setheaderLogo] = useState(undefined);

  const handleChange = () => {
    setactiveHeader(!activeHeader);
  };

  const calculateTotalPrice = (cart) => {
    let total = 0;
    if (cart.length > 0) {
      cart.map((item, i) => {
        let price;
        price = item.regular_price;
        if (item.sale_price !== null) {
          price = item.sale_price;
        }
        if (item.tax !== null) {
          price = Math.ceil(
            (Number(price) / 100) * Number(item.tax) + Number(price)
          );
        }
        total += parseFloat(price * item.quantity);
        return total;
      });

      settotalPrice(Number(total).toFixed(2));
    } else {
      settotalPrice(0);
    }
  };

  const quantityChange = (text, id, index) => {
    let data = cart[index];
    if (text === "add") {
      let value = data.quantity + 1;
      data = { ...data, quantity: value };
      const index = cart.findIndex((data) => data.id === id);
      cart[index] = data;
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
    } else {
      let value;
      if (data.quantity > 1) {
        value = data.quantity - 1;
        data = { ...data, quantity: value };
        const index = cart.findIndex((data) => data.id === id);
        cart[index] = data;
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
      }
    }
    if (user) {
      dispatch(
        globalAction(UPDATE_QTY_ADD_TO_CART_INTO_DB, {
          data: data,
          userId: user.id,
        })
      );
      // console.log({data:data,userId:user.id})
    }
    calculateTotalPrice(cart);
  };

  useEffect(() => {
    dispatch(globalAction(GET_CATEGORY));
    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
    HeaderApi().then((res) => {
      const { data, status } = res;
      if (status === 200) {
        setheaderLogo(data.header.logo);
      }
    });
    if (user) {
      dispatch(globalAction(GET_SAVED_PRODUCTS, { id: user.id }));
    }
  }, [dispatch]);

  useEffect(() => {
    calculateTotalPrice(cart);
  }, [visibleMessage]);

  useEffect(() => {
    calculateTotalPrice(cart);
  }, [cart]);

  const onSearch = (e) => {
    e.preventDefault();
    let param = "";
    console.log(search);
    if (selected_category !== "") {
      param += `?category_slug=${selected_category}&search=${search}&limit=24`;
    } else {
      param += `?search=${search}&limit=24&order=desc`;
    }
    localStorage.setItem("isSearchValid", "true");
    localStorage.setItem("searchQuery", search);
    dispatch(globalAction(GET_SEARCH_PRODUCT, { param, forword: history }));
    formRef.current.reset();
  };

  const toggleShoppingCart = () => {
    setShowShopingCart(!showShopingCart);
    calculateTotalPrice(cart);
    if (cart.length === 0) {
      setShowShopingCart(false);
    }
  };

  /** Delete Items in cart */
  const DeleteCardItems = (id) => {
    let index = cart.findIndex(function (el) {
      return el.id === id;
    });
    if (cart.length === 1) {
      toggleShoppingCart();
    }
    if (index !== -1) {
      if (user) {
        dispatch(
          globalAction(DELETE_ADD_TO_CART_FROM_DB, {
            data: cart[index],
            userId: user.id,
          })
        );
      }
      // dispatch(globalAction(DELETE_ADD_TO_CART_FROM_DB, cart[index]));
      cart.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(globalAction(ADD_TO_CART, cart));
      const data = {
        msg: `Item successfully deleted in your cart`,
        status: "success",
      };

      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en";
    i18next.changeLanguage(savedLanguage, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // -> same as i18next.t
      dispatch(globalAction(CHANGE_LANG, savedLanguage));
      // You can also handle currency value change here if needed
    });
  }, [dispatch]);

  const handleLanguage = (e) => {
    const newLanguage = e.target.value;
    // let tempcurrency = newLanguage === 'en' ? 'usd' : 'eur';
    i18next.changeLanguage(newLanguage, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      t("key"); // -> same as i18next.t
      dispatch(globalAction(CHANGE_LANG, newLanguage));
      localStorage.setItem("language", newLanguage);
      window.location.reload();
      // axios
      //   .get(
      //     `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eur/${tempcurrency}.json`
      //   )
      //   .then((res) => {
      //     //console.log("currency---",res)
      //     dispatch(globalAction(SET_CURRENCY_VALUE, res?.data?.[tempcurrency]));
      //   })
      //   .catch((err) => {
      //     dispatch(globalAction(SET_CURRENCY_VALUE, 1));
      //     //console.log(err)
      //   });
    });
  };

  return (
    <div className="header_wrapper">
      <header
        className={`header ${activeHeader ? "active" : ""} ${
          activeCategory ? "active_cat" : ""
        }`}
        id="header"
      >
        <Toast message={message} status={status} show={visibleMessage} />
        <div className="top_header">
          <Container>
            <Row>
              <Col md={4.5} className="contact_add col">
                <a
                  className="phone"
                  href="tel:+919711010424"
                  onClick={() => window.open("+919711010424")}
                >
                  <span>
                    <FaPhoneAlt />
                  </span>
                  <span>+919711010424</span>
                </a>
                <Link
                  className="email"
                  to="/"
                  onClick={() => window.open("mailto:care@bellstoneonline.com")}
                >
                  <span>
                    <AiOutlineMail />
                  </span>
                  <span>care@bellstoneonline.com</span>
                </Link>
              </Col>
              <Col md={3} className="title col">
                {/* <h2>Om sai Ram</h2> */}
              </Col>
              <Col md={4.5} className="links col">
                <Navbar bg="light" expand="lg">
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      {isLogin ? (
                        <select
                          className="pannel"
                          value=""
                          onChange={(e) => {
                            let value = e.target.value;
                            if (value === "logout") {
                              dispatch(globalAction(LOGOUT_USER));
                              let cart = [];
                              dispatch(globalAction(ADD_TO_CART, cart));
                              localStorage.clear();
                              localStorage.setItem("url", "/");
                              history.replace("/login");
                            } else if (value === "dashboard") {
                              history.replace("/dashboard");
                            }
                          }}
                        >
                          <option value="">{user && user.name}</option>
                          <option value="dashboard">{`${i18next.t(
                            "Dashboard"
                          )}`}</option>
                          <option value="logout">{`${i18next.t(
                            "Logout"
                          )}`}</option>
                        </select>
                      ) : (
                        <Link to="/login" className="sign-in nav-link">
                          {`${i18next.t("Sign In")}`}
                        </Link>
                      )}
                      <div className="langSwitcher">
                        {/* {googleTranslateElementInit()} */}
                      </div>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Main header */}
        <div className="main_header">
          <Container>
            <Row>
              <Col md={2.5} className="logo">
                <Link to="/">
                  <img src={headerLogo || Logo} alt=">Starapple.com" />

                  {/* <span>Online Industrial Departmental Store</span> */}
                </Link>
              </Col>

              <Col md={6} className="header_search">
                <form
                  ref={formRef}
                  className="search_form"
                  onSubmit={(e) => onSearch(e)}
                >
                  <select
                    onChange={(e) => {
                      setSelected_category(e.target.value);
                    }}
                  >
                    <option value="">{`${i18next.t("All Categories")}`}</option>
                    {category &&
                      category.map(({ name, id, slug }) => (
                        <option value={slug}>{name}</option>
                      ))}
                  </select>
                  <div className="input">
                    <input
                      placeholder={`${i18next.t(
                        "Search product by title,supplier"
                      )}`}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      name=""
                    />
                    <span>
                      <BsSearch onClick={(e) => onSearch(e)} />
                    </span>
                  </div>
                </form>
              </Col>

              <Col md={2.5} className="cart">
                <div className="language">
                  <select
                    onChange={(e) => {
                      handleLanguage(e);
                    }}
                  >
                    <option selected={currentLang === "en"} value="en">
                      EN
                    </option>
                    <option selected={currentLang === "fr"} value="fr">
                      FR
                    </option>
                  </select>
                </div>
                {/* Wishlist Icon */}
                <Link
                  to={isLogin ? "/dashboard/saved-products" : "/login"}
                  className="wishlist"
                >
                  <span className="icon">
                    <span className="item-number">{savedProducts?.total}</span>
                    <FaHeart />
                  </span>
                  <span className="text">{`${i18next.t("Wishlist")}`}</span>
                </Link>

                {history.location.pathname !== "/shipping-address" &&
                  history.location.pathname !== "/order-summary" && (
                    <Link
                      to={isLogin ? "#" : "/login"}
                      className="mycart"
                      onClick={() => {
                        if (!isLogin) {
                          history.push("/login");
                        } else {
                          toggleShoppingCart();
                        }
                      }}
                    >
                      <span className="icon">
                        <FaShoppingCart />
                      </span>
                      <div className="mycart_inner">
                        <span className="text">
                          {cart ? `${cart.length} Items` : "My Cart"}
                        </span>
                        <span>
                          <BiRupee />: {totalPrice}
                        </span>
                      </div>
                    </Link>
                  )}

                <div
                  className={`shopingCart ${showShopingCart ? "show" : "hide"}`}
                >
                  <div className="card_overlay"></div>
                  <div className="shopingCart_inner">
                    <div className="title">
                      {currentLang === "en" ? (
                        <h1>
                          {cart.length <= 0
                            ? "Please Add Products"
                            : `Recently Added ${cart.length} Item(s)`}
                        </h1>
                      ) : (
                        <h1>
                          {cart.length <= 0
                            ? "Veuillez ajouter des produits"
                            : `Récemment ajouté ${cart.length} Article(s)`}
                        </h1>
                      )}
                      <span
                        className="cross-icon"
                        onClick={() => toggleShoppingCart()}
                      >
                        <ImCross />
                      </span>
                    </div>
                    <div className="main-content">
                      <ListGroup variant="flush">
                        {cart.length > 0 ? (
                          cart.map((item, i) => {
                            return (
                              <ListGroup.Item>
                                <div className="si-wrap">
                                  <div className="si-image">
                                    <Link
                                      to={`/${item?.category?.slug}/${
                                        item?.subcategory?.slug
                                      }/${
                                        item?.slug !== null
                                          ? item?.slug
                                          : item?.id
                                      }`}
                                      onClick={() => toggleShoppingCart()}
                                    >
                                      <img
                                        src={item.image_url}
                                        alt={item.title}
                                      />
                                    </Link>
                                  </div>
                                  <div className="si-details">
                                    <Link
                                      to={`/${item?.category?.slug}/${
                                        item?.subcategory?.slug
                                      }/${
                                        item?.slug !== null
                                          ? item?.slug
                                          : item?.id
                                      }`}
                                      onClick={() => toggleShoppingCart()}
                                    >
                                      <h6>{item.title}</h6>
                                    </Link>

                                    <p className="normal-price">
                                      <BiRupee />
                                      {item.sale_price !== null
                                        ? item.sale_price
                                        : item.regular_price}
                                      <span>{`${i18next.t("per QTY")}`}</span>
                                    </p>

                                    {item.tax && (
                                      <p className="gst-price">
                                        <BiRupee />
                                        {item.sale_price !== null
                                          ? Math.ceil(
                                              (Number(item.sale_price) / 100) *
                                                Number(item.tax) +
                                                Number(item.sale_price)
                                            ).toFixed(2)
                                          : Math.ceil(
                                              (Number(item.regular_price) /
                                                100) *
                                                Number(item.tax) +
                                                Number(item.regular_price)
                                            ).toFixed(2)}
                                        <span>{`(include ${item.tax}% GST)`}</span>
                                      </p>
                                    )}

                                    <div className="si-qty-count">
                                      <span
                                        className="minus"
                                        onClick={() => {
                                          if (
                                            item.minimum_qty_per_order <
                                            item.quantity
                                          ) {
                                            quantityChange("sub", item.id, i);
                                          }
                                        }}
                                      >
                                        <FaMinus />
                                      </span>
                                      <span className="count">
                                        {item.quantity}
                                      </span>
                                      <span
                                        className="plus"
                                        onClick={() => {
                                          if (
                                            item.maximum_qty_per_order ===
                                              null ||
                                            item.maximum_qty_per_order >
                                              item.quantity
                                          ) {
                                            quantityChange("add", item.id, i);
                                          }
                                        }}
                                      >
                                        <FaPlus />
                                      </span>
                                    </div>
                                    {item.minimum_qty_per_order >=
                                      item.quantity && (
                                      <p className="red-alert-text">
                                        {`${i18next.t("Minimum")}`}{" "}
                                        {item.minimum_qty_per_order}{" "}
                                        {`${i18next.t("per QTY")}`}
                                      </p>
                                    )}
                                    {item.maximum_qty_per_order &&
                                      item.maximum_qty_per_order <=
                                        item.quantity && (
                                        <p className="red-alert-text">
                                          {`${i18next.t("Maximum")}`}{" "}
                                          {item.maximum_qty_per_order}{" "}
                                          {`${i18next.t("per QTY")}`}
                                        </p>
                                      )}

                                    <span
                                      className="itemDelete"
                                      onClick={() => {
                                        DeleteCardItems(item.id);
                                      }}
                                    >
                                      <RiDeleteBin2Line />
                                    </span>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            );
                          })
                        ) : (
                          <div className="empty-cart">
                            <span>
                              {" "}
                              <ImSad />
                            </span>
                            <p>{`${i18next.t("Your is cart empty")}`}</p>
                          </div>
                        )}
                      </ListGroup>
                    </div>

                    <div className="btn-wrap">
                      <div class="grand-total-wrap">
                        <span>{`${i18next.t("Grand Total")}`}</span>
                        <span className="grand-total">
                          <BiRupee />
                          {totalPrice}
                        </span>
                      </div>
                      <Link
                        className="button"
                        to="/cart"
                        onClick={async (e) => {
                          e.preventDefault();
                          dispatch(globalAction(SET_BUY_NOW_ITEM, undefined));
                          await localStorage.removeItem("buyNow");
                          await history.push("/cart");
                          await toggleShoppingCart();
                        }}
                      >
                        {`${i18next.t("View Cart")}`}
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>

              <Col className="mobile_button">
                <button className="menu_open" onClick={handleChange}>
                  <AiOutlineMenu />
                </button>
                <button
                  className="menu_close"
                  onClick={() => {
                    setActiveCategory(!activeCategory);
                    handleChange();
                  }}
                >
                  <AiOutlineClose />
                </button>
              </Col>
              <Col className="mobile_menu">
                <div className="mobile_menu_bar">
                  <h5>{`${i18next.t("Main Menu")}`}</h5>
                  <button className="menu_close" onClick={handleChange}>
                    <AiOutlineClose />
                  </button>
                </div>
                <ul>
                  <li>
                    <Link to="/" onClick={handleChange}>
                      {`${i18next.t("Home")}`}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={handleChange}>
                      {`${i18next.t("About Us")}`}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" onClick={handleChange}>
                      {`${i18next.t("Blog")}`}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" onClick={handleChange}>
                      {`${i18next.t("Contact us")}`}
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <Navigation />
        <Col className="fixed_footer">
          <ul>
            <li>
              <Link to="/">
                <span>
                  <AiFillHome />
                </span>
                <span>{`${i18next.t("Home")}`}</span>
              </Link>
            </li>
            <li>
              <Link
                onClick={() =>
                  document
                    .getElementById("header")
                    .classList.toggle("active_cat")
                }
              >
                <span className="open_icon">
                  <FaBars />
                </span>
                <span className="close_icon">
                  <FaTimes />
                </span>
                <span>{`${i18next.t("Category")}`}</span>
              </Link>
            </li>
            <li>
              <Link to="/login">
                <span>
                  <FaUserAlt />
                </span>
                <span>{`${i18next.t("Account")}`}</span>
              </Link>
            </li>
            {history.location.pathname !== "/shipping-address" && (
              <li>
                <Link to="#" onClick={() => toggleShoppingCart()}>
                  <span className="icon">
                    <FaShoppingCart />
                  </span>
                  <div className="mycart_inner">
                    <span className="text">
                      {cart ? `${cart.length}` : "My Cart"}
                    </span>
                    {/* <span>
                    <BiRupee />: {totalPrice}
                  </span> */}
                  </div>
                  <span>{`${i18next.t("Cart")}`}</span>
                </Link>
              </li>
            )}
          </ul>
        </Col>
      </header>
    </div>
  );
}

// const mapStateToProps=(state)=>{
//   const {AppReducer:{headers,categories,savedProducts},errorReducer:{ status, message, visibleMessage,error_code},authReducer:{user,isLogin}}=state;
//   return{headers,categories,status,message,visibleMessage,user,savedProducts,isLogin,error_code,
//   cartList:JSON.parse(localStorage.getItem("cartList")),
//   }
// }
// const mapDispatchToProps = dispatch => {
//   return {
//       getCategoryList: () => dispatch(globalAction(GET_CATEGORY)),
//       getProducts: (data) => dispatch(globalAction(GET_PRODUCTS, data)),
//       searchResult: (data) => dispatch(globalAction(GET_SEARCH, data)),
//       logoutUser: () => dispatch(globalAction(LOGOUT_USER)),
//       changeQuantity: (data) => dispatch(globalAction(CHANGE_CART_QTY,data)),
//       removeCartItem: (data) => dispatch(globalAction(REMOVE_FROM_CART,data)),
//       applyFilter: (data) => dispatch(globalAction(APPLY_FILTER,data)),
//       getSavedProducts: (data) => dispatch(globalAction(GET_SAVED_PRODUCTS,data)),
//   }
// }

export default withRouter(Header);
