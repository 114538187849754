import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./sellOnBellstone.scss";

function SellOnBellstone() {
  useEffect(() => {
    document.title = "Sell on bellstone";
  }, []);

  return (
    <section className="sell_bellstone">
      <div className="bread_crum">
        <Container>
          <Row>
            <ul>
              <li>
                <Link to="/">
                  Home
                  <span>/</span>
                </Link>
              </li>
              <li>
                <Link to="/sell-on-bellstone">Sell on bellstone</Link>
              </li>
            </ul>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col>
            <div className="common_pages">
              <div className="common_pages_heading">
                <h5>Sell On Bellstone</h5>
              </div>
              <div className="common_pages_content">
                <div className="date">
                  <h4>REGISTER</h4>
                </div>
                <div className="common_pages_inner">
                  <Form>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last Name" />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control type="number" placeholder="Contact Number" />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control type="text" placeholder="Company Name" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Trade </Form.Label>
                        <Form.Control type="text" placeholder="Trade" />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control type="text" placeholder="Company Name" />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Website </Form.Label>
                        <Form.Control type="text" placeholder="Trade" />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress2">
                      <Form.Label>Address</Form.Label>
                      <Form.Control placeholder="Apartment, studio, or floor" />
                    </Form.Group>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label>City</Form.Label>
                        <Form.Control />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridState">
                        <Form.Label>State</Form.Label>

                        <select className="form-select" id="cars" name="cars">
                          <option value="0">Choose...</option>
                          <option value="1">Delhi</option>
                          <option value="2">Bihar</option>
                          <option value="3">Uttar Pradesh</option>
                        </select>
                        {/* <Form.Control /> */}
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control />
                      </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress2">
                      <Form.Control as="textarea" placeholder="Leave a comment here" style={{ height: "120" }} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SellOnBellstone;
