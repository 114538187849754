import { Apis } from "./globals";
import axios from "axios";
import { AiOutlineConsoleSql } from "react-icons/ai";
const {
  baseUrl,
  mid,
  login,
  signup,
  order_cancel,
  recent_orders,
  home,
  contactus,
  product_list,
  categoryList,
  brand_list,
  product_details,
  global_product_search,
  subscribe,
  about_us,
  save_product_list,
  add_save_product,
  delete_save_product,
  latest_products,
  add_review,
  return_and_refund,
  payment_methods,
  delivery_information,
  privacy_policy,
  terms_condition,
  footer,
  header,
  add_bulk_enquiry,
  check_delivery,
  get_saved_address_by_userId,
  country,
  state,
  add_address,
  delete_address,
  update_address,
  add_order,
  orders,
  callback,
  order_details,
  bulk_enquiry_list,
  compare_products,
  delete_bulk,
  signup_verify,
  blog_list,
  blog_category,
  popular_blogs,
  blog_details,
  comment_list,
  add_comment,
  subcategory,
  review_register,
  payment_setting,
  add_like_unlike,
  estimate_shipping,
  forgot_password,
  reset_password,
  change_password,
  update_profile,
  add_to_cart,
  add_to_cart_list,
  update_qty,
  delete_add_to_cart,
  delete_all_add_to_cart,
  vendor_details,
  show_bulk_enquiry_details,
  bulk_enquiry_message,
} = Apis;

// let ="https://admin.supersonicparts.com/";
export async function getHomeApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + home, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function loginApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + login, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function signInApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + signup, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function contactApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + contactus, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getProductsApi(data) {
  var param = "";
  if (data) {
    param = data;
  }
  console.log(baseUrl + mid + product_list + param);
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + product_list + param)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getCategoriesApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + categoryList)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getBrandListApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + brand_list)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getProductsDetailsApi(id) {
  let isId = Number(id);
  let params = "?slug=" + id;
  if (isId) {
    params = "?id=" + id;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + product_details + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getSearchProduct(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + global_product_search + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function sendSubscribe(email) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + subscribe, email)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function aboutUsApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + about_us)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getSavedProductList(param) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + save_product_list + param)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function saveProductApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_save_product, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function removeSavedProdApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + delete_save_product, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getLatestProducts() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + latest_products)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function addReviewApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_review, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getRefundPolicyApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + return_and_refund)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getPaymentMethodPolicyApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + payment_methods)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getAboutApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + about_us)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getDeliveryInfoApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + delivery_information)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getPrivacyPolicyApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + privacy_policy)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getTermsConditionApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + terms_condition)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function footeraboutUsApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + footer)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function HeaderApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + header)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function SetBulkEnquiryApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_bulk_enquiry, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function checkDeliveryApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        baseUrl +
          mid +
          check_delivery +
          "?delivery_postcode=" +
          data.delivery_postcode +
          "&cod=" +
          data.cod +
          "&weight=" +
          data.weight
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => reject(error));
  });
}

export async function getSavedAddressApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + get_saved_address_by_userId + "?id=" + data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getCountryApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + country)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function getStateApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + state + "?country_id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addAddressApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_address, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function deleteAddressApis(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + delete_address + "?id=" + data.id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateAddressApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + update_address, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addCodOrderApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_order, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getMyOrderListApi(param) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + orders + param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function socialLoginApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + callback, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getMyTotalOrderListApi(param) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + orders + "?id=" + param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function orderDetailsApi(param) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + order_details + "?id=" + param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getBlukEnquiryListApi(param) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + bulk_enquiry_list + param)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getComparedProductsApi(data) {
  var formdata = new FormData();
  data.forEach((item) => {
    formdata.append("id[]", item);
  });
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + compare_products, formdata)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function deleteBlukEnquiryApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + delete_bulk + "?id=" + data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function signUpVerifyApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        baseUrl +
          mid +
          signup_verify +
          "?email=" +
          data.email +
          "&token=" +
          data.OTP
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {});
  });
}

export async function getBlogs(data) {
  // const token = localStorage.getItem('token', url);
  let param = "";
  if (data) {
    param = data;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + blog_list + param)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getBlogCategoryApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + blog_category)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getPopularBlogsApi() {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + popular_blogs)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function blogDetailApi(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + blog_details + "?id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getBlogCommentsApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + comment_list + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addBlogCommentApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_comment, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getSubCategoryApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + subcategory + "?category_slug=" + data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function review_register_Api(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + review_register, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getPaymentSettingApis(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + payment_setting)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addLikeUnlikeApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_like_unlike, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function checkEstimateShippingApi(params) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + estimate_shipping + params)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function cancelOrderApis(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + order_cancel, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function forgetPasswordApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + forgot_password, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function resetPasswordApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + reset_password, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updatePasswordApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + change_password, data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateProfileApi(data) {
  var formdata = new FormData();

  for (const key in data) {
    if (key === "photo") {
      formdata.append(key, data[key], data[key].name);
    } else {
      formdata.append(key, data[key]);
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + update_profile, formdata)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function addToApiIntoDbApi(data) {
  console.log("addToApiIntoDbApi", data);
  var formdata = new FormData();
  data.items.forEach((item, i) => {
    formdata.append(`add_to_cart[${i}][product_id]`, item.id);
    formdata.append(`add_to_cart[${i}][user_id]`, data.userId);
    formdata.append(`add_to_cart[${i}][qty]`, item.quantity);
    formdata.append(`add_to_cart[${i}][variant_id]`, item.variant_id);
  });
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + add_to_cart, formdata)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getListAddToApiFromDbApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + add_to_cart_list + "?id=" + data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function updateQtyCartItemIntoDbApi(data) {
  var formdata = new FormData();
  formdata.append(`product_id`, data.data.id);
  formdata.append(`user_id`, data.userId);
  formdata.append(`qty`, data.data.quantity);
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + update_qty, formdata)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function deleteCartItemIntoDbApi(data) {
  var formdata = new FormData();
  formdata.append(`id`, data.order_id);
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + delete_add_to_cart, formdata)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function deleteAllCartItemIntoDbApi(data) {
  var formdata = new FormData();
  formdata.append(`user_id`, data);
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + delete_all_add_to_cart, formdata)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getRecentOrderByUserIdApi(data) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + recent_orders + "?id=" + data)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function getVendorDetailByVendorId(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + recent_orders + "?id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}

export async function showBulkEnquiryDetailsApis(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + mid + show_bulk_enquiry_details + "?id=" + id)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
export async function bulkEnquiryMessageApi(data) {
  let formdata = new FormData();
  for (const key in data) {
    formdata.append(key, data[key]);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + mid + bulk_enquiry_message ,formdata)
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
}
// export async function getProducts(params) {
//   let param="";
//   if(params){
//     param=params;
//   }
//     return new Promise((resolve, reject) => {
//       axios.get(baseUrl + mid + product_list + param)
//         .then(result => resolve(result))
//         .catch(error => reject(error));
//     })
// }
// export async function getProductsDetails(id) {
//   return new Promise((resolve, reject)=>{
//     axios.get(baseUrl+mid+product_details+"?id="+id)
//    .then(result =>resolve(result))
//    .catch(error =>reject(error));
//    })
// }
// export async function updateProfileApi(data) {
//   var formdata = new FormData();

//   for(const key in data){
//     if (key === "photo") {
//      formdata.append(key, data[key], data[key].name);
//     }
//     else {
//       formdata.append(key, data[key]);
//     }
//   }
//   return new Promise((resolve, reject)=>{
//     axios.post(baseUrl+mid+update_profile,formdata)
//    .then(result =>resolve(result))
//    .catch(error =>reject(error));
//    })
// }
// export async function getAboutusApi() {
//   return new Promise((resolve, reject)=>{
//     axios.get(baseUrl+mid+about_us)
//    .then(result =>resolve(result))
//    .catch(error =>reject(error));
//    })
// }
