import React, { useEffect, useState } from "react";
import "./slider.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom"; // Import useHistory
import OwlCarousel from "react-owl-carousel";
import { BiRupee } from "react-icons/bi";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai"; // Import both icons
import { FaCheck, FaShoppingCart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../../Translation/index";
import {
  globalAction,
  SAVE_PRODUCT,
  ADD_TO_CART,
  ADD_TO_CART_INTO_DB,
  SHOW_TOAST,
} from "../../../redux";

function BestSellingProducts({ best_selling_product }) {
  const dispatch = useDispatch();
  const history = useHistory(); // Initialize useHistory
  const { user } = useSelector((state) => state.authReducer);
  const { cart } = useSelector((state) => state.AppReducer);
  const { currentLang } = useSelector((state) => state.AppReducer);

  // State to track wishlisted items
  const [wishlistedItems, setWishlistedItems] = useState([]);

  // Function to save wishlist items to localStorage
  const saveWishlistToLocalStorage = (items) => {
    localStorage.setItem("wishlist", JSON.stringify(items));
  };

  // Function to load wishlist items from localStorage
  const loadWishlistFromLocalStorage = () => {
    const savedWishlist = localStorage.getItem("wishlist");
    return savedWishlist ? JSON.parse(savedWishlist) : [];
  };

  useEffect(() => {
    // Load wishlist items from localStorage when the component mounts
    const storedWishlist = loadWishlistFromLocalStorage();
    setWishlistedItems(storedWishlist);

    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  const saveProductByUser = (product_id) => {
    if (user) {
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
      setWishlistedItems((prevItems) => {
        const newWishlist = [...prevItems, product_id]; // Add to wishlist
        saveWishlistToLocalStorage(newWishlist);
        return newWishlist;
      });
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
      history.push("/login");
    }
  };

  const addToCart = (item) => {
    if (user) {
      let flag = true;
      if (item) {
        var newArray = cart.filter(function (el) {
          if (el.id === item.id) {
            flag = false;
          }
        });
        if (flag) {
          item.quantity = 1;
          cart.push(item);
          localStorage.setItem("cart", JSON.stringify(cart));
          dispatch(globalAction(ADD_TO_CART, cart));
          if (user) {
            dispatch(globalAction(ADD_TO_CART_INTO_DB, { userId: user.id }));
          }
        }
      }
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
      history.push("/login");
    }
  };

  const isProductInCart = (productId) => {
    return cart.some((cartItem) => cartItem.id === productId);
  };

  const { data } = best_selling_product;
  return (
    <section className="best_selling_product product_cart">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>{`${i18next.t("Best Selling Products")}`}</h3>
          </Col>
          {data.length > 0 && (
            <OwlCarousel
              className="owl-theme"
              items={6}
              autoplay={false}
              smartSpeed={500}
              nav={true}
              slideBy={2}
              responsive={{
                0: {
                  items: 1,
                  slideBy: 1,
                },
                417: {
                  items: 2,
                  slideBy: 2,
                },
                576: {
                  items: 3,
                  slideBy: 3,
                },
                1000: {
                  items: 4,
                  slideBy: 4,
                },
              }}
            >
              {data.map((item) => {
                const {
                  title,
                  title_french,
                  id,
                  regular_price,
                  sale_price,
                  image_url,
                  stock_qty,
                  slug,
                  category,
                  subcategory,
                } = item;
                const isWishlisted = wishlistedItems.includes(id);
                const isInCart = isProductInCart(id);
                return (
                  <div className="item" key={id}>
                    <div className="cart">
                      <Link
                        className={stock_qty <= 0 ? "outofstock" : ""}
                        to={`/${category.slug}/${subcategory.slug}/${
                          slug !== null ? slug : id
                        }`}
                      >
                        <img src={image_url} alt="selling img" />
                      </Link>
                      <div className="cart_body">
                        <div className="product-heading">
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 0, hide: 400 }}
                            overlay={
                              <Tooltip
                                id={`tooltip-top`}
                                style={{
                                  fontSize: "12px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {currentLang === "en"
                                  ? title
                                  : title_french || title}
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/${category.slug}/${subcategory.slug}/${
                                slug !== null ? slug : id
                              }`}
                              className="d-inline-flex align-items-center"
                            >
                              <h5>
                                {currentLang === "en"
                                  ? title && title.slice(0, 30)
                                  : (title_french &&
                                      title_french.slice(0, 30)) ||
                                    title.slice(0, 30)}
                                ...
                              </h5>
                            </Link>
                          </OverlayTrigger>
                        </div>
                        <p>
                          <span>{`${i18next.t("Regular Price")}`}:</span>
                          <span>
                            <BiRupee />
                            {regular_price}
                          </span>
                        </p>
                        <p>
                          <span>{`${i18next.t("Special Price")}`}:</span>
                          <span>
                            <BiRupee />
                            {sale_price}
                          </span>
                        </p>
                        <div className="product_icon">
                          <span className="wishlist">
                            <AiTwotoneHeart
                              onClick={() =>
                                !isWishlisted && saveProductByUser(id)
                              }
                              className={`wishlist-icon ${
                                isWishlisted ? "wishlisted" : ""
                              }`}
                            />
                          </span>
                          <span
                            className={`shop ${
                              isInCart || stock_qty <= 0 ? "disabled" : ""
                            }`}
                            onClick={() => stock_qty > 0 && addToCart(item)}
                          >
                            {isInCart ? <FaCheck /> : <FaShoppingCart />}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </Row>
      </Container>
    </section>
  );
}

export default BestSellingProducts;
