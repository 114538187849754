import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-71139590-1');

function GoogleAnalytical() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    })
    return (
        <div>

        </div>
    )
}

export default withRouter(GoogleAnalytical)
