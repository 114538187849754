import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import "./range-slider.scss";

import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

export default function RangeSlider({ handleChange, maxValue }) {
  return (
    <Nouislider
      range={{ min: 0, max: Number(maxValue) }}
      start={[0, Number(maxValue)]}
      tooltips={true}
      format={{
        to: (v) => parseFloat(v).toFixed(0),
        from: (v) => parseFloat(v).toFixed(0),
      }}
      connect
      onSlide={(e) => handleChange(e)}
    />
  );
}
