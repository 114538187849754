import React, { Component } from 'react'
import {Spinner} from 'react-bootstrap'
import './animatedLogo.scss'
export default class AnimatedLogo extends Component {
    render() {
        return (
            <div className="animation-main">
                <Spinner className="animation"  variant="info" animation="border" role="status" size="lg"/>
            </div>
        )
    }
}
