import React, { useEffect, useState } from "react";
import "./civil-labs.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineHeart, AiTwotoneHeart } from "react-icons/ai"; // Import both icons
import { FaShoppingCart } from "react-icons/fa";
import { BiRupee } from "react-icons/bi";
import CivilLabSlider from "../slider/CivilLabSlider";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../../Translation";
import {
  globalAction,
  SHOW_TOAST,
  ADD_TO_CART,
  SAVE_PRODUCT,
  ADD_TO_CART_INTO_DB,
} from "../../../redux";

function CivilLab({ civilLabData }) {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.AppReducer);
  const { user } = useSelector((state) => state.authReducer);
  const { currentLang } = useSelector((state) => state.AppReducer);

  // State to track wishlisted items
  const [wishlistedItems, setWishlistedItems] = useState([]);

  // Function to save wishlist items to localStorage
  const saveWishlistToLocalStorage = (items) => {
    localStorage.setItem("wishlist", JSON.stringify(items));
  };

  // Function to load wishlist items from localStorage
  const loadWishlistFromLocalStorage = () => {
    const savedWishlist = localStorage.getItem("wishlist");
    return savedWishlist ? JSON.parse(savedWishlist) : [];
  };

  useEffect(() => {
    // Load wishlist items from localStorage when the component mounts
    const storedWishlist = loadWishlistFromLocalStorage();
    setWishlistedItems(storedWishlist);

    let preStoredItems = localStorage.getItem("cart");
    preStoredItems = JSON.parse(preStoredItems);
    if (preStoredItems !== null) {
      dispatch(globalAction(ADD_TO_CART, preStoredItems));
    }
  }, [dispatch]);

  const saveProductByUser = (product_id) => {
    if (user) {
      dispatch(globalAction(SAVE_PRODUCT, { product_id, user_id: user.id }));
      setWishlistedItems((prevItems) => {
        const newWishlist = [...prevItems, product_id]; // Add to wishlist
        saveWishlistToLocalStorage(newWishlist);
        return newWishlist;
      });
    } else {
      const data = {
        msg: `${i18next.t("Please login or Register to save this product.")}`,
        status: "warning",
      };
      dispatch(globalAction(SHOW_TOAST, { data }));
    }
  };

  const addToCart = (item) => {
    let flag = true;
    if (item) {
      var newArray = cart.filter(function (el) {
        if (el.id === item.id) {
          const data = {
            msg: `${item.title} ${i18next.t("already added in your cart")}`,
            status: "warning",
          };
          dispatch(globalAction(SHOW_TOAST, { data }));
          flag = false;
        }
      });
      if (flag) {
        item.quantity = 1;
        cart.push(item);
        const data = {
          msg: `${item.title} ${i18next.t(
            "are successfully added in your cart"
          )}`,
          status: "success",
        };
        dispatch(globalAction(SHOW_TOAST, { data }));
        localStorage.setItem("cart", JSON.stringify(cart));
        dispatch(globalAction(ADD_TO_CART, cart));
        if (user) {
          dispatch(globalAction(ADD_TO_CART_INTO_DB, { userId: user.id }));
        }
      }
    }
  };

  return (
    <section className="civil_lab spacing 4">
      <Container>
        <Row>
          <Col className="slider_title">
            <h3>
              {currentLang === "en"
                ? civilLabData && civilLabData.title_2
                : civilLabData.title_2_french || civilLabData.title_2}
            </h3>
          </Col>
          <Col className="lab_wrapper">
            <div className="lab_wrapper_img">
              {civilLabData && civilLabData.subcategory_slug !== null ? (
                <Link
                  to={`/${civilLabData.category_slug}/${civilLabData.subcategory_slug}`}
                >
                  <img
                    src={civilLabData && civilLabData.banner_2}
                    alt={civilLabData.title_2}
                  />
                </Link>
              ) : (
                <Link to={`/category-detail/${civilLabData.category_slug}`}>
                  <img
                    src={civilLabData && civilLabData.banner_2}
                    alt={civilLabData.title_2}
                  />
                </Link>
              )}
            </div>
            <div className="civil_lab_content">
              {civilLabData &&
                civilLabData.product2.map((item, i) => {
                  const { stock_qty, category, subcategory, slug, id } = item;
                  const isWishlisted = wishlistedItems.includes(id);
                  if (i === 0) {
                    return (
                      <div className={`product item${i}`} key={id}>
                        <Link
                          className={stock_qty <= 0 ? "outofstock" : ""}
                          to={`/${item.category.slug}/${
                            item.subcategory.slug
                          }/${item.slug !== null ? item.slug : id}`}
                        >
                          <img src={item && item.image_url} alt="" />
                        </Link>
                        <div className="product_body">
                          <p>
                            <OverlayTrigger
                              placement="top"
                              delay={{ show: 0, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id={`tooltip-top`}
                                  style={{
                                    fontSize: "12px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.title}
                                </Tooltip>
                              }
                            >
                              <Link
                                to={`/${item.category.slug}/${
                                  item.subcategory.slug
                                }/${item.slug !== null ? item.slug : id}`}
                              >
                                {item.title.slice(0, 30)}...
                              </Link>
                            </OverlayTrigger>{" "}
                            <span>
                              {`${i18next.t(" regular price")}`}: <BiRupee />
                              {item.regular_price}
                            </span>
                            <span>
                              {`${i18next.t("Special Price")}`}: <BiRupee />
                              {item.sale_price}
                            </span>
                          </p>
                          <div className="product_icon">
                            <span className="wishlist">
                              <AiTwotoneHeart
                                onClick={() =>
                                  !isWishlisted && saveProductByUser(item.id)
                                }
                                className={`wishlist-icon ${
                                  isWishlisted ? "wishlisted" : ""
                                }`}
                              />
                            </span>
                            <span
                              className={`shop ${
                                stock_qty <= 0 ? "disabled" : ""
                              }`}
                              onClick={() => stock_qty > 0 && addToCart(item)}
                            >
                              <FaShoppingCart />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}

              <CivilLabSlider
                products={civilLabData && civilLabData.product2}
                addToCart={addToCart}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CivilLab;
