import { takeEvery, put, call, delay, select } from "redux-saga/effects";
import {
  ADD_BLOG_COMMENT,
  ADD_LIKE_UNLIKE,
  ADD_ORDER,
  ADD_TO_CART_INTO_DB,
  ADD_TO_COMPARE,
  CANCEL_ORDER,
  CHECK_DELIVERY,
  DELETE_ADD_TO_CART_FROM_DB,
  DELETE_ALL_ADD_TO_CART_FROM_DB,
  GET_BLOG,
  GET_BLOG_CATEGORY_LIST,
  GET_BLOG_COMMENTS,
  GET_BLOG_DETAILS,
  GET_COMPARED_PRODUCTS,
  GET_LIST_OF_ADD_TO_CART_INTO_DB,
  GET_MY_ORDER,
  GET_ORDER_DETAILS,
  GET_SHIPPING_ESTIMATE,
  REGISTER_REVIEW,
  REMOVE_REQUEST,
  REQUEST_NOT_FOUND,
  SET_BLOG,
  SET_BLOG_CATEGORY_LIST,
  SET_BLOG_COMMENTS,
  SET_BLOG_DETAILS,
  SET_BULK_ENQUIRY_LIST,
  SET_COMPARED_PRODUCTS,
  SET_COMPARE_PRODUCT,
  SET_DELIVERY_STATUS,
  SET_MY_ORDER_LIST,
  SET_ORDER_DETAILS,
  SET_SEARCH_PRODUCTS_FOR_COMPARE,
  SET_SHIPPING_ESTIMATE,
  SET_TOTAL_ORDER,
  SET_USER,
  TOTAL_ORDER,
  UPDATE_PROFILE,
  UPDATE_QTY_ADD_TO_CART_INTO_DB,
} from "..";

import {
  addAddressApi,
  checkDeliveryApi,
  getCategoriesApi,
  getHomeApi,
  getLatestProducts,
  getMyOrderListApi,
  getProductsApi,
  getProductsDetailsApi,
  getSavedProductList,
  getSearchProduct,
  removeSavedProdApi,
  saveProductApi,
  SetBulkEnquiryApi,
  getMyTotalOrderListApi,
  orderDetailsApi,
  getBlukEnquiryListApi,
  getComparedProductsApi,
  deleteBlukEnquiryApi,
  getBlogs,
  getBlogCategoryApi,
  blogDetailApi,
  getBlogCommentsApi,
  addBlogCommentApi,
  review_register_Api,
  addLikeUnlikeApi,
  checkEstimateShippingApi,
  cancelOrderApis,
  updateAddressApi,
  updateProfileApi,
  addToApiIntoDbApi,
  getListAddToApiFromDbApi,
  updateQtyCartItemIntoDbApi,
  deleteCartItemIntoDbApi,
  deleteAllCartItemIntoDbApi,
} from "../../api";
import { globalAction } from "../actions";
import {
  SET_PRODUCT_MAX_PRICE,
  SET_BULK_ENQUIRY,
  ADD_TO_CART,
  APPLY_FILTER,
  CLOSE_LOADING,
  GET_CATEGORY,
  GET_HOME_DATA,
  GET_LATEST_PRODUCTS,
  GET_PRODUCTS,
  GET_PRODUCT_DETAILS,
  GET_SAVED_PRODUCTS,
  GET_SEARCH_PRODUCT,
  HIDE_MESSAGE,
  REMOVE_MESSAGE,
  REMOVE_SAVE_PROD,
  SAVE_PRODUCT,
  SET_CATEGORY,
  SET_FILTER,
  SET_HOME_DATA,
  SET_LATEST_PRODUCTS,
  SET_PRODUCTS,
  SET_PRODUCT_DETAILS,
  SET_SAVED_PRODUCTS,
  SHOW_LOADING,
  SHOW_MESSAGE,
  SHOW_TOAST,
  GET_BULK_ENQUIRY_LIST,
  DELETE_BULK_ENQUIRY,
} from "../actionTypes";

const callApi = (customFunction, data) => {
  if (data) {
    return customFunction(data).then((result) => {
      return result;
    });
  } else {
    return customFunction().then((result) => {
      return result;
    });
  }
};

function* getHomeData() {
  try {
    const result = yield call(callApi, getHomeApi);
    const { data } = result; //destructuring
    yield put(globalAction(SET_HOME_DATA, data)); // inserting data into reducer
  } catch (error) {
    console.log(error);
  }
}
function* showToast({ payload }) {
  try {
    const { data } = payload;
    yield put(globalAction(SHOW_MESSAGE, data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}
function* getProduct_list({ payload }) {
  try {
    const result = yield call(callApi, getProductsApi, payload);
    const { data, status } = result;

    if (status === 200) {
      yield put(globalAction(SET_PRODUCTS, data));
      const roundOffTo = (num, factor = 1) => {
        const quotient = num / factor;
        const res = Math.ceil(quotient) * factor;
        return res;
      };
      let product_max_price = roundOffTo(Number(data.max_price), 10);
      if (data.max_price === null || data.max_price === 0) {
        product_max_price = 10000;
      }
      yield put(globalAction(SET_PRODUCT_MAX_PRICE, product_max_price));
    }
  } catch (error) {
    console.log(error);
  }
}
function* applyFilter({ payload }) {
  const getFilter = (state) => state.AppReducer.filter;
  const filter = yield select(getFilter);
  console.log(payload);

  if (payload?.brand_id) {
    delete filter.sub_category_slug;
    delete filter.category_slug;
  } else {
    delete filter.brand_id;
  }

  try {
    let finalParam = "";
    if (payload) {
      Object.keys(payload).forEach((k) => {
        filter[k] = payload[k];
      });
    }

    let k = 0;
    for (const key in filter) {
      if (filter[key]) {
        if (k === 0) {
          finalParam = finalParam + `?${key}=${filter[key]}`;
        } else {
          finalParam = finalParam + `&${key}=${filter[key]}`;
        }
        k++;
      }
    }

    // Apply debouncing: wait for 3 seconds before making the API call
    yield delay(3000);

    yield put(globalAction(SET_FILTER, filter));
    yield put(globalAction(GET_PRODUCTS, finalParam));
  } catch (error) {
    console.log(error);
    // Handle error as needed
  }
}
function* getCategory_List({ payload }) {
  try {
    const result = yield call(callApi, getCategoriesApi, payload);
    const { data, status } = result; //destructuring
    if (status === 200) {
      yield put(globalAction(SET_CATEGORY, data.category.data)); // inserting data into reducer
    }
  } catch (error) {
    console.log(error);
  }
}
function* getProduct_details({ payload }) {
  try {
    const result = yield call(callApi, getProductsDetailsApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_PRODUCT_DETAILS, data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    console.log(error);
    yield put(globalAction(CLOSE_LOADING));
  }
}
function* getProductSearch({ payload }) {
  const { param, forword, getForCompare } = payload;
  try {
    const result = yield call(callApi, getProductsApi, param);
    const { data, status } = result;
    if (status === 200) {
      if (forword !== undefined) {
        yield put(globalAction(SET_PRODUCTS, data));
        yield forword.push(`/results/${param}`);
        if (payload.brandName) {
          yield forword.push(`/results/?=${payload.brandName.split("/")[0]}`);
        }
      }
      if (getForCompare === true) {
        yield put(
          globalAction(SET_SEARCH_PRODUCTS_FOR_COMPARE, data.product_list.data)
        );
      }
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    console.log(error);
    yield put(globalAction(CLOSE_LOADING));
  }
}

function* getSavedProducts({ payload }) {
  try {
    var param = "";
    for (const key in payload) {
      if (payload[key]) {
        if (key === "id") {
          param += "?id=" + payload[key];
        } else {
          param += `&${key}=` + payload[key];
        }
      }
    }
    const result = yield call(callApi, getSavedProductList, param);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_SAVED_PRODUCTS, data.data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error.response.data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* saveProduct({ payload }) {
  try {
    const result = yield call(callApi, saveProductApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(
        globalAction(GET_SAVED_PRODUCTS, { id: payload.user_id, limit: 5 })
      );
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(1000);
      yield put(globalAction(REMOVE_MESSAGE));
    } else {
      console.log(data);
    }
  } catch (error) {
    // console.log(error)
    yield put(globalAction(SHOW_MESSAGE, error.response.data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* deleteSavedProduct({ payload }) {
  const { id, user_id, limit } = payload;
  try {
    yield put(globalAction(SHOW_LOADING));
    const result = yield call(callApi, removeSavedProdApi, { id });
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(GET_SAVED_PRODUCTS, { id: user_id, limit }));
      yield put(globalAction(CLOSE_LOADING));
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
    } else {
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error.response));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getLatestProduct_List({ payload }) {
  try {
    const result = yield call(callApi, getLatestProducts, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_LATEST_PRODUCTS, data.latest_product_list)); // inserting data into reducer
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* setBulkEnquiry({ payload }) {
  try {
    const result = yield call(callApi, SetBulkEnquiryApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(1000);
      yield put(globalAction(REMOVE_MESSAGE));
    } else {
      data = {
        msg: `Something went wrong ! Please try after sometime`,
        status: "waring",
      };
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(1000);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (error) {
    let data = error;
    if (error !== undefined) {
      data = {
        msg: `Error 400 (Network Error) ! Please try after sometime`,
        status: "waring",
      };
    }
    yield put(globalAction(SHOW_MESSAGE, data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* checkDelivery({ payload }) {
  try {
    const result = yield call(callApi, checkDeliveryApi, payload);
    let { data, status } = result;
    if (status === 200) {
      let reqData = { msg: data.msg, COD: payload.cod };
      yield put(globalAction(SET_DELIVERY_STATUS, reqData));
    }
  } catch (error) {
    let reqData = { msg: "Not Available", COD: payload.cod };
    yield put(globalAction(SET_DELIVERY_STATUS, reqData));
  }
}

function* getMyOrderList({ payload }) {
  try {
    const result = yield call(callApi, getMyOrderListApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_MY_ORDER_LIST, data.data));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error.response.data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getTotalOrderList({ payload }) {
  try {
    const result = yield call(callApi, getMyTotalOrderListApi, payload);
    const { data, status } = result;
    if (status === 200) {
      let allOrder = data.data.data;
      yield put(globalAction(SET_TOTAL_ORDER, allOrder.length));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error.response.data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getOrderDetails({ payload }) {
  try {
    const result = yield call(callApi, orderDetailsApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_ORDER_DETAILS, data.data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error.response.data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getBulkEnquiryList({ payload }) {
  try {
    const result = yield call(callApi, getBlukEnquiryListApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_BULK_ENQUIRY_LIST, data.data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error.response.data));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* deleteBulkEnquiry({ payload }) {
  try {
    const result = yield call(callApi, deleteBlukEnquiryApi, payload.Itemid);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(GET_BULK_ENQUIRY_LIST, payload.data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    // console.log(error)
  }
}

function* getComparedProducts({ payload }) {
  yield put(globalAction(SHOW_LOADING));
  try {
    const result = yield call(callApi, getComparedProductsApi, payload.data);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_COMPARED_PRODUCTS, data.compare_product));
      yield put(globalAction(ADD_TO_COMPARE, payload));
      yield localStorage.setItem(
        "BS_ComparedProductList",
        JSON.stringify(payload)
      );
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (err) {
    // console.log(err)
    yield put(globalAction(CLOSE_LOADING));
  }
}

function* getBlog_List({ payload }) {
  try {
    const result = yield call(callApi, getBlogs, payload);
    // console.log(result)
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_BLOG, data.blog)); // inserting data into reducer
    }
  } catch (error) {
    console.log(error);
  }
}

function* getBlogCategory() {
  try {
    const result = yield call(callApi, getBlogCategoryApi);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_BLOG_CATEGORY_LIST, data.blog_category.data));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getBlogDetails({ payload }) {
  try {
    const result = yield call(callApi, blogDetailApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_BLOG_DETAILS, data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    const {
      response: { status },
    } = error;
    if (status === 404 || status === 400) {
      yield put(globalAction(CLOSE_LOADING));
      yield put(globalAction(REQUEST_NOT_FOUND, status));
      yield delay(1000);
      yield put(globalAction(REMOVE_REQUEST));
    }
  }
}

function* getBlogsComments({ payload }) {
  try {
    const result = yield call(callApi, getBlogCommentsApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_BLOG_COMMENTS, data.blog_comment));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addBlog({ payload }) {
  try {
    const result = yield call(callApi, addBlogCommentApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(GET_BLOG_COMMENTS, payload.blog_id));
      yield put(globalAction(CLOSE_LOADING));
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(1000);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* registerReview({ payload }) {
  try {
    const result = yield call(callApi, review_register_Api, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(CLOSE_LOADING));
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(2000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(1000);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (error) {
    yield put(globalAction(CLOSE_LOADING));
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(2000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(1000);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* addLikeUnlike({ payload }) {
  const { blog_id } = payload;
  try {
    const result = yield call(callApi, addLikeUnlikeApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(GET_BLOG_DETAILS, blog_id));
      yield put(globalAction(SHOW_MESSAGE, data));
      yield delay(1000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(500);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* getShippingEstimate({ payload }) {
  try {
    const result = yield call(callApi, checkEstimateShippingApi, payload);
    const { data, status } = result;
    if (status === 200) {
      yield put(globalAction(SET_SHIPPING_ESTIMATE, data));
      yield put(globalAction(CLOSE_LOADING));
    }
  } catch (error) {
    let tempMsg = {
      delivery_estimate: "0",
      msg: "Delivery is not serviceable on this pincode.",
      shipping_price: 0,
      status: false,
      // status: "waring",
    };
    yield put(globalAction(SET_SHIPPING_ESTIMATE, tempMsg));
    yield put(globalAction(SHOW_MESSAGE, tempMsg));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
    yield put(globalAction(CLOSE_LOADING));
  }
  yield put(globalAction(CLOSE_LOADING));
}

function* cancelOrder({ payload }) {
  try {
    const result = yield call(callApi, cancelOrderApis, payload);
    const { data, status } = result;
    let msgData = {
      msg: data.message,
      status: data.status,
    };
    if (status === 200) {
      yield put(globalAction(GET_ORDER_DETAILS, payload.id));
      yield put(globalAction(SHOW_MESSAGE, msgData));
      yield delay(1000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(500);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

function* updateProfile({ payload }) {
  try {
    const result = yield call(callApi, updateProfileApi, payload);
    const { data, status } = result;
    let msgData = {
      msg: data.msg,
      status: data.status,
    };
    if (status === 200) {
      yield put(globalAction(SHOW_MESSAGE, msgData));
      yield put(globalAction(SET_USER, data.data));
      yield delay(1000);
      yield put(globalAction(HIDE_MESSAGE));
      yield delay(500);
      yield put(globalAction(REMOVE_MESSAGE));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

export function* add_to_cart_into_db({ payload }) {
  const tempReduxCartItems = (state) => state.AppReducer.cart;
  const reduxCartItems = yield select(tempReduxCartItems);
  console.log("reduxCartItems", reduxCartItems);
  try {
    const result = yield call(callApi, addToApiIntoDbApi, {
      items: reduxCartItems,
      userId: payload.userId,
    });
    const { data, status } = result;
    if (data?.status === "success" && payload.userId !== undefined) {
      yield put(globalAction(GET_LIST_OF_ADD_TO_CART_INTO_DB, payload.userId));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

export function* get_list_add_to_cart_into_db({ payload }) {
  try {
    const result = yield call(callApi, getListAddToApiFromDbApi, payload);
    const { data, status } = result;
    let DbCartItem = [];
    if (data?.status === "success") {
      let tempdata = data.data.data;
      tempdata.forEach((item) => {
        let tempProduct = {
          ...item.product,
          ...{ order_id: item.id, quantity: item.qty || item.quantity },
        };
        DbCartItem.push(tempProduct);
      });
      console.log("DbCartItem,", DbCartItem);
      yield put(globalAction(ADD_TO_CART, DbCartItem));
    }
    localStorage.setItem("cart", JSON.stringify(DbCartItem));
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

export function* updateQtyCartItemIntoDb({ payload }) {
  try {
    const result = yield call(callApi, updateQtyCartItemIntoDbApi, payload);
    const { data, status } = result;
    if (data?.status === "success") {
      yield put(globalAction(GET_LIST_OF_ADD_TO_CART_INTO_DB, payload.userId));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

export function* deleteCartItemFromDb({ payload }) {
  try {
    const result = yield call(callApi, deleteCartItemIntoDbApi, payload.data);
    const { data, status } = result;
    if (data?.status === "success") {
      yield put(globalAction(GET_LIST_OF_ADD_TO_CART_INTO_DB, payload.userId));
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}

export function* deleteAllCartItemFromDb({ payload }) {
  try {
    const result = yield call(callApi, deleteAllCartItemIntoDbApi, payload);
    const { data, status } = result;
    if (data?.status === "success") {
      localStorage.removeItem("cart");
    }
  } catch (error) {
    yield put(globalAction(SHOW_MESSAGE, error));
    yield delay(1000);
    yield put(globalAction(HIDE_MESSAGE));
    yield delay(500);
    yield put(globalAction(REMOVE_MESSAGE));
  }
}
export function* watchAppSaga() {
  yield takeEvery(GET_HOME_DATA, getHomeData);
  yield takeEvery(SHOW_TOAST, showToast);
  yield takeEvery(APPLY_FILTER, applyFilter);
  yield takeEvery(GET_PRODUCTS, getProduct_list);
  yield takeEvery(GET_CATEGORY, getCategory_List);
  yield takeEvery(GET_PRODUCT_DETAILS, getProduct_details);
  yield takeEvery(GET_SEARCH_PRODUCT, getProductSearch);
  yield takeEvery(GET_SAVED_PRODUCTS, getSavedProducts);
  yield takeEvery(SAVE_PRODUCT, saveProduct);
  yield takeEvery(REMOVE_SAVE_PROD, deleteSavedProduct);
  yield takeEvery(GET_LATEST_PRODUCTS, getLatestProduct_List);
  yield takeEvery(SET_BULK_ENQUIRY, setBulkEnquiry);
  yield takeEvery(CHECK_DELIVERY, checkDelivery);
  yield takeEvery(TOTAL_ORDER, getTotalOrderList);
  yield takeEvery(GET_MY_ORDER, getMyOrderList);
  yield takeEvery(GET_ORDER_DETAILS, getOrderDetails);
  yield takeEvery(GET_BULK_ENQUIRY_LIST, getBulkEnquiryList);
  yield takeEvery(GET_COMPARED_PRODUCTS, getComparedProducts);
  yield takeEvery(GET_COMPARED_PRODUCTS, getComparedProducts);
  yield takeEvery(DELETE_BULK_ENQUIRY, deleteBulkEnquiry);
  yield takeEvery(GET_BLOG, getBlog_List);
  yield takeEvery(ADD_BLOG_COMMENT, addBlog);
  yield takeEvery(GET_BLOG_COMMENTS, getBlogsComments);
  yield takeEvery(GET_BLOG_DETAILS, getBlogDetails);
  yield takeEvery(GET_BLOG_CATEGORY_LIST, getBlogCategory);
  yield takeEvery(REGISTER_REVIEW, registerReview);
  yield takeEvery(ADD_LIKE_UNLIKE, addLikeUnlike);
  yield takeEvery(GET_SHIPPING_ESTIMATE, getShippingEstimate);
  yield takeEvery(CANCEL_ORDER, cancelOrder);
  yield takeEvery(UPDATE_PROFILE, updateProfile);
  yield takeEvery(ADD_TO_CART_INTO_DB, add_to_cart_into_db);
  yield takeEvery(
    GET_LIST_OF_ADD_TO_CART_INTO_DB,
    get_list_add_to_cart_into_db
  );
  yield takeEvery(UPDATE_QTY_ADD_TO_CART_INTO_DB, updateQtyCartItemIntoDb);
  yield takeEvery(DELETE_ADD_TO_CART_FROM_DB, deleteCartItemFromDb);
  yield takeEvery(DELETE_ALL_ADD_TO_CART_FROM_DB, deleteAllCartItemFromDb);
}
