import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLogo from "../../../assets/logo-black.png";
import { globalAction, SHOW_LOADING, SIGNUP_VERIFY } from "../../../redux";
import './OTPVerify.css'
import AnimateIcon from '../../AnimatedLogo/AnimatedLogo'
import { useHistory } from "react-router";
import i18next from "../../Translation";




function OTPVerify() {
    const history=useHistory()
    const dispatch = useDispatch()
    const {visibleMessage,isLoading,message} = useSelector(state => state.errorReducer)
    const {user} = useSelector(state => state.authReducer)
    const [userEmail, setUserEmail] = useState(undefined);
    const [OTP, setOTP] = useState(undefined);
    const [checkEmpty, setcheckEmpty] = useState(false)

    useEffect(() => {
        setUserEmail(localStorage.getItem("bellStone_verifyEmail"));
        if(user!==null ){
            history.push('/dashboard')
        }
        return () => {
            setUserEmail(localStorage.getItem(undefined));
        };
    }, []);
    
   

    const handleSubmit = (() => {
        if (OTP.trim() === '') {
            setOTP(undefined)
            setcheckEmpty(true)
        }
        else {
            dispatch(globalAction(SHOW_LOADING))
            dispatch(globalAction(SIGNUP_VERIFY, { OTP: OTP, email: userEmail,cmhistory:history }))
        }

    })

    return (
        <div className="otp-verify-main-wrap">
            {isLoading && <AnimateIcon/>}
            <div className="otp-verify-inner">
                <div className="logo section">
                    <div className="logo">
                        <img src={MainLogo} alt="Bellstone" />
                    </div>
                </div>
                <div className="otp-content">
                    <h3><span className="green-text">{`${i18next.t('Verify your')}`}</span>{`${i18next.t('email')}`}</h3>
                    <p>
                    {`${i18next.t('You need to verified your email account for first time to login your acccount.')}`}{" "}


                        {`${i18next.t('We already sent a verification code to your email')}`}{" "}
                        <span className="otp-email">{`( ${userEmail} )`}</span>
                    </p>
                </div>
                <div className={`otp-form ${checkEmpty === true ? 'error' : ''}`}>
                    <input
                        type="text"
                        onChange={(e) => {
                            setOTP(e.target.value); setcheckEmpty(false)
                        }}
                        id="otp" name="otp"
                    />
                    {
                        checkEmpty &&
                        <small className='error-req'>*{`${i18next.t('Required')}`}</small>
                    }


                </div>
                {
                    OTP !== undefined &&
                    <div className="otp-btn animate-pop-in">
                        <button onClick={() => handleSubmit()} className="button" type='button'>
                        {`${i18next.t('Verify')}`}
                        </button>
                    </div>
                }

            </div>
        </div>
    );
}

export default OTPVerify;
